import { Radio, Select, Input, Space, Button, DatePicker } from "antd"
import { SearchOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useState } from "react";
import css from "../../css/SearchBar.module.css"
import '../../css/SearchBar.less'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

//  节点状态
const NodeStatus = {
    //  正常节点
    Normal : 0,
    //  已暂停节点
    Pause : 1
}

const SearchBar = ({ onSearchClicked, size, zoneData, gameData, groupsData, showModel = {
    radioGroup: true,
    zone: true,
    game: true,
    groups: true,
    range: false,
    search: false,
    nodeStatus: false
} }) => {

    

    const [nodeStatus, setNodeStatus] = useState(NodeStatus.Normal)
    const [timeQuantum, setTimeQuantum] = useState("")
    const [zone, setZone] = useState("")
    const [game, setGame] = useState("")
    const [groups, setGroups] = useState("")
    const [searchText, setSearchText] = useState("")
    const [searchTime, setSearchTime] = useState([])
    const [finishTime, setFinishTime] = useState("")

    if(showModel.zone && !zoneData) console.log('SearchBar提示：你选择了[地区选择器]，但是未设置数据源，请为zoneData赋值，数据类型：[\'南宁\',\'广州\']')
    if(showModel.game && !gameData) console.log('SearchBar提示：你选择了[游戏选择器]，但是未设置数据源，请为gameData赋值，数据类型：[\'QQ华夏[端游]\',\'傲剑绿色版[端游]\']')
    if(showModel.groups && !groupsData) console.log('SearchBar提示：你选择了[分组选择器]，但是未设置数据源，请为groupsData赋值，数据类型：[\'0000\',\'0001\']')

    let disabledDate = (current) => {
        return current && current > new Date();
    }
    let onNodeStatusChange = (event) => {
        setNodeStatus(event.target.value)
        onSearchClicked && onSearchClicked(timeQuantum, zone, game, groups, searchText, event.target.value, searchTime, finishTime)
    }

    let onTimeQuantumChange = (event) => {
        setTimeQuantum(event.target.value)
        onSearchClicked && onSearchClicked(event.target.value, zone, game, groups, searchText, nodeStatus, searchTime, finishTime)
    }

    let onZoneChange = (value) => {
        setZone(value)
        onSearchClicked && onSearchClicked(timeQuantum, value, game, groups, searchText, nodeStatus, searchTime, finishTime)
    }

    let onGameChange = (value) => {
        setGame(value)
        onSearchClicked && onSearchClicked(timeQuantum, zone, value, groups, searchText, nodeStatus, searchTime, finishTime)
    }

    let onFinishTimeChange = (value) => {
        setFinishTime(value)
        onSearchClicked && onSearchClicked(timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime, value)
    }
 
    let onGroupsChange = (value) => {
        setGroups(value)
        onSearchClicked && onSearchClicked(timeQuantum, zone, game, value, searchText, nodeStatus, searchTime, finishTime)
    }

    let onSearchTextChange = (event) => {
        setSearchText(event.target.value.trim())
    }

    let onRangePickerChange = (event) => {
        var ti = ['', '']
        if (event) {
            ti = [event[0].format("YYYY-MM-DD 00:00:00"), event[1].format("YYYY-MM-DD 00:00:00")]
        }
        setSearchTime(ti)
        onSearchClicked && onSearchClicked(timeQuantum, zone, game, groups, searchText, nodeStatus, ti)
    }

    let onBtnSearchClicked = (event) => {
        onSearchClicked && onSearchClicked(timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime)
    }




    const getQueryNodeStatus = () => {
        return (
                <Radio.Group
                    onChange={onNodeStatusChange}
                    size={size}
                    value={nodeStatus}
                    buttonStyle="solid"
                    style={{ verticalAlign: "middle", }}>
                    <Radio.Button style={{ borderRadius:'5px 0 0 5px'}}   value={NodeStatus.Normal} onClick={() => { setNodeStatus(NodeStatus.Normal)  ; console.log('xxxx',NodeStatus.Normal) }}>正常节点</Radio.Button>
                    <Radio.Button style={{ borderRadius:'0 5px 5px 0' ,}} value={NodeStatus.Pause}  onClick={() => { setNodeStatus(NodeStatus.Pause)
                        console.log('snsnns',NodeStatus.Pause)}}>暂停节点</Radio.Button>
                </Radio.Group>

        )
    }

    const getQueryAreaSelect = () => {
        return (
                <Select
                    onChange={onZoneChange}
                    defaultValue={zone}
                    style={{ width: 120 }}
                    bordered={false}
                    className={css.select}>
                    <Option value="">全部地区</Option>
                    {
                        (zoneData && zoneData.length >= 1) && zoneData.map(element => {
                            return <Option key={`zone${element}`} value={element}>{element}</Option>
                        })
                    }
                </Select>
        )
    }

    const getQueryTimeSelect = () => {
        return (
                <Radio.Group
                    onChange={onTimeQuantumChange}
                    size={size}
                    defaultValue={timeQuantum}
                    buttonStyle="solid"
                    style={{ verticalAlign: "middle" }}>
                    <Radio.Button value={7}>7天到期</Radio.Button>
                    <Radio.Button value="">全部</Radio.Button>
                </Radio.Group>

        )
    }

    const getQueryGameSelect = () => {
        return (
                <Select
                    onChange={onGameChange}
                    size={size}
                    defaultValue={game}
                    style={{ width: 150 }}
                    bordered={false}
                    className={css.select}>
                    <Option value="">全部游戏</Option>
                    {
                        (gameData && gameData.length >= 1) && gameData.map(element => {
                            return <Option key={`game${element}`} value={element}>{element}</Option>
                        })
                    }
                </Select>

        )
    }

    const getQueryFinishTimeSelect = () => {
        return (
                <Select
                    onChange={onFinishTimeChange}
                    size={size}
                    defaultValue={finishTime}
                    style={{ width: 150 }}
                    bordered={false}
                    className={css.select}>
                    <Option value="">到期时间</Option>
                    <Option value={1}>{"<1天"}</Option>
                    <Option value={3}>{"<3天"}</Option>
                    <Option value={7}>{"<7天"}</Option>
                </Select>

        )
    }

    const getQueryGroupSelect = () => {
        return (
            <Select
                onChange={onGroupsChange}
                size={size}
                defaultValue={groups}
                style={{ width: 150 }}
                bordered={false}
                className={css.select}>
                <Option value="">全部分组</Option>
                {
                    (groupsData && groupsData.length >= 1) && groupsData.map((element, idx) => {
                        return <Option key={`group${element}${idx}`} value={element}>{element}</Option>
                    })
                }
            </Select>
        )
    }

    const getQueryNodeNameSearch = () => {
        return (
            <Search
                size={size}
                placeholder="节点名称/分组"
                prefix={<SearchOutlined />}
                value={searchText}
                style={{ width: 250, verticalAlign: "middle" ,borderRadius:'20px' }}
                enterButton="搜索"
                onSearch={onBtnSearchClicked}
                onChange={onSearchTextChange}
            />
        )
    }

    const getQueryTimePicker = () => {
        return (
            <RangePicker disabledDate={disabledDate}  suffixIcon={<ClockCircleOutlined />} onChange={onRangePickerChange} />
        )
    }

    return (
        <div className="my-des bg" style={{borderx:'1px solid',width:"fit-content"}}>
            <Space size="middle">
                { showModel.radioGroup && getQueryTimeSelect() }
                { showModel.nodeStatus && getQueryNodeStatus() }
                { showModel.zone && getQueryAreaSelect() }
                { showModel.game && getQueryGameSelect() }
                { showModel.finishTime && getQueryFinishTimeSelect() }
                { showModel.groups && getQueryGroupSelect() }
                { showModel.range && getQueryTimePicker() }
                { showModel.search && getQueryNodeNameSearch() }
            </Space>
        </div>

    )
}

SearchBar.defaultValue = {
    size: "",
    zoneData: [],
    gameData: [],
    groupsData: [],
    showModel: {
        radioGroup: true,
        zone: true,
        game: true,
        groups: true,
        nodeStatus: false
    }
}

export { NodeStatus }
export default SearchBar