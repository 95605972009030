import {useEffect, useState} from "react";
import {
    Col,
    Radio,
    Row,
    List,
    Select,
    Input,
    Button,
    message,
    Modal,
    Image,
    Table,
    Empty,
    Spin,
    Checkbox,
    Space,
    Layout,
    Cascader
} from "antd";
import NodeCity from "./NodeCity";
import {NetworkHelper} from "com.guntherlau.services.mofang";
import {check} from "../../utils/check";
import css from "../../css/PageNodePurchase.module.css"
import {images} from "../../Constant";
import {useNavigate} from "react-router";

const {Option} = Select;
const { Footer} = Layout;


const PageNodePurchaseSecond = () => {
    const navigator = useNavigate()

    //  当前选择的【游戏索引】，A-Z
    //  当前【游戏索引】下的游戏列表
    const [games, setGames] = useState([]);
    const [selGame, setSelGame] = useState(''
    );
    //  所有的城市
    const [citys, setCitys] = useState([]);
    //  当前省的节点
    const [province, setProvince] = useState([]);
    //  当前省的Select选项
    const [provinceSelect, setProvinceSelect] = useState(-1);
    //  专区选项
    const [zhuanqu, setZhuanqu] = useState("");
    const [zhuanqus, setZhuanqus] = useState([])
    //  购买节点时间的Select选项
    const [buyTimeSelect, setBuyTimeSelect] = useState(1);
    //  购买节点时间的Input
    const [buyTimeInput, setBuyTimeInput] = useState(1);
    //  当前选择商品
    const [cat, setCat] = useState([]);
    //  当前用户金币的数量
    const [balance, setBalance] = useState(sessionStorage.getItem("mofang-Balance"));
    const [loading, setLoading] = useState(false);
    // 是否同意协议
    const [checkbox, setCheckbox] = useState(false);
    // 协议抖动效果
    const [animation, setAnimation] = useState(false);

    // 弹窗
    const [isModalVisible, setIsModalVisible] = useState(false);


    const loadGameArea = function () {
        NetworkHelper.getInstance().GetAreaData(selGame, zhuanqus[zhuanqu], province[provinceSelect]).then((response) => {
            if (response.data.Code === 1000) {
                setProvince(response.data.ScreenProvinceList);
                if (response.data.AreaList === null) {
                    setCitys([])
                } else {
                    setCitys(response.data.AreaList)
                }
                setZhuanqus(response.data.ScreenTypeList)
            } else {
                message.error(response.data.Message, 2)
            }
        })
            .catch((error) => {
                console.log("请求发生异常", error);
            });
    }

    // 关闭弹窗
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // 确认弹窗
    const handleOk = () => {
        if (!checkbox) {
            setAnimation(true)
            setTimeout(() => {
                setAnimation(false)
            }, 500);
            message.error('请勾选协议', 2);
            return
        }
        setIsModalVisible(false)
        setLoading(true)
        NetworkHelper.getInstance().BuyProject({
            BuyInfo: cat,
            BuyGame: selGame[1],
            BuyTime: buyTimeInput || buyTimeSelect
        }).then((e) => {

            if (e.data.Code === 1000) {
                sessionStorage.setItem("mofang-Balance", e.data.Balance)
                setBalance(e.data.Balance)
                message.success('购买成功！', 2)
                setCat([])
                loadGameArea();
            } else {
                message.error(e.data.Message, 2)
            }
        })
            .finally(() => {
                setLoading(false)
                setCheckbox(false)
            });
    }

    let setCatGoods = (e) => {
        setCat(e)
    }

    // 选择游戏
    let handlerGameChange = (event) => {
        // console.log(event.target.value);
        console.log('sss ', selGame);
        setSelGame(event.target.value);
    };

    let buyTimeSelectChange = (e) => {
        setBuyTimeSelect(e)
        setBuyTimeInput(e)
    }
    let changeTimeInput = (e) => {

        let val = e.target.value.trim()
        if (val === '') {
            setBuyTimeInput(0)
            setBuyTimeSelect(1)
            return
        }
        if (!isNaN(val)) {
            let valInt = parseInt(val)

            if (valInt >= 36) {
                setBuyTimeInput(36)
                message.info('最大输入36', 2)
                return
            }
            setBuyTimeInput(valInt)
            if (valInt === 1 || valInt === 2 || valInt === 3 || valInt === 12) {
                setBuyTimeSelect(valInt)
            }
        }

        // setBuyTimeInput(parseInt(e.target.value))
    }

    let selectChange = (e) => {
        setCat([])
        setProvinceSelect(e)
    }

    let selectZhuanQu = (e) => {
        setCat([])
        setZhuanqu(e)
    }

    const columnsSel = [
        // {
        //     title: '序号',
        //     render: (Type, Describe, index) => `${index + 1}`,
        //     width: '5%',
        //     align: 'center'
        // },
        {
            title: '地区',
            dataIndex: 'area',
            key: 'area',
            width: '15%',
            align: 'center'
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            width: "15%",
            align: 'center'
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            width: "15%",
            align: 'center'
        }
    ]

    const ModalTable = ({columnsSel, nodeOrderItemList}) => {
        return (
            <Table
                rowKey={record => record.uid}
                pagination={false}
                columns={columnsSel}
                dataSource={nodeOrderItemList}
                scroll={{scrollToFirstRowOnChange: false, y: 200}}
                size="middle"
                footer={() => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{width: '36%'}}>合计</div>
                            <div style={{
                                color: 'red',
                                width: '30%',
                                textAlign: 'center'
                            }}> {cat.reduce((a, b) => a + b.price, 0)}</div>
                            <div style={{color: 'red', width: '30%', textAlign: 'center', paddingRight: '16px'}}>
                                {cat.reduce((a, b) => a + b.quantity, 0)}
                            </div>

                        </div>
                    )
                }}
            />
        )
    }


    // 确认购买
    let handlerBuy = () => {
        if (!selGame) {
            message.error('请选择游戏项目（选择游戏名称首字母选择您的游戏）', 2);
            return
        } else if (cat.length <= 0) {
            message.error('请选择游戏的节点', 2);
            return
        }
        setIsModalVisible(true)
    }

    // 根据游戏获取地区数据
    useEffect(() => {
        if (selGame !== "") {
            loadGameArea()
        }
    }, [selGame, zhuanqu, provinceSelect]);


    const loadData = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        NetworkHelper.getInstance().GetGameData(targetOption.value)
            .then((response) => {
                if (response) {
                    response.data.GameList.map(val => {
                        val.label = val.Game
                        val.value = val.Game
                    })

                    targetOption.loading = false;
                    targetOption.children = response.data.GameList;
                    setOptions([...options]);
                    if (response.data.Code === 1000) {
                        setProvinceSelect(-1)
                        setSelGame('')
                        setCat([])
                        setGames(response.data.GameList);
                    } else {
                        message.error(response.data.Message, 2)
                    }
                }
            }).catch(error => {
            console.log('navigator("/")')
        })
    }


    let gameIndexArray = []
    for (let i = 0; i < 26; i++) {
        let str = String.fromCharCode(i + 65)
        gameIndexArray.push({
            value: str,
            label: str,
            isLeaf: false
        })
    }
    const [options, setOptions] = useState(gameIndexArray);
    const onChange = (value, selectedOptions) => {
        console.log('assss', value, selGame);
        if (selectedOptions.length > 1) {
            setSelGame(selectedOptions[selectedOptions.length - 1].value)
        } else {
            setSelGame('')
        }
    };


    return (
        <div style={{xbackground: 'red', xwidth: 111, height: 'calc(100vh - 70px)', padding: '0 10'}}>
            <div className="my-des bg" style={{borderx: '1px solid', width: "fit-content", margin: '22px 0'}}>

                <div style={{lineHeight: '34px', marginRight: '16px'}}>游戏选择</div>
                {/*可切换游戏项目<Cascader options={options} loadData={loadData} onChange={onChange} changeOnSelect style={{ width: 300, marginLeft: 30 }}/>*/}

                <Cascader placeholder="请选择" options={options} xdefaultValue={["M", "魔兽世界[端游]"]} loadData={loadData} onChange={onChange}
                          changeOnSelect allowClear={true}/>
                {/*<Cascader options={options} defaultValue={["M", "魔兽世界[端游]"]} loadData={loadData} onChange={onChange} changeOnSelect allowClear={false}/>*/}
                <div style={{lineHeight: '34px', marginRight: '16px', marginLeft: '16px'}}>线路选择</div>
                <Select bordered={false} style={{width: 120}} value={zhuanqu}
                        defaultValue={""} onChange={selectZhuanQu}>
                    <Option value={""}>不限专区</Option>
                    {
                        zhuanqus.map((val, idx) => {
                            return <Option key={idx} value={idx}>{val}</Option>
                        })
                    }
                </Select>

                <div style={{padding: '0 0px 0 30px', lineHeight: '34px', marginRight: '16px'}}>地区选择
                </div>
                <Select bordered={false} style={{width: 120}}
                        value={provinceSelect} defaultValue={-1} onChange={selectChange}>
                    <Option value={-1}>不限省份</Option>
                    {
                        province.map((val, idx) => {
                            return (
                                <Option key={idx} value={idx}>{val}</Option>
                            )
                        })
                    }
                </Select>


                <div
                    style={{padding: '0 0px 0 30px', lineHeight: '34px', marginRight: '16px'}}>购买时长
                </div>
                <Select bordered={false}
                        style={{verticalAlign: "middle", width: 120}} onChange={buyTimeSelectChange}
                        value={buyTimeSelect} defaultValue={1}>
                    <Option value={1}>一个月</Option>
                    <Option value={2}>二个月</Option>
                    <Option value={3}>三个月</Option>
                    <Option value={12}>一年</Option>
                </Select>

                <div style={{width: 120}}>
                    <Input placeholder="输入购买时长"
                           value={buyTimeInput}
                           onChange={changeTimeInput}
                           suffix={"月"}
                           style={{
                               marginLeft: '16px',
                               borderRadius: '5px',
                               lineHeight: '34px',
                               height: '34px'
                           }}
                           onFocus={(e) => e.target.select()}/>
                </div>

            </div>

            <div style={{padding: '0 16px', xbackgroundColor: 'red', borderRadius: 10}}>
                <div style={{padding: 10, backgroundColor: '#fff', borderRadius: 10}}>

                    <List
                        style={{
                            background: "#ffffff", padding: '0 20px',
                            height: "calc(100vh - 269px)", xminWidth: 1200, overflowY: 'auto'
                        }}
                        grid={{gutter: 24, column: 4, xs: 4, sm: 3, md: 3, lg: 3, xl: 4, xxl: 5}}
                        dataSource={citys}
                        renderItem={item => (
                            selGame &&
                            <List.Item style={{
                                border: '1px solid rgba(27,34,43,0.20)',
                                background: "#ffffff",
                                marginTop: '22px',
                                // height: '116px',
                                borderRadius: 8,
                                // width: 254,
                                // height: 122
                            }}>
                                {/*<div>egeew</div>*/}
                                <NodeCity cat={cat} setCat={setCatGoods} commodity={item} geame={selGame}/>
                            </List.Item>
                        )}
                    />
                    <Footer style={{
                        // height: 100,
                        backgroundColor: "#ffffff",
                        // marginLeft: 20,
                        // marginRight: 20,
                        // marginBottom: 20,
                    }}>
                        <Row style={{
                            // borderTop: '1px solid',
                            // borderTopColor: '#f5f5f5',
                            // verticalAlign: 'middle',
                            // paddingTop: 20,
                            // paddingBottom: 20
                        }}>
                            <Col span={18}>
                                已选<span className={css.footerText}> {cat.length} </span>个地区 <span
                                className={css.footerText}>{cat.reduce((a, b) => a + b.quantity, 0)} </span>个节点
                                <span className={css.footerText}>  {buyTimeInput || buyTimeSelect} </span>个月
                                <span style={{margin: '0 6px 0 40px'}}>余额:</span>
                                <Image style={{width: '20px', height: '20px'}} src={`${images}jiandou.png`}
                                       preview={false}/>
                                <span style={{marginRight: '40px'}}>金币 {balance}</span>

                                <span style={{marginRight: '8px'}}>账单金额:</span>
                                <span style={{marginRight: '40px'}}><span style={{
                                    color: '#FC6F01',
                                    fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold',
                                    fontWeight: 700
                                }}> {cat.reduce((a, b) => a + b.price * buyTimeInput, 0)} </span> 金币</span>
                            </Col>
                            <Col>
                                <Button size="large" htmlType="submit" type="primary" style={{
                                    border: 'none',
                                    background: 'linear-gradient(140deg,#ff7d3b 0%, #f92906 95%)',
                                    borderRadius: '30px',
                                    width: '200px',
                                    color: '#fff'
                                }}
                                        onClick={handlerBuy}>确认购买
                                </Button>
                            </Col>
                        </Row>
                    </Footer>
                </div>


            </div>

            <Modal title="订单详情" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText='确定' centered
                   cancelText="取消">
                <p>游戏项目：<span style={{color: 'red', fontWeight: '600'}}> {selGame} </span></p>
                <ModalTable columnsSel={columnsSel} nodeOrderItemList={cat}/>
                <p>购买时长<span className={css.footerText}> {buyTimeInput || buyTimeSelect} </span>个月</p>
                {/* <p>价格 <span style={{ color: 'red', fontWeight: '600' }}> {cat.reduce((a, b) => a + b.price, 0)} 金币</span></p> */}
                <p style={{marginBottom: '6px'}}>服务协议:</p>
                <Checkbox className={animation ? 'PageNodePurchase_cake__3XZGH' : ''} checked={checkbox}
                          onChange={(e) => {
                              setCheckbox(e.target.checked)
                          }} style={{color: 'red'}}>请仔细核对购买信息，节点为虚拟商品，一经出售，恕不退换</Checkbox>
            </Modal>
        </div>
    )
}

export default PageNodePurchaseSecond
