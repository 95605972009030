import "../../css/about.css"
// import PageHeader, { HeaderType } from "../PageHeader"
import { images } from "../../Constant"
import { Tabs } from "antd"
import PageFooter from "../PageFooter";
// import PageCompany from "../PageCompany";
// import data from "../../rawdata/AboutPage.json"
import PageHeaderSecond from "../PageHeaderSecond";
const { TabPane } = Tabs;

const About = () => {

    const valueStyle = { fontSize: '16px', fontFamily: 'Microsoft YaHei, Microsoft YaHei-Regular', fontWeight: '400', textAlign: 'left', color: 'rgba(0,0,0,0.85)', lineHeight: '40px' }
    const titleStyle = { fontSize: '24px', fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold', fontWeight: '700', textAlign: 'left', color: 'rgba(0,0,0,0.85)' }
    
    return (
        <div className="about" style={{ backgroundColor: "#EAEEFA" ,display:"flex",flexDirection:"column",minWidth:1300}}>
            <PageHeaderSecond />
            <main>
                <section>
                    <h4>关于我们</h4>
                    <p>
                        我们是一款基于WEB/PC端，为切换节点而生的网络助手。我们致力为用户提供安全、便捷、稳定的专业级IP代理服务。
                    </p>
                    <div className="item">
                        <img src={`${images}pic_chanp.png`} style={{width:'100%'}}/>

                    </div>
                </section>
                <section>
                    <h4>团队介绍</h4>
                    <p style={{maxWidth:800}}>
                        科盟科技有限公司成立于2014年，公司注册资本1000万元人民币，魔方IP助手拥有超过10万注册用户，云服务器数量达2万余台，
                        拥有20余人的专业团队，经验丰富，技术精湛，实力雄厚。科盟发展至今，已凝聚起国内实力的技术和开发团队，
                        积累起6年的动态拨号VPS管理、http代理和市场服务经验。提供专属热线/QQ咨询/服务单系统等多种沟通渠道，24小时不间断， 3分钟快速响应客户问题。
                    </p>
                    <div className="item" style={{display:"flex",flexDirection:"row",height:255,overflow:"hidden"}}>
                        <div style={{xbackground:'red',borderRadius:10,flex:20,height:'100%',marginRight:21,overflow:"hidden"}}>
                            <img src={`https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fstatic.tfcaijing.com%2Fwamei%2Fupload%2Fimage%2F20180420%2F1524200499487059989.jpg&refer=http%3A%2F%2Fstatic.tfcaijing.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1647484747&t=7956266c92c012f359ede5fee2f9320e`} style={{width:'100%'}}/>
                        </div>
                        <div style={{xbackground:'red',borderRadius:10,flex:12,height:'100%',marginRight:10}}>
                            <img src={`${images}house.png`} style={{width:'100%'}}/>
                        </div>
                        {/*<img src={`${images}pic_chanp.png`} style={{width:'100%'}}/>*/}
                    </div>
                </section>
                <section>
                    <h4>联系我们</h4>
                    <p>
                        有商务合作意向请通过以下联系方式联系我们 </p>
                    <div className="hezuo">
                        <div className="hezuoft">
                            <div>
                                <p>商务合作</p>
                                <a href="">联系人：***</a>
                            </div>
                            <div>
                                <p>电子邮件</p>
                                <a href="">*******</a>
                                <a href="">电话/微信：188-8888-8888</a>
                            </div>
                            <div>
                                <p>客户服务</p>
                                <a href="">在线客服：88888888</a>
                                <a href="">投诉热线：0771-88888888</a>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <PageFooter></PageFooter>

        </div>

    )
}

export default About