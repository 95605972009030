import { Input, Button, message, Modal } from "antd"
import { useEffect, useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"

const ModalNodeGroup = ({ show, onHide, selnodes, onNodeChangeCallback }) => {

    const [groupName, setGroupName] = useState(selnodes.length===1?selnodes[0].soldAccount:"")

    //  0001~9999
    const onGroupNameChange = (event) => {
        if(event.target.value.indexOf('.')!==-1) {
            return
        }
        if(event.target.value !== "") {
            let val = event.target.value.trim()
            if(!isNaN(val) && parseInt(val)<=9999)
                setGroupName(event.target.value)
        } else {
            setGroupName(event.target.value)
        }
    }

    const handlerGroupChange = () => {

        if(!groupName || groupName==="") {
            message.info("请输入分组名称0001~9999")
            return
        } 

        //  不足4位，补齐
        let groupNameStr = groupName.toString()
        while(groupNameStr.length < 4) {
            groupNameStr = '0'+ groupNameStr
        }

        let SoldId = []
        selnodes.map(val => {
            SoldId.push(val.ID.toString())
        })

        NetworkHelper.getInstance().SetNodeGroup(SoldId, groupNameStr).then(response => {
            console.log('设置分组', response)
            if(response.data.Code === 1000) {
                onNodeChangeCallback && onNodeChangeCallback(groupNameStr)
                onHide && onHide()
                message.info('节点分组成功', 2)
            } else {
                onHide && onHide()
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log(error))

    }

    useEffect(() => {
        setGroupName(selnodes.length===1?selnodes[0].soldAccount:"")
    }, [selnodes])

    return (
        <Modal
            maskClosable={true}
            transitionName=""
            maskTransitionName=""
            visible={show}
            onCancel={onHide}
            // size="sm"
            // aria-labelledby="contained-modal-title-vcenter"
            centered
            title={"更改分组"}
            footer={<div style={{ width: "100%", textAlign: "center" }}>
                <Button onClick={onHide}>取消</Button>
                <Button type="primary" onClick={handlerGroupChange}>确认</Button>
            </div>}
            ><Input value={groupName} onChange={onGroupNameChange}/>
        </Modal>
    )
}

export default ModalNodeGroup