import css from "../../css/HomePartners.module.css"
import { Carousel } from "antd"
import { useRef } from "react"
import { images } from "../../Constant"

const ModalSwiper = () => {
    const myRef = useRef(null);
    const data = [`${images}hzicon_1.png`, `${images}hzicon_2.png`, `${images}hzicon_3.png`, `${images}hzicon_5.png`, `${images}hzicon_4.png`, `${images}hzicon_6.png`, `${images}hzicon_7.png`, `${images}hzicon_8.png`]
    const lunboSetting = {
        dots: false,
        autoplay: true,
        arrows: false,
        slidesToShow: 4,
        speed: 500,
    };

    const handlePrev = () => {
        myRef.current.prev();
    }
    const handleNext = () => {
        myRef.current.next();
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={css.hticonL} onClick={handlePrev}>
                <img className={css.hticon} src={`${images}hticon_8.png`}></img>
            </div>
            <div style={{ width: '60%', alignItems: "center", paddingTop: '40px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: "900px" }}>
                        <Carousel {...lunboSetting} vertical="true" ref={myRef}>
                            {data.map((item, key) =>
                                <img key={key} className={css.listImg} src={item}></img>
                            )}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className={css.hticonR} onClick={handleNext}>
                <img className={css.hticon} src={`${images}hticon_8.png`}></img>
            </div>
        </div>
    )
}


const HomePartners = () => {
    return (
        <div style={{ width: "100%", background: "#EAEEFA", textAlign: "center" }}>
            <div style={{ width: "100%", paddingTop: 54, paddingBottom: 54 }} >
                <div>
                    <img src={`${images}icon_09.png`}></img>
                </div>
                <div className={css.containerTitle}> 支持市面上几乎主流的模拟器代理
                </div>
            </div>
            <div className={css.imgContainer} style={{ display: "inline-block", paddingTop: 11, paddingBottom: 33, }}>
                <img src={`${images}pic_06.png`}></img>
                {/*<ModalSwiper></ModalSwiper>*/}
            </div>
        </div>
    )
}

export default HomePartners