import { Col, Row } from "antd"
import css from "../css/HomeCompany.module.css"
import data from "../rawdata/PageCompany.json"

const PageCompany = () => {
    return (
        <div style={{ 
            width: "100%", background: '#202735', display: 'flex', overflowX: "hidden",
            justifyContent: 'center', paddingTop: 54, paddingBottom: 54 }} >
            
            <Row>
                <Col className='fl-c' style={{ width: 300 }}>
                    <div className={css.title}>{data.company[0].title}</div>
                    {data.company[0].child.map((val,idx) => <div className={css.value} key={idx}>{val}</div>)}
                    <div style={{ marginTop: 30 }} className={css.value}>
                        {data.link.title}
                        {data.link.child.map((val, idx) => idx === data.link.child.length - 1 ? ` ${val}` : ` ${val} |`)}
                    </div>
                </Col>
                <Col className='fl-c' style={{ width: 300 }}>
                    <div className={css.title}>{data.company[1].title}</div>
                    {data.company[1].child.map((val,idx) => <div className={css.value} key={idx}>{val}</div>)}
                    <div style={{ marginTop: 56 }} className={css.value}></div>
                </Col>
                <Col className='fl-c' style={{ width: 300 }}>
                    <div className={css.title}>{data.company[2].title}</div>
                    {data.company[2].child.map((val,idx) => <div className={css.value} key={idx}>{val}</div>)}
                    <div style={{ marginTop: 56 }} className={css.value}></div>
                </Col>
            </Row>

        </div>
    )

}

export default PageCompany