import { message, Table, Space, Button, Row, Layout, Select, Input, Popconfirm, Image, Modal } from 'antd'
import { FormOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react'
import SearchBar from './SearchBar'
import ModalNodeChange from './ModalNodeChange'
import ModalNodeRenew from './ModalNodeRenew'
import ModalNodeGroup from './ModalNodeGroup'
import ModalNodeRestore from './ModalNodeRestore'
import ModalNodeRemark from './ModalNodeRemark'
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { useNavigate } from "react-router"
import { getSFM } from '../../utils/helper'
import { NodeStatus } from './SearchBar';
import { normalDetailColumns } from '../../utils/ColumnTemplate';
import { images } from '../../Constant';
const { Option } = Select

const NodeSpecialColumn = ({ record, editTarget, groupNameCheck, onConfirmSaveCallback }) => {

    const [showIcon, setShowIcon] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [text, setText] = useState(editTarget)

    const btnEditClicked = () => {
        setShowIcon(true)
        if (text !== editTarget)
            setText(editTarget)
    }

    const onConfirmSave = () => {
        setShowConfirm(false)
        setShowIcon(false)

        if (groupNameCheck) {
            let groupNameStr = text.toString()
            while (groupNameStr.length < 4) {
                groupNameStr = '0' + groupNameStr
            }
            setText(groupNameStr)
        }
        onConfirmSaveCallback && onConfirmSaveCallback(record.ID, text)
    }

    const onCancel = () => {
        setShowConfirm(false)
        setShowIcon(false)
        if (text !== editTarget)
            setText(editTarget)
    }

    const onInputChange = (event) => {
        if (event.target.value.indexOf('.') !== -1) {
            return
        }
        if (event.target.value !== "") {
            let val = event.target.value.trim()
            if (!isNaN(val) && parseInt(val) <= 9999)
                setText(event.target.value)
        } else {
            setText(event.target.value)
        }
    }

    const onRemarkChange = (event) => {
        setText(event.target.value)
    }

    //  购买时间一天以内
    let now = new Date().getTime() / 1000
    let startTime = new Date(record.StartTime).getTime() / 1000
    let isNew = (now >= startTime) && (now - startTime <= 86400)

    return (
        <span >
            {showIcon &&
                <Popconfirm
                    title="是否保存？"
                    visible={showConfirm}
                    onConfirm={onConfirmSave}
                    onCancel={onCancel}>
                    <Input value={text}
                        onChange={groupNameCheck ? onInputChange : onRemarkChange}
                        maxLength={groupNameCheck ? 4 : 7}
                        onMouseLeave={() => setShowConfirm(true)}
                        onFocus={(e) => e.target.select()}
                        autoFocus={true} />
                </Popconfirm>
            }
            {!showIcon &&
                <>
                    /<span>{text}</span>
                    <Button type="text" onClick={btnEditClicked}>
                        <FormOutlined />
                    </Button>
                    {(isNew && groupNameCheck) && <Image preview={false} src={`${images}new.png`} height={16} width={24}/>}
                </>
            }
        </span >
    )
}

const PageNodeManager = () => {
    const navigator = useNavigate()
    //  SearchBar
    const [zoneData, setZoneData] = useState([])
    const [gameData, setGameData] = useState([])
    const [groupsData, setGroupsData] = useState([])
    const [searchBarShow, setSearchBarShow] = useState({
        radioGroup: false, zone: true, game: true, groups: true, search: true, nodeStatus: true, finishTime: true
    })
    const [searchData, setSearchData] = useState({
        timeType: "", zone: "", game: "", groups: "", keyword: "", nodeStatus: NodeStatus.Normal, finishTime: ""
    })
    //  Table
    const [managerList, setManagerList] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    //  Modal Change
    const [modalNodeChangeShow, setModalNodeChangeShow] = useState(false)
    const [selnodes, setSelNodes] = useState([])
    const [leftNodes, setLeftNodes] = useState([])
    const [modalRightNodes, setModalRightNodes] = useState([])
    const [selSoldGame, setSelSoldGame] = useState("")
    const [rightcurrentpage, setRightCurrentPage] = useState(1)
    const [oneChange, setOneChange] = useState(false)
    //  Modal Renew
    const [modalNodeRenewShow, setModalNodeRenewShow] = useState(false)
    const [oneRenew, setOneRenew] = useState(false)
    //  Modal Group
    const [modalNodeGroupShow, setModalNodeGroupShow] = useState(false)
    const [oneGroup, setOneGroup] = useState(false)
    //  Modal pauseNode
    const [modalNodeUnsubscribeShow, setModalNodeUnsubscribeShow] = useState(false)
    const [onePause, setOnePause] = useState(false)
    //  恢复节点
    const [modalNodeRestoreShow, setModalNodeRestoreShow] = useState(false)
    const [oneRestore, setOneRestore] = useState(false)
    //  导出
    const [modalNodeExportShow, setModalNodeExportShow] = useState(false)

    const [oneRemark, setOneRemark] = useState(false)
    const [modalNodeRemarkShow, setModalNodeRemarkShow] = useState(false)
    const [modifiedFlag, setModifiedFlag] = useState(false)

    const [modifiedGroupFlag, setModifiedGroupFlag] = useState(false)

    const [modalOrderItemDetailShow, setModalOrderItemDetailShow] = useState(false)
    const [orderItemNumber, setOrderItemNumber] = useState(null)
    const [orderItemDetailData, setOrderItemDetailData] = useState([])
    const [orderItemDetailDataLength, setOrderItemDetailDataLength] = useState(0)
    const [orderItemDetailPagination, setOrderItemDetailPagination] = useState({ current: 1, pageSize: 10 })
    let handlerOrderItemDetailTableChange = (pagination) => {
        pagination && setOrderItemDetailPagination(pagination)
    }


    const normalColumns = [
        {
            title: '节点名称/备注',
            dataIndex: 'Node',
            key: 'Node',
            align: 'center',
            width: 130,
            fixed: 'left',
            render: (_, record) => {
                const onConfirmSaveCallback = (SoldId, remark) => {

                    NetworkHelper.getInstance().SetNodeRemark([SoldId], remark).then(response => {
                        console.log('设置备注', response)
                        if (response.data.Code === 1000) {
                            message.info('备注设置成功', 2)
                            _reqTableData()
                        } else {
                            message.error(response.data.Message, 2)
                        }
                    }).catch(error => console.log(error))
                }

                return <>
                    <a style={{ color: "blue" }} onClick={() => {
                        setOrderItemNumber(record.ID)
                    }}>{record.Node}</a>
                    <NodeSpecialColumn
                        modifiedFlag={modifiedFlag}
                        record={record}
                        editTarget={record.Remarks}
                        onConfirmSaveCallback={onConfirmSaveCallback} />
                </>
            }
        },
        {
            title: '游戏项目',
            dataIndex: 'Game',
            key: 'Game',
            align: 'center',
            width: 170
        },
        {
            title: '地区/运营商',
            dataIndex: 'areaAndRoute',
            key: 'areaAndRoute',
            align: 'center',
            width: 160,
            render: (_, record) => `${record.Area}/${record.Route}`
        },
        // {
        //     title: '购买时间',
        //     dataIndex: 'StartTime',
        //     key: 'StartTime',
        //     align:'center',
        //     width: 120
        // },
        {
            title: '到期时间',
            dataIndex: 'EndTime',
            key: 'EndTime',
            align: 'center',
            width: 170,
            render: (_, record) => {
                if (record.EndDays >= 1) {
                    return `${parseInt(record.EndDays)}天后到期`
                } else {
                    let now = new Date().getTime() / 1000
                    let finish = new Date(record.EndTime).getTime() / 1000
                    if (finish > now)
                        return `${getSFM(finish - now)}`
                    else
                        return '已过期'
                }
            }
        },
        {
            title: '价格',
            dataIndex: 'Price',
            key: 'Price',
            align: 'center',
            width: 70,
            render: (_, record) => parseInt(record.Price)
        },
        {
            title: '分组',
            dataIndex: 'Group',
            key: 'Group',
            align: 'center',
            width: 100,
            render: (_, record) => {
                const onConfirmSaveCallback = (SoldId, groupName) => {

                    if (!groupName || groupName === "") {
                        message.info("请输入分组名称0001~9999")
                        return
                    }

                    //  不足4位，补齐
                    let groupNameStr = groupName.toString()
                    while (groupNameStr.length < 4) {
                        groupNameStr = '0' + groupNameStr
                    }


                    NetworkHelper.getInstance().SetNodeGroup([SoldId], groupNameStr).then(response => {
                        console.log('设置分组', response)
                        if (response.data.Code === 1000) {
                            message.info('节点分组成功', 2)
                            _reqTableData()
                        } else {
                            message.error(response.data.Message, 2)
                        }
                    }).catch(error => console.log(error))
                }

                return <NodeSpecialColumn
                    modifiedFlag={modifiedGroupFlag}
                    record={record}
                    editTarget={record.Group}
                    groupNameCheck={true}
                    onConfirmSaveCallback={onConfirmSaveCallback} />
            }
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            width: 155,
            align: 'center',
            fixed: 'right',
            render: (_, record) => {
                return (
                    // <Space size="middle">
                    //     <a onClick={() => onChangeClicked(record)}>切换</a>
                    //     <a onClick={() => onRenewClicked(record)}>续费</a>
                    //     <a onClick={() => onNodePauseClicked(record)}>退订</a>
                    //     <a onClick={() => onGroupClicked(record)}>分组</a>
                    // </Space>

                    <Select key={JSON.stringify(record)} xstyle={{ width: 70 }} value="" onChange={(event) => {
                        let key = event
                        if (key === "change") {
                            onChangeClicked(record)
                        }
                        if (key === "nerew") {
                            onRenewClicked(record)
                        }
                        if (key === "unsub") {
                            onNodePauseClicked(record)
                        }
                        if (key === "group") {
                            onGroupClicked(record)
                        }

                        if (key === "remark") {
                            onRemarkClicked(record)
                        }

                    }}>
                        <Option value="">操作</Option>
                        <Option value="change">切换</Option>
                        <Option value="nerew">续费</Option>
                        <Option value="unsub">暂停</Option>
                        {/* <Option value="group">分组</Option> */}
                        {/* <Option value="remark">备注</Option> */}
                    </Select>
                )
            }
        }
    ]




    const pauseColumns = [
        {
            title: '游戏项目',
            dataIndex: 'Game',
            key: 'Game',
            align: 'center',
        },
        {
            title: '地区',
            dataIndex: 'Area',
            key: 'Area',
            align: 'center',
        },
        {
            title: '剩余价值',
            dataIndex: 'PauseValues',
            key: 'PauseValues',
            align: 'center',
            render: (_, record) => {
                let { PauseValues } = record
                if (PauseValues) {
                    if (PauseValues >= 1) {
                        let PauseValuesStr = PauseValues.toString()
                        return `${PauseValuesStr.substring(0, PauseValuesStr.indexOf(".") + 3)}元`
                    } else {
                        return `${PauseValues}元`
                    }
                }
            }
        },
        {
            title: '价格',
            dataIndex: 'Price',
            key: 'Price',
            align: 'center',
            width: 80,
            render: (_, record) => parseInt(record.Price)
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            align: 'center',
            width: 100,
            render: (_, record) => {
                return (
                    <Button value="restore" onClick={() => { onRestoreNodeClicked(record) }}>恢复</Button>
                )
            }
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelNodes(selectedRows)
            setLeftNodes(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows) => {
            setSelNodes(selectedRows)
            setLeftNodes(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelNodes(selectedRows)
            setLeftNodes(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        selectedRowKeys: selnodes.map((val, idx, arr) => val.key)
    }

    const rowSelectionEmpty = () => {
        setSelectedRowKeys([])
        setSelNodes([])
        setManagerList([])
    }

    //  请求【节点管理】表格数据
    const _reqTableData = () => {

        NetworkHelper.getInstance().GetProjectData(
            currentPage,
            pageSize,
            searchData.zone,
            searchData.game,
            searchData.groups,
            searchData.keyword,
            searchData.finishTime
        ).then(response => {
            setLoading(false)

            if(response.data.Code === 1000) {
                if (response.data.ProjectList !== null) {
                    response.data.ProjectList.map((val, idx) => {
                        val.key = idx
                    })
                    setManagerList(response.data.ProjectList)
                } else {
                    setManagerList([])
                }
                setTotal(response.data.NodeQty)

                //  SearchBar需要的Data
                response.data.ScreenAreaList && setZoneData(response.data.ScreenAreaList)
                response.data.ScreenGameList && setGameData(response.data.ScreenGameList)
                response.data.ScreenGroupList && setGroupsData(response.data.ScreenGroupList)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(error => {
            console.log(' ')
        })
    }

    //  请求已暂停节点列表数据
    const _reqPauseTableData = () => {
        setLoading(true)
        NetworkHelper.getInstance().GetPauseNodeData(currentPage, pageSize, searchData.game, searchData.zone).then(response => {
            setLoading(false)

            if(response.data.Code === 1000) {
                console.log('GetPauseNodeData', `currentPage=${currentPage},pageSize=${pageSize},total=${response.data.PauseNodeQty},sy=${sy}`)

                if (response.data.PauseNodeList !== null) {
                    response.data.PauseNodeList.map((val, idx) => {
                        val.key = idx
                    })
                    setManagerList(response.data.PauseNodeList)
                    console.log('pppl',response.data.PauseNodeList)
                } else {
                    setManagerList([])
                }
                setTotal(response.data.PauseNodeQty)


                response.data.ScreenGameList && setGameData(response.data.ScreenGameList)
                response.data.ScreenAreaList && setZoneData(response.data.ScreenAreaList)
            } else {
                message.error(response.data.Message, 2)
            }

        }).catch(error => console.log('navigator("/")'))
    }

    //  请求【切换节点】窗口的可选节点
    const _reqRightTableData = () => {

        NetworkHelper.getInstance().GetAreaData(selSoldGame).then(response => {
            if(response.data.Code === 1000) {
                response.data.AreaList.map((val, idx) => {
                    val.key = idx
                    val.areaAndRoute = `${val.Area}/${val.Route}`
                })
                setModalRightNodes(response.data.AreaList)
            } else {
                message.error(response.data.Message, 2)
            }
        })
    }

    //  打开【切换节点】窗口
    let showNodeChange = () => {
        if (oneChange) {
            setSelSoldGame(leftNodes[0].Game)
            setModalNodeChangeShow(true)
            _reqRightTableData()
            return
        }

        if (selnodes.length <= 0) {
            message.info("请选择要切换的节点", 2)
            return
        }

        let onlyOne = true
        selnodes.forEach(val => {
            if (val.Game !== selnodes[0].Game) {
                onlyOne = false
            }
        })
        if (!onlyOne) {
            message.info("请选择相同游戏类型的节点",  2)
            return
        } else {
            setSelSoldGame(selnodes[0].Game)
        }

        setModalNodeChangeShow(true)
        _reqRightTableData()
    }

    //  打开【续费】窗口
    let showNodeRenew = () => {
        if (oneRenew) {
            setModalNodeRenewShow(true)
            return
        }

        if (selnodes.length <= 0) {
            message.info("请选择要续费的节点", 2)
            return
        }
        setModalNodeRenewShow(true)
    }

    //  打开【暂停】窗口
    let showNodeUnsubscribe = () => {
        if (onePause) {
            setModalNodeUnsubscribeShow(true)
            return
        }

        if (selnodes.length <= 0) {
            message.info("请选择要暂停的节点", 2)
            return
        }
        setModalNodeUnsubscribeShow(true)
    }

    //  打开【恢复】节点窗口
    let showNodeRestore = () => {
        if (oneRestore) {
            setSelSoldGame(leftNodes[0].Game)
            setModalNodeRestoreShow(true)
            _reqRightTableData()
            return
        }
        if (selnodes.length <= 0) {
            message.info("请选择要恢复的节点", 2)
            return
        }

        let onlyOne = true
        selnodes.forEach(val => {
            if (val.Game !== selnodes[0].Game) {
                onlyOne = false
            }
        })
        if (!onlyOne) {
            message.info("请选择相同游戏类型的节点", 2)
            return
        } else {
            setSelSoldGame(selnodes[0].Game)
        }

        setModalNodeRestoreShow(true)
        _reqRightTableData()
    }

    //  打开【分组】窗口
    let showNodeGroup = () => {
        if (oneGroup) {
            setSelSoldGame(leftNodes[0].Game)
            setModalNodeGroupShow(true)
            return
        }

        if (selnodes.length <= 0) {
            message.info("请选择要分组的节点", 2)
            return
        }

        // let onlyOne = true
        // selnodes.forEach(val => {
        //     if (val.Game !== selnodes[0].Game) {
        //         onlyOne = false
        //     }
        // })
        // if (!onlyOne) {
        //     message.info("同一分组不能分配不同项目", 2)
        //     return
        // } else {
            setSelSoldGame(selnodes[0].Game)
        // }

        setModalNodeGroupShow(true)
    }

    //  打开【备注设置】窗口
    let showNodeRemark = () => {
        if (oneRemark) {
            setSelSoldGame(leftNodes[0].Game)
            setModalNodeRemarkShow(true)
            return
        }


        if (selnodes.length <= 0) {
            message.info("请选择要设置备注的节点", 2)
            return
        }

        // let onlyOne = true
        // selnodes.forEach(val => {
        //     if (val.Game !== selnodes[0].Game) {
        //         onlyOne = false
        //     }
        // })
        // if (!onlyOne) {
        //     message.info("同一分组不能分配不同项目", 2)
        //     return
        // } else {
        //     setSelSoldGame(selnodes[0].Game)
        // }

        setModalNodeRemarkShow(true)
    }

    let showNodeExport = () => {
        if (selnodes.length <= 0) {
            message.info("请选择需要导出的节点", 2)
            return
        }
        setModalNodeExportShow(true)
    }

    let onChangeClicked = (record) => {
        setSelSoldGame(record.soldGame)
        setLeftNodes([record])
        setOneChange(true)
    }

    let onRenewClicked = (record) => {
        setLeftNodes([record])
        setOneRenew(true)
    }

    let onNodePauseClicked = (record) => {
        setLeftNodes([record])
        setOnePause(true)
    }

    let onRestoreNodeClicked = (record) => {
        setLeftNodes([record])
        setOneRestore(true)
    }

    let onGroupClicked = (record) => {
        setLeftNodes([record])
        setOneGroup(true)
    }

    let onRemarkClicked = (record) => {
        setLeftNodes([record])
        setOneRemark(true)
    }

    //  【SearchBar】点击搜索的回调函数
    let handlerSearch = (timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime, finishTime) => {
        setCurrentPage(1)
        // console.log('handlerSearch', { timeType: timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime, finishTime })
        setSearchData({ timeType: timeQuantum, zone, game, groups, keyword: searchText, nodeStatus, searchTime, finishTime })
    }
    //  当前页面的节点管理列表分页
    let handlerTableChange = (pagination) => {
        console.log('节点管理，切换页面', pagination)
        setLoading(true)
        rowSelectionEmpty()
        pagination && setCurrentPage(pagination.current)
        pagination && setPageSize(pagination.pageSize)
    }
    //  【切换节点】窗口的可选节点的分页操作
    let handlerRightTableChange = (pagination) => {
        pagination && setRightCurrentPage(pagination.current)
    }

    let handlerPauseNode = () => {

        let SoldId = []
        leftNodes.map(val => {
            SoldId.push(val.ID.toString())
        })

        NetworkHelper.getInstance().PauseNode(SoldId).then(response => {

            if (response.data.Code === 1000) {
                setModalNodeUnsubscribeShow(false)
                _reqTableData()
                if (leftNodes.length === 1 && selnodes.length > 1) {
                    const copyNodes = JSON.parse(JSON.stringify(selnodes))
                    let outIndex = -1
                    for (let i = 0; i < copyNodes.length; i++) {
                        if (copyNodes[i].ID === selnodes[0].ID) {
                            outIndex = i
                            break
                        }
                    }
                    outIndex !== -1 && copyNodes.splice(outIndex, 1)
                    setLeftNodes(copyNodes)
                } else {
                    rowSelectionEmpty()
                }

                message.info('节点暂停成功', 2)
            } else if (response.data.Code === 1) {
                message.error(response.data.Message, 2)
                setModalNodeUnsubscribeShow(false)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log('navigator("/")'))
    }

    let handlerNodeExport = () => {
        setModalNodeExportShow(false)

        const ExcelJS = require('exceljs');
        var workbook = new ExcelJS.Workbook();
        var wsheet = workbook.addWorksheet("data")

        const sortOrder = [
            'ID', 'Node', 'Game', 'Area', 'Group', 'Route', 'StartTime', 'EndTime', 'Price', 'EndDays', 'Remarks', 'IPAddress'
        ]
        let excelData = []
        selnodes.forEach(val => {
            let data = {}
            for (let i = 0; i < sortOrder.length; i++) {
                data[sortOrder[i]] = val[sortOrder[i]]
            }
            excelData.push(data)
        })

        //  表头
        const tHeader = [
            'ID', '节点名称', '游戏项目', '地区', '分组', '运营商', '开始时间', '结束时间', '售价', '剩余天数', '备注', 'IPAddress'
        ]
        for (let i = 0; i < tHeader.length; i++) {
            let str = String.fromCharCode(i + 65);
            wsheet.getCell(`${str}${1}`).value = tHeader[i]
        }
        //  内容
        let startLineIndex = 2
        for (let i = 0; i < excelData.length; i++) {
            // let keys = Object.keys(excelData[i])
            for (let j = 0; j < sortOrder.length; j++) {
                let str = String.fromCharCode(j + 65);
                wsheet.getCell(`${str}${startLineIndex}`).value = excelData[i][sortOrder[j]]
            }
            startLineIndex++
        }

        workbook.xlsx.writeBuffer().then(function (data) {
            var blob = new Blob([data], { type: "binary" })
            const filename = 'data.xlsx'
            const aElement = document.createElement('a')
            document.body.appendChild(aElement)
            aElement.style.display = 'none'
            aElement.href = URL.createObjectURL(blob)
            aElement.download = filename
            aElement.click()
            document.body.removeChild(aElement)
        })
    }

    useEffect(() => {
        //  节点状态切换，重置选择项
        console.log('jdzt',searchData , NodeStatus.Pause)
        rowSelectionEmpty()
        if (searchData.nodeStatus === NodeStatus.Pause) {
            console.log('vvvvv')
            setSearchBarShow({ nodeStatus: true, game: true, zone: true })
            _reqPauseTableData()
        } else {
            setSearchBarShow({ radioGroup: false, zone: true, game: true, groups: true, search: true, nodeStatus: true, finishTime: true })
            _reqTableData()
        }
    }, [currentPage, pageSize, searchData])

    useEffect(() => {
        if (oneChange) {
            showNodeChange()
            setOneChange(false)
        }
    }, [oneChange])

    useEffect(() => {
        if (oneRenew) {
            showNodeRenew()
            setOneRenew(false)
        }
    }, [oneRenew])

    useEffect(() => {
        if (onePause) {
            showNodeUnsubscribe()
            setOnePause(false)
        }
    }, [onePause])

    useEffect(() => {
        if (oneGroup) {
            showNodeGroup()
            setOneGroup(false)
        }
    }, [oneGroup])

    useEffect(() => {
        if (oneRestore) {
            showNodeRestore()
            setOneRestore(false)
        }
    }, [oneRestore])

    useEffect(() => {
        if (oneRemark) {
            showNodeRemark()
            setOneRemark(false)
        }
    }, [oneRemark])


    const _a = () => {
        NetworkHelper.getInstance().GetNodeDetail(orderItemNumber, orderItemDetailPagination.pageSize, orderItemDetailPagination.current).then(response => {
            if(response.data.Code === 1000) {
                setOrderItemDetailData(response.data.NodeOrderItemList)
                setOrderItemDetailDataLength(response.data.NodeOrderItemQty)
                setModalOrderItemDetailShow(true)
            } else {
                message.error(response.data.Message, 2)
            }
        })
    }

    useEffect(() => {
        if (orderItemNumber !== null)
            _a()
    }, [orderItemDetailPagination, orderItemNumber])

    const [sy, setSy] = useState(389)
    const [tableAutoScrollY, setTableAutoScrollY] = useState(0)

    useEffect(() => {
        window.addEventListener('resize',d=>{
            setTableAutoScrollY(document.getElementById('dti').clientHeight)
        })
        setTableAutoScrollY(document.getElementById('dti').clientHeight)
    }, [])


    return (
        <Layout style={{ width: "100%"}}>
            <div style={{margin:'22px 0',width:'100%'}}>
                <SearchBar
                    showModel={searchBarShow}
                    onSearchClicked={handlerSearch}
                    zoneData={zoneData}
                    gameData={gameData}
                    groupsData={groupsData}
                />
            </div>


            <div style={{background:'#F1F4FD',padding:'0px 16px 0px',paddingTop:0}} className='md-hfqhxf-bt'>
                <div style={{marginBottom:22}}  >
                    {
                        searchData.nodeStatus === NodeStatus.Pause ?

                            <Button onClick={showNodeRestore}>恢复</Button>
                            :
                            <Space  size={'large'}>
                                <Button onClick={showNodeChange}>切换</Button>
                                <Button onClick={showNodeRenew}>续费</Button>
                                <Button onClick={showNodeUnsubscribe}>暂停</Button>
                                <Button onClick={showNodeGroup}>分组</Button>
                                <Button onClick={showNodeRemark}>备注</Button>
                                <Button onClick={showNodeExport}>导出</Button>
                            </Space>
                    }
                </div>
                    <div id='dti' style={{background:' #ffffff',padding:0, borderRadius: 11,xwidth:'calc(100vw - 55px)',height:'calc(100vh - 216px)',overflow:"hidden"}}>


                    <Table

                        selectedRows={selnodes}
                        columns={searchData.nodeStatus === NodeStatus.Normal ? normalColumns : pauseColumns}
                        rowSelection={{ ...rowSelection }}
                        dataSource={managerList}
                        loading={loading}
                        pagination={{
                            size: "default",
                            showSizeChanger: true,
                            hideOnSinglePage: false,
                            showTotal: total2 => `共${total2}条数据`,
                            // style: { background: "#ffffff", marginTop: 0, paddingTop: 20, paddingRight: 20, paddingBottom: 20 },
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            style: {width:'100%',height:'55px',alignContent:'center',justifyContent: 'center',position:"fixed",bottom:'19px'},


                            onChange: (page,pageSize) => {
                                console.log(`Table.onChange page=${page},pageSize=${pageSize}`)
                            },
                            onShowSizeChange: (current,size) => {
                                console.log(`Table.onShowSizeChange current=${current},size=${size}`)
                            }
                        }}
                        onChange={handlerTableChange}
                        scroll={{ x: 'max-content' , y:tableAutoScrollY-133 }}
                        size="middle"
                        sticky
                    />

                    <ModalNodeChange
                        show={modalNodeChangeShow}
                        onHide={() => {
                            setModalNodeChangeShow(false)
                            if (leftNodes.length === 1 && selnodes.length > 1)
                                setLeftNodes(selnodes)
                        }}
                        onNodeChangeCallback={() => {
                            _reqTableData()
                            if (leftNodes.length === 1 && selnodes.length > 1)
                                setLeftNodes(selnodes)
                        }}
                        handlerrighttablechange={handlerRightTableChange}
                        selnodes={leftNodes}
                        rightnodes={modalRightNodes}
                        pagination={{ size: "default", showTotal: total2 => `共${total2}条数据`, current: rightcurrentpage, pageSize: pageSize }}
                    />
                </div>



            </div>




            <ModalNodeRenew
                show={modalNodeRenewShow}
                onHide={() => {
                    setModalNodeRenewShow(false)
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
                selnodes={leftNodes}
                onNodeRenewCallback={() => {
                    _reqTableData()
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
            />
            <ModalNodeGroup
                show={modalNodeGroupShow}
                onHide={() => {
                    setModalNodeGroupShow(false)
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
                selnodes={leftNodes}
                onNodeChangeCallback={() => {
                    // message.info('分组成功', 2)
                    setManagerList([])
                    _reqTableData()
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
            />
            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                // width="1200px"
                // height="500px"
                visible={modalNodeUnsubscribeShow}
                onCancel={() => {
                    setModalNodeUnsubscribeShow(false)
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
                title={"节点时间暂停"}
                footer={<div style={{ width: "100%", textAlign: "center"}}>
                    <Button
                        type="secondary"
                        onClick={() => {
                            setModalNodeUnsubscribeShow(false)
                            if (leftNodes.length === 1 && selnodes.length > 1)
                                setLeftNodes(selnodes)
                        }}>
                        取消
                    </Button>
                    <Button
                        type="primary"
                        onClick={handlerPauseNode}>
                        确认
                    </Button>
                </div>}
                >
                    <p>暂停节点会把当前节点IP释放，再次恢复使用将重新分配新的节点！</p>

<p>暂停规则：
每节点扣除24小时可用时长再转换为剩余价值。
到期时间小于7天的节点不允许暂停。</p>

<p>热门项目节点暂停后，可能无法第一时间恢复节点，请谨慎选择暂停</p>
            </Modal>

            <ModalNodeRestore
                show={modalNodeRestoreShow}
                onHide={() => {
                    setModalNodeRestoreShow(false)
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
                selnodes={leftNodes}
                onNodeChangeCallback={() => {
                    setLeftNodes([])
                    setSelNodes([])
                    setLeftNodes([])

                    _reqPauseTableData()
                    // if (leftNodes.length === 1 && selnodes.length > 1)
                    //     setLeftNodes(selnodes)
                }}
                handlerrighttablechange={handlerRightTableChange}
                rightnodes={modalRightNodes}
                pagination={{ size: "default", showTotal: total2 => `共${total2}条数据`, current: rightcurrentpage, pageSize: pageSize }}
            />

            <Modal
                visible={modalNodeExportShow}
                transitionName=""
                maskTransitionName=""
                onCancel={() => setModalNodeExportShow(false)}
                title={"导出Excel"}
                centered
                footer={<div style={{ width: "100%", textAlign: "center" }}>
                    <Button
                        type="secondary"
                        onClick={() => setModalNodeExportShow(false)}>
                        取消
                    </Button>
                    <Button
                        type="primary"
                        onClick={handlerNodeExport}>
                        确认
                    </Button>
                </div>}
                >
                    <p>确认导出节点Excel表单？</p>
            </Modal>


            <ModalNodeRemark
                show={modalNodeRemarkShow}
                onHide={() => {
                    setModalNodeRemarkShow(false)
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
                selnodes={leftNodes}
                onNodeChangeCallback={() => {
                    //  TODO
                    setManagerList([])

                    _reqTableData()
                    if (leftNodes.length === 1 && selnodes.length > 1)
                        setLeftNodes(selnodes)
                }}
            />


            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                width="1200px"
                visible={modalOrderItemDetailShow}
                onCancel={() => {
                    setOrderItemNumber(null)
                    setModalOrderItemDetailShow(false)
                }}
                title={"详情"}
                footer=""
                >

                    <Table
                        style={{ background: "#ffffff", minHeight: 500 }}
                        rowKey="ID"
                        columns={normalDetailColumns}
                        dataSource={orderItemDetailData}
                        onChange={handlerOrderItemDetailTableChange}
                        defaultCurrent={1}
                        pagination={{
                            size: "default",
                            showSizeChanger: true,
                            hideOnSinglePage: false,
                            showTotal: total2 => `共${total2}条数据`,
                            current: orderItemDetailPagination.current,
                            pageSize: orderItemDetailPagination.pageSize,
                            total: orderItemDetailDataLength
                        }}
                        scroll={{ y: 390 }}
                        size="small"
                        sticky
                    />

            </Modal>

        </Layout>
    )
}

export default PageNodeManager
