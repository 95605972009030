import { Radio, Table, Modal, Image, Button, Row, Col, message } from 'antd'
import { useEffect, useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { modalNodeRenewColumns } from "../../utils/ColumnTemplate"
import { useNavigate } from "react-router-dom"
import { images, TABS } from "../../Constant"

const ModalNodeRenew = ({ show, onHide, selnodes, onNodeRenewCallback }) => {

    const navigate = useNavigate()
    const [months, setMonths] = useState(1)
    const [total, setTotal] = useState(0)
    const [dialogShow, setDialogShow] = useState(false)

    const handlerToPayment = () => {
        onHide && onHide()
        navigate(TABS.PAYMENT)
    }

    let zoneNums = new Map()
    for (let i = 0; i < selnodes.length; i++) {
        if (!zoneNums.has(selnodes[i].soldArea)) {
            zoneNums.set(selnodes[i].soldArea, "")
        }
    }

    let caculateTotal = (months) => {
        let total = 0
        for (let i = 0; i < selnodes.length; i++) {
            let price = selnodes[i].Price
            total += months * price
        }
        setTotal(total)
    }

    let onMonthsChange = (event) => {
        setMonths(event.target.value)
        caculateTotal(event.target.value)
    }

    let onRenew = () => {
        setDialogShow(false)

        let SoldId = []
        selnodes.map(val => {
            SoldId.push(val.ID.toString())
        })

        NetworkHelper.getInstance().RenewProject(SoldId, months).then(response => {
            console.log('续费', response)
            if (response.data.Code === 1000) {
                sessionStorage.setItem("mofang-Balance", response.data.Balance)
                onHide()
                onNodeRenewCallback()
                message.info('节点续费成功', 2)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        caculateTotal(months)
    }, [selnodes])

    return (
        <>
            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                width="1200px"
                height="500px"
                visible={show}
                onCancel={onHide}
                // size="xl"
                // aria-labelledby="contained-modal-title-vcenter"
                title={"续费"}
                footer={<Row style={{ width: "100%" }}>
                    <Col span={20} style={{ textAlign: "left" }}>
                    {`已选择${zoneNums.size}个地区${selnodes.length}个节点${months}个月    余额：`}
                    <Image src={`${images}jiandou.png`} preview={false} />
                    {`金币 ${sessionStorage.getItem("mofang-Balance")}    `}
                    {`账单总额：${total}金币    金币 实扣费用：${ total}金币`}
                    </Col>
                    <Col span={4} style={{ textAlign: "right"}}>
                    <Button
                        disabled={sessionStorage.getItem("mofang-Balance") < total}
                        style={{ border: '1px solid #ffd031', background: '#ffd031', borderRadius: '30px', width: '150px', color: '#000' }}
                        type="primary"
                        danger>
                         <div 
                            style={{ fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold', padding: '0 32px', width: '100%', color: '#000' }}
                            onClick={() => setDialogShow(true)}>
                            续费
                        </div>
                    </Button>
                    </Col>
                </Row>}
                >

                    <Row>
                        <Col>
                            <Table
                                key="node-renew-table"
                                style={{ height: 290 }}
                                columns={modalNodeRenewColumns}
                                dataSource={selnodes}
                                pagination={{
                                    size: "default",
                                    showSizeChanger: true,
                                    hideOnSinglePage: false
                                }}
                                scroll={{ y: 200 }}
                                size="middle"
                            />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                        <Col>
                            请选择续费时长<Radio.Group
                                onChange={onMonthsChange}
                                // size="default" 
                                defaultValue={months}
                                buttonStyle="solid"
                                style={{ verticalAlign: "middle", paddingLeft: 10 }}>
                                <Radio.Button value={1}>1个月</Radio.Button>
                                <Radio.Button value={2}>2个月</Radio.Button>
                                <Radio.Button value={3}>3个月</Radio.Button>
                                <Radio.Button value={12}>1年</Radio.Button>
                            </Radio.Group>
                        </Col>
                        { sessionStorage.getItem("mofang-Balance") < total &&
                        <Col style={{ flex: 1, paddingRight: 20, textAlign: "right"}}>
                            <span style={{ verticalAlign: "text-bottom" }}>您的余额无法购买更多商品，请</span>
                            <a style={{ verticalAlign: "text-bottom", color: "blue" }} onClick={handlerToPayment} >充值</a>
                        </Col>
                        }
                    </Row>
            </Modal>

            <Modal 
                visible={dialogShow}
                onCancel={() => setDialogShow(false)}
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                title={"提示"}
                footer={<div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={() => setDialogShow(false)}>取消</Button>
                    <Button type="primary" onClick={onRenew}>确认</Button>
                </div>}
                >
                    <p>确认续费？</p>
            </Modal>
        </>
    )
}

export default ModalNodeRenew