import { useEffect, useState } from 'react'
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { Table, Layout, Modal, message } from 'antd';
import SearchBar from './SearchBar'
import useThrottle from '../../hooks/useThrottle';
import { pageDetail_NormalColumns } from '../../utils/ColumnTemplate';
// const { Header } = Layout;

const PageDetail = () => {
    const [orderList, setOrderList] = useState([])
    const [loading, setLoading] = useState(false)
    //  SearchBar
    const [groupsData, setGroupsData] = useState([])
    const [searchData, setSearchData] = useState({
        timeQuantum: "", zone: "", game: "", groups: "", searchText: '', keyword: "", searchTime: ['', '']
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [modalOrderItemDetailShow, setModalOrderItemDetailShow] = useState(false)
    const [orderItemNumber, setOrderItemNumber] = useState(null)
    const [orderItemDetailData, setOrderItemDetailData] = useState([])
    const [orderItemDetailDataLength, setOrderItemDetailDataLength] = useState(0)
    const [orderItemDetailPagination, setOrderItemDetailPagination] = useState({ current: 1, pageSize: 10 })
    let handlerOrderItemDetailTableChange = (pagination) => {
        pagination && setOrderItemDetailPagination(pagination)
    }


    //  搜索的回调
    const handlerSearch = useThrottle((timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime) => {
        setCurrentPage(1)
        setSearchData({ timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime })
    }, 500)
    //  当前页面的节点管理列表分页
    let handlerTableChange = (pagination) => {
        pagination && setPageSize(pagination.pageSize)
        pagination && setCurrentPage(pagination.current)
    }

    const columnsSel = [
        {
            title: '序号',
            render: (Type, Describe, index) => `${index + 1}`,
            align: 'center',
            fixed: 'left',
            width: "12%",

        },
        {
            title: '类型',
            dataIndex: 'Type',
            key: 'Type',
            align: 'center',
            width: "222",


        },
        {
            title: '详情',
            dataIndex: 'Describe',
            key: 'Describe',
            width: "28%",
            align: 'center',
            render: (_, record) => {
                if (record.Type === '在线充值') {
                    return record.Describe
                } else {
                    return <a style={{ color: "blue" }} onClick={() => {
                        setOrderItemNumber(record.Number)
                    }}>{record.Describe}</a>
                }
            }
        },
        {
            title: '时间',
            dataIndex: 'Time',
            key: 'Time',
            width: "18%",
            align: 'center'
        },
        {
            title: '金币',
            render: ({ ExpenditureAmount, IncomeAmount }) => {
                let value = 0
                if (parseFloat(ExpenditureAmount) > 0) {
                    value = `-${ExpenditureAmount}`
                } else if (parseFloat(IncomeAmount) > 0) {
                    value = `+${IncomeAmount}`
                }
                return value
            },
            key: 'ExpenditureAmount',
            width: "18%",
            align: 'center'
        },
        {
            title: '账户金币',
            dataIndex: 'Balance',
            key: 'Balance',
            width: "18%",
            align: 'center'
        }
    ]

    const _requestOrderDetail = () => {
        
        NetworkHelper.getInstance().GetNodeOrderItemDataDetail(
            orderItemNumber,
            orderItemDetailPagination.pageSize,
            orderItemDetailPagination.current
        ).then(response => {
            if(response.data.Code === 1000) {
                setOrderItemDetailData(response.data.NodeOrderItemList)
                setOrderItemDetailDataLength(response.data.NodeOrderItemQty)
                setModalOrderItemDetailShow(true)
            } else {
                message.error(response.data.Message, 2)
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        NetworkHelper.getInstance().GetOrderData(
            currentPage,
            pageSize,
            searchData.groups,
            searchData.searchTime[0],
            searchData.searchTime[1]
        ).then((res) => {
            setLoading(false)
            if (res.data.Code === 1000) {
                setTotal(res.data.OrderQty)
                setOrderList(res.data.OrderList)
                setGroupsData(res.data.ScreenTypeList)
            } else {
                message.error(res.data.Message, 2)
            }
        })
    }, [pageSize, currentPage, searchData])

    useEffect(() => {
        if (orderItemNumber !== null)
            _requestOrderDetail()
    }, [orderItemDetailPagination, orderItemNumber])

    const [tableAutoScrollY, setTableAutoScrollY] = useState(0)
    // const [tth, setTh] = useState(0)
 
    useEffect(() => {
        window.addEventListener('resize',d=>{
            setTableAutoScrollY(document.getElementById('dti').clientHeight)
        })
        setTableAutoScrollY(document.getElementById('dti').clientHeight)


    }, [])

    return (
        <>
            <Layout style={{ width: "100%" }}>
                <div style={{margin:'22px 0',width:'100%'}}>
                    <SearchBar
                        onSearchClicked={handlerSearch}
                        showModel={{ groups: true, range: true }}
                        // showModel={{ groups: true, range: true, game: true,  }}
                        groupsData={groupsData}
                    />
                </div>

                <div style={{background:'#F1F4FD',padding:16,paddingTop:0}}>

                    <div id='dti' style={{background:' #ffffff',padding:0, borderRadius: 11,xwidth:'calc(100vw - 55px)',height:'calc(100vh - 177px)',overflow:"hidden"}}>
                        <Table
                            // style={{ background: "#ffffff", paddingTop: 20 }}
                            // style={{background: "#fff", padding: 20 ,height:'90%',paddingTop:0}}
                            rowKey="ID"
                            columns={columnsSel}
                            dataSource={orderList}
                            onChange={handlerTableChange}
                            defaultCurrent={1}
                            loading={loading}
                            pagination={{
                                size: "default",
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                showTotal: total2 => `共${total2}条数据`,
                                // style: { paddingRight: 20, paddingBottom: 0 },
                                style: {width:'100%',height:'55px',alignContent:'center',justifyContent: 'center',position:"fixed",bottom:'19px'},

                                current: currentPage,
                                pageSize: pageSize,
                                total: total
                            }}
                            scroll={{ x: 'true', y:tableAutoScrollY-166 }}
                            size="middle"
                            sticky
                        />
                    </div>
                </div>


            </Layout>

            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                width="1200px"
                visible={modalOrderItemDetailShow}
                onCancel={() => {
                    setOrderItemNumber(null)
                    setModalOrderItemDetailShow(false)
                }}
                title={"详情"}
                footer=""
                >
                <Table
                    style={{ background: "#ffffff", height: 500 }}
                    rowKey="ID"
                    columns={pageDetail_NormalColumns}
                    dataSource={orderItemDetailData}
                    onChange={handlerOrderItemDetailTableChange}
                    defaultCurrent={1}
                    pagination={{
                        size: "default",
                        showSizeChanger: true,
                        hideOnSinglePage: false,
                        showTotal: total2 => `共${total2}条数据`,
                        current: orderItemDetailPagination.current,
                        pageSize: orderItemDetailPagination.pageSize,
                        total: orderItemDetailDataLength
                    }}
                    scroll={{ y: 390 }}
                    size="small"
                    sticky
                />
            </Modal>
        </>
    )
}

export default PageDetail