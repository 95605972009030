import "../../css/PageHeader.css"
import {Avatar, Dropdown, Space, Menu, Image} from "antd"
import {useNavigate} from "react-router"
import {LogoutOutlined} from '@ant-design/icons';
import {images, TABS} from "../../Constant";
import React, {useEffect} from "react";
import {NetworkHelper} from "com.guntherlau.services.mofang";

const PageHeader = (prop) => {
    let navigate = useNavigate()

    //没有登录返回主页
    useEffect(() => {
        if (sessionStorage.getItem("mofang-username")) {
        } else
            console.log('navigate("/")')
    }, [])


    const HandlerLougout = () => {
        const se = window.confirm("是否确认退出登录？")
        if (se === true) {
            sessionStorage.clear()
            // navigate(TABS.ROOT)
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="logoutItem" icon={<LogoutOutlined/>}>
                <a target="_blank" onClick={HandlerLougout}>
                    退出登录
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='head'>
            <div className='logo'></div>
            <div className='sel'>
                {
                    prop.sel
                }

            </div>
            <div className="av">
                <Dropdown overlay={menu}>
                    <Space>
                        {/*<img style={{width:'100%'}} src={`${images}icon_touxiang2.png`} alt=""/></div>*/}

                        <Avatar size={32} src={`${images}icon_touxiang2.png`}/>
                        <span>{sessionStorage.getItem("mofang-username")}</span>
                    </Space>
                </Dropdown>
            </div>

        </div>
    )
}

export default PageHeader
