import {Col} from "antd"
import {images} from "../../Constant"
import PageMap from "./PageMap";
import PageWhy from "./PageWhy";
import PageServices from "./PageServices";
import PageHelp from "./PageHelp";
import HomePartners from "./HomePartners";
import PageStart from "./PageStart";
import XxButton from "../cpms/dlButton";
import '../../css/home.css'
const PagePrimary = () => {

    return (<>
            <div style={{height: 777, display: "flex", paddingTop: 149, justifyContent: "space-between"}} className="imgbr">
                <Col span={3}></Col>
                <Col span={9}>
                    <img src={`${images}pic_13.png`}/>
                    <p onClick={e=>{
                        window.location.hash = '/about'
                    }} style={{
                        width: 454,
                        fontWeight: 500,
                        color: '#566176',
                        fontSize: 16,
                        paddingTop: 22,
                    }}>欢迎来到魔方IP助手，我们是一款基于WEB/PC端，为切换节点而生的网络助手。我们致力为用户提供安全、便捷、稳定的专业级IP代理服务。</p>
                    <XxButton/>
                </Col>
                <Col span={9}>

                    <img src={`${images}pic_01.png`} style={{paddingLeft: 44}}/>
                </Col>
                <Col span={3}></Col>
            </div>
            <PageMap/>
            <PageWhy/>
            <PageServices/>
            <PageHelp/>
            <HomePartners/>
            <PageStart/>
        </>

    )
}

export default PagePrimary