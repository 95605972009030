import { Form, Input, Button, message } from 'antd';
import css from "../css/LoginPage.module.css"
// import "../css/LoginPage.css"
import '../css/login.css'
import { useNavigate } from "react-router"
import { useEffect, useState } from 'react';
import useThrottle from '../hooks/useThrottle';
import { images, TABS } from '../Constant';
import {NetworkHelper} from "com.guntherlau.services.mofang"
import {CloseOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";

const ModalLogin = ({ modelType,handlerType }) => {
    let navigate = useNavigate()
    const [username, setUsername] = useState(sessionStorage.getItem("mofang-username"))
    const [password, setPassword] = useState(sessionStorage.getItem("mofang-password"))

    const validateMessages = {
        required: '请填写${name}',
    }

    const handlerSubmit = useThrottle((event) => {
        navigate(TABS.CENTER)
        console.log('vvvvvvv')
        return
        NetworkHelper.getInstance().LoginUserWeb(username, password).then(response => {
            if (response.data.Code === 1000) {
                navigate(TABS.CENTER)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(
            (err) => { message.error(err.Message) }
        )
        event && event.preventDefault()
    }, 200)

    useEffect(() => {
        //  自动登录
        let _username = sessionStorage.getItem("mofang-username")
        let _password = sessionStorage.getItem("mofang-password")
        if (_username && _password) {
            NetworkHelper.getInstance().LoginUserWeb(_username, _password).then(response => {
                if (response.data.Code === 1000) {
                    navigate(TABS.CENTER)
                } else {
                    message.error(response.data.Message, 2)
                }
            }).catch(
                (err) => { message.error(err.Message) }
            )
        }
    }, [])

    return (
    <div style={{display: modelType === 'login'?"":"none"}}>
        <div className="uni-login" ></div>
        <div className="login">
            <div className="left"></div>
            <div className="right">
                <div className="close" onClick={() => {
                    handlerType('')
                }}><CloseOutlined/></div>
                <h2>欢迎登录</h2>
                <h6>还没有账号？请先<a  type="link" onClick={() => { handlerType('register') }}>注册
                </a></h6>
                <Form name="control-ref" onFinish={()=> handlerSubmit()} validateMessages={validateMessages}>
                    <Form.Item  name="用户名/手机号码" rules={[{ required: true }]}>
                        <div className="input">

                            <Input
                                prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                }
                                bordered={false}
                                size="middle"
                                style={{ background: '#ffffff',}}
                                placeholder="用户名/手机号码"
                                value={username}
                                onChange={(event)=> setUsername(event.target.value)}
                            />
                        </div>


                    </Form.Item>

                    <Form.Item  name="密码" rules={[{ required: true }]}>

                        <div className="input">
                            <Input.Password
                                prefix={
                                    <LockOutlined />
                                }
                                bordered={false}
                                size="middle"
                                placeholder="密码"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </div>

                    </Form.Item>




                    <Form.Item style={{ width: '100%', display: 'flex' }}>
                        <div className="bt">
                            <Button type='link' onClick={() => { handlerType('resPassword') }}>忘记密码？</Button>
                            <Button size="large" onClick={()=>{navigate(TABS.CENTER)}} htmlType="submit" type="primary" style={{ background: '#7157FF', width: 144, color: '#eeeeee', fontWeight: '700' ,border:'none'}}>
                                <div style={{ widthx: '100%', color: '#ffffff', fontWeight: '700' }}>立即登录</div>
                            </Button>
                        </div>

                    </Form.Item>

                    <div style={{ width: '100%', display: 'flex', margin: '16px 0 0px 0' }}>

                    </div>
                </Form>


            </div>
        </div>
    </div>

    )
}

export default ModalLogin
