import { Button, Col, Row } from "antd"
import { images } from "../../Constant"

const PageHelp = () => {
    return (
        <Row style={{ background: '#EAEEFA', display: 'flex', justifyContent: 'center', paddingTop: 54, paddingBottom: 54 }} className=' fl-c' >
            <Col span={24} style={{ textAlign: "center" }}>
            <img src={`${images}icon_08.png`} />
            <p style={{ fontWeight:500,color:'#566176',fontSize:16, marginBottom:44,marginTop:10}}>极速稳定安全的网络给用户带来的是全新的操作体验。</p>
            <div style={{  width: "100%",display:"flex",justifyContent:"center",marginBottom:33}}>
                <div style={{ backgroundColor: "#FFFFFF", width: 960, height: 316, borderRadius: 30 }}>
                </div>
            </div>
            <Button type="primary" ghost>查看更多</Button>
            </Col>
        </Row>
    )
}

export default PageHelp