import { message, Table, Space, Button, Row, Layout, Select, Input, Modal } from 'antd'
// import { FormOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react'
import SearchBar from './SearchBar'
import { NetworkHelper } from "com.guntherlau.services.mofang"
// const { Header } = Layout
// const { Option } = Select

const PageDevices = () => {

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const [selectedArray, setSelectedArray] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalDate, setModalData] = useState({
        title: '',
        subTitle: '',
        confirm: () => {},
        showInput: false
    })

    const [oneCloseClient, setOneCloseClient] = useState(false)
    const [oneRestartClient, setOneRestartClient] = useState(false)
    const [oneShutDownComputer, setOneShutDownComputer] = useState(false)
    const [oneRebootComputer, setOneRebootComputer] = useState(false)
    const [oneRefreshNode, setOneRefreshNode] = useState(false)
    const [oneRunCmd, setOneRunCmd] = useState(false)
    const [cmdStr, setCmdStr] = useState('')
    const onOneCloseClient = (record) => {
        setSelectedArray([record])
        setOneCloseClient(true)
    }
    const ShowCloseClient = () => {

        setModalData({
            title: '关闭客户端',
            subTitle: `确认关闭客户端吗？`,
            confirm: (arr) => {
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, "CloseClient").then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            }
         })

        if(oneCloseClient) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要关闭的客户端", 2)
            return
        }
        setModalShow(true)
    }

    const onOneRestartClient = (record) => {
        setSelectedArray([record])
        setOneRestartClient(true)
    }

    const ShowRestartClient = () => {
        setModalData({
            title: '重启客户端',
            subTitle: `确认重启客户端吗？`,
            confirm: (arr) => {
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, "RestartClient").then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            }
         })

        if(oneRestartClient) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要重启的客户端", 2)
            return
        }
        setModalShow(true)
    }

    const onOneShutDownComputer = (record) => {
        setSelectedArray([record])
        setOneShutDownComputer(true)
    }

    const ShowShutDownComputer = () => {
        setModalData({
            title: '关闭计算机',
            subTitle: `确认关闭计算机吗？`,
            confirm: (arr) => {
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, "ShutDownComputer").then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            }
         })

        if(oneShutDownComputer) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要关闭的计算机", 2)
            return
        }
        setModalShow(true)
    }


    const onOneRebootComputer = (record) => {
        setSelectedArray([record])
        setOneRebootComputer(true)
    }
    const ShowRebootComputer = () => {
        setModalData({
            title: '重启计算机',
            subTitle: `确认重启计算机吗？`,
            confirm: (arr) => {
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, "RebootComputer").then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            }
         })

        if(oneRebootComputer) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要重启的计算机", 2)
            return
        }
        setModalShow(true)
    }


    const onOneRefreshNode = (record) => {
        setSelectedArray([record])
        setOneRefreshNode(true)
    }
    const ShowRefreshNode = () => {
        setModalData({
            title: '刷新节点',
            subTitle: `确认刷新节点吗？`,
            confirm: (arr) => {
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, "RefreshNode").then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            }
         })

        if(oneRefreshNode) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要刷新的节点", 2)
            return
        }
        setModalShow(true)
    }
    
    const onOneRunCmd = (record) => {
        setSelectedArray([record])
        setOneRunCmd(true)
    }
    const ShowRunCmd = () => {
        setModalData({
            title: '执行CMD命令行',
            subTitle: `确认要执行CMD命令行吗？`,
            confirm: (arr) => {
                let checkCmdStr = document.getElementById('tvCmdStr').value
                
                let OnlineId = []
                arr.map(val => {
                    OnlineId.push(val.ID.toString())
                })

                NetworkHelper.getInstance().SetDeviceOperateOrder(OnlineId, `RunCmd[${checkCmdStr}]`).then(response => {
                    if(response.data.Code === 1000) {
                        message.info(response.data.Message, 2)
                        setModalShow(false)
                        _requestTableData()
                    } else {
                        message.error(response.data.Message, 2)
                    }
                })
            },
            showInput: true
         })

        if(oneRunCmd) {
            setModalShow(true)
            return
        }

        if (selectedArray.length <= 0) {
            message.info("请选择要执行CMD命令行的节点", 2)
            return
        }
        setModalShow(true)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRows)
            setSelectedArray(selectedRows)
            console.log(`onChange ${selectedRows}`)
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRowKeys(selectedRows)
            setSelectedArray(selectedRows)
            console.log(`onSelect ${selectedRows}`)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRowKeys(selectedRows)
            setSelectedArray(selectedRows)
            console.log(`onSelectAll ${JSON.stringify(selectedRows)}`)
        },
        selectedRowKeys: selectedRowKeys.map((val, idx, arr) => val.key)
    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'ID',
        //     key: 'ID',
        //     align: 'center',
        //     fixed: 'left',
        // },
        {
            title: '设备名称',
            dataIndex: 'DeviceName',
            key: 'DeviceName',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'OS操作系统',
            dataIndex: 'OS',
            key: 'OS',
            align: 'center'
        },
        {
            title: 'IP地址',
            dataIndex: 'Ipaddress',
            key: 'Ipaddress',
            align: 'center'
        },
        {
            title: '软件版本',
            dataIndex: 'Version',
            key: 'Version',
            align: 'center',
        },
        {
            title: '登陆时间',
            dataIndex: 'LoginTime',
            key: 'LoginTime',
            align: 'center',
        },
        {
            title: '项目名称',
            dataIndex: 'Project',
            key: 'Project',
            align: 'center',
        },
        {
            title: '项目分组',
            dataIndex: 'Group',
            key: 'Group',
            align: 'center',
        },
        {
            title: '节点数',
            dataIndex: 'NodeNumber',
            key: 'NodeNumber',
            align: 'center',
        },
        {
            title: '进程数量',
            dataIndex: 'ProcessTreesNumber',
            key: 'ProcessTreesNumber',
            align: 'center',
        },
        {
            title: '发送流量',
            dataIndex: 'SendFlow',
            key: 'SendFlow',
            align: 'center',
        },
        {
            title: '接收流量',
            dataIndex: 'RecvFlow',
            key: 'RecvFlow',
            align: 'center',
        },
        // {
        //     title: '操作',
        //     dataIndex: '',
        //     key: 'x',
        //     align: 'center',
        //     width: 100,
        //     fixed: 'right',
        //     render: (_, record) => {
        //         return (
        //             <Select disabled key={JSON.stringify(record)} style={{ width: 130 }} value="" onChange={(event) => {
        //                 let key = event
        //                 if (key === "CloseClient") {
        //                     onOneCloseClient(record)
        //                 }
        //                 if (key === "RestartClient") {
        //                     onOneRestartClient(record)
        //                 }
        //                 if (key === "ShutDownComputer") {
        //                     onOneShutDownComputer(record)
        //                 }
        //                 if (key === "RebootComputer") {
        //                     onOneRebootComputer(record)
        //                 }
        //                 if (key === "RefreshNode") {
        //                     onOneRefreshNode(record)
        //                 }
        //                 if (key === "RunCmd") {
        //                     onOneRunCmd(record)
        //                 }

        //             }}>
        //                 <Option value="">操作</Option>
        //                 <Option value="CloseClient">关闭客户端</Option>
        //                 <Option value="RestartClient">重启客户端</Option>
        //                 <Option value="ShutDownComputer">关闭计算机</Option>
        //                 <Option value="RebootComputer">重启计算机</Option>
        //                 <Option value="RefreshNode">刷新节点</Option>
        //                 <Option value="RunCmd">执行CMD命令行</Option>
        //             </Select>
        //         )
        //     }
        // }
        
    ]

    const rowSelectionEmpty = () => {
        setSelectedRowKeys([])
        setDataList([])
    }

    let handlerTableChange = (pagination) => {
        setLoading(true)
        rowSelectionEmpty()
        pagination && setPage(pagination.current)
        pagination && setPageSize(pagination.pageSize)
    }

    const _requestTableData = () => {
        NetworkHelper.getInstance().GetDeviceData(1, 10, "").then(response => {
            setLoading(false)
            if(response.data.Code === 1000) {
                if(response.data.DeviceList) {
                    response.data.DeviceList.length>0 &&
                    response.data.DeviceList.map((val,idx) => {
                        val.key = idx
                    })
                    setDataList(response.data.DeviceList)
                }
                setTotal(response.data.DeviceQty)
            } else {
                message.error(response.data.Message, 2)
            }
            
        })
    }

    const [sy, setSy] = useState(389)
    useEffect(() => {
        // console.log('调整表格ScrollY')
        //  ManagerPage上方的高度
        let managerHeader = 60
        //  SearchBar的高度
        let searchBarHeight = 64
        //  批量操作按钮的 高度
        let buttons = 52
        //  表头的高度
        let hh = 48
        //  分页栏的高度
        // let pagi = 72
        let pagi = 130
        let result = window.innerHeight - managerHeader - searchBarHeight - buttons - hh - pagi - 16
        // console.log('result', result)
        setSy(result )
    }, [])

    useEffect(() => {
        _requestTableData()
    }, [page, pageSize])

    useEffect(() => {
        if (oneCloseClient) {
            ShowCloseClient()
            setOneCloseClient(false)
        }
    }, [oneCloseClient])
    useEffect(() => {
        if (oneRestartClient) {
            ShowRestartClient()
            setOneRestartClient(false)
        }
    }, [oneRestartClient])
    useEffect(() => {
        if (oneShutDownComputer) {
            ShowShutDownComputer()
            setOneShutDownComputer(false)
        }
    }, [oneShutDownComputer])
    useEffect(() => {
        if (oneRebootComputer) {
            ShowRebootComputer()
            setOneRebootComputer(false)
        }
    }, [oneRebootComputer])
    useEffect(() => {
        if (oneRefreshNode) {
            ShowRefreshNode()
            setOneRefreshNode(false)
        }
    }, [oneRefreshNode])
    useEffect(() => {
        if (oneRunCmd) {
            ShowRunCmd()
            setOneRunCmd(false)
        }
    }, [oneRunCmd])

    return (
        <div >
            <div style={{margin:'22px 0',width:'100%'}}>

            <SearchBar
                    showModel={{ groups: true }}
                    // onSearchClicked={handlerSearch}
                    // zoneData={zoneData}
                    // gameData={gameData}
                    // groupsData={groupsData}
                />
            </div>
            <div style={{background:'#F1F4FD',padding:10}}>

            <div style={{backgroundColor:"white",padding:10,borderRadius:11}}>
                <Row style={{
                    background: "#ffffff",
                    paddingLeft: 50, paddingBottom: 20
                }}>
                    <Space>
                        <Button disabled key="CloseClient" onClick={ShowCloseClient}>关闭客户端</Button>
                        <Button disabled key="RestartClient" onClick={ShowRestartClient}>重启客户端</Button>
                        <Button disabled key="ShutDownComputer" onClick={ShowShutDownComputer}>关闭计算机</Button>
                        <Button disabled key="RebootComputer" onClick={ShowRebootComputer}>重启计算机</Button>
                        <Button disabled key="RefreshNode" onClick={ShowRefreshNode}>刷新节点</Button>
                        <Button disabled key="RunCmd" onClick={ShowRunCmd}>执行CMD命令行</Button>
                    </Space>
                </Row>
                <Table
                    // style={{ background: "#ffffff", padding: 0, margin: 0 }}
                    style={{background: "#fff", padding: 20 ,margin:'20px',height:'90%'}}
                    // bordered
                    selectedRows={selectedRowKeys}
                    columns={columns}
                    rowSelection={{ ...rowSelection }}
                    dataSource={dataList}
                    loading={loading}
                    pagination={{
                        size: "default",
                        showSizeChanger: true,
                        hideOnSinglePage: false,
                        showTotal: total2 => `共${total2}条数据`,
                        // style: { background: "#ffffff", marginTop: 0, paddingTop: 20, paddingRight: 20, paddingBottom: 20 },
                        style: { paddingRight: 20 },
                        current: page,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={handlerTableChange}
                    // scroll={{ x: 800, y: sy }}
                    size="middle"
                    // sticky
                />
            </div>
            </div>
        </div>
    )
}

export default PageDevices