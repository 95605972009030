import PageFooter from "../PageFooter"

import PageHeaderSecond from "../PageHeaderSecond"
import PagePrimary from "./PagePrimary"


const HomePage = () => {

    // useEffect(() => {
    //     let deviceWidth = document.documentElement.clientWidth
    //     let scale = deviceWidth/1440
    //     // document.body.style.zoom = scale
    //     document.getElementById("HomePage").style.zoom = scale
    // }, [])

    return (
        <div idx="HomePage"
             style={{backgroundColor: "#EAEEFA", display: "flex", flexDirection: "column", minWidth: 1300}}>
            <PageHeaderSecond/>
            <PagePrimary/>
            <PageFooter/>

        </div>
    )
}

export default HomePage