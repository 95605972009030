import { Col, Row } from "antd"
import { images, downloadUrl } from "../../Constant"
import FileDownload from "../FileDownload"
import css from "../../css/HomePartners.module.css";
import XxButton from "../cpms/dlButton";
const PageStart = () => {
    return (
        <Row style={{ paddingTop: 54, paddingBottom: 54 }}>
            <Col span={24} style={{ textAlign: "center" }}>
                <img src={`${images}icon_11.png`} />
                <p className={css.containerTitle} style={{marginBottom:60}}>准备好开始了吗？魔方即将带你开始一场不一样网络体验。</p>
                <XxButton/>
                {/*<FileDownload*/}
                {/*imageSrc={`${images}tpicon_2.png`}*/}
                {/*text="PC新版下载"*/}
                {/*url={downloadUrl} />*/}
            </Col>
        </Row>
    )
}

export default PageStart