
export function getSFM(seconds, dateFormat = 'D天H小时i分s秒') {
    var obj = {};
    
    if(seconds >= 86400) {
        obj.D = Number.parseInt(seconds / 86400)
        obj.H = Number.parseInt((seconds - obj.D * 86400) / 3600)
        obj.i = Number.parseInt((seconds - obj.D * 86400 - obj.H * 3600) / 60)
        obj.s = Number.parseInt(seconds - obj.D * 86400 - obj.H * 3600 - obj.i * 60)
    } else {
        obj.H = Number.parseInt(seconds / 3600);
        obj.i = Number.parseInt((seconds - obj.H * 3600) / 60);
        obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60);
    }

    //	if (obj.H < 10) {
    //		obj.H = '0' + obj.H;
    //	}
    //	if (obj.i < 10) {
    //		obj.i = '0' + obj.i;
    //	}
    //	if (obj.s < 10) {
    //		obj.s = '0' + obj.s;
    //	}
    
    // 3.解析
    var rs = dateFormat.replace('D', obj.D).replace('H', obj.H).replace('i', obj.i).replace('s', obj.s);
    return rs;
}

// YYYY-MM-dd HH:mm:ss格式
export function formatDate(value) {
  var date = new Date(value);
  var y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    h = date.getHours(),
    i = date.getMinutes(),
    s = date.getSeconds();
  if (m < 10) { m = '0' + m; }
  if (d < 10) { d = '0' + d; }
  if (h < 10) { h = '0' + h; }
  if (i < 10) { i = '0' + i; }
  if (s < 10) { s = '0' + s; }
  var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
  return t;
}

export function randomNum(minNum,maxNum){ 
  switch(arguments.length){ 
      case 1: 
          return parseInt(Math.random()*minNum+1,10); 
      break; 
      case 2: 
          return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
      break; 
          default: 
              return 0; 
          break; 
  } 
} 