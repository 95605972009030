import { Row, Col, Space } from "antd"
import { images } from "../../Constant"

const PageServices = () => {

    return (
        <Row style={{ paddingTop: 54, paddingBottom: 54 }}>
            <Col span={24} style={{ textAlign: "center" }}>
                <img src={`${images}icon_07.png`} style={{ paddingBottom: 20,borderRadius:32 }} />
                <p style={{ fontWeight:500,color:'#566176',fontSize:16, marginBottom:44,marginTop:10}}>兼容多种浏览器/PC系统同时运行使用，最新动态IP传输封装技术为您保驾护航。</p>
                <img src={`${images}pic_03.png`} style={{ width: 954, marginTop: 24,borderRadius:32 }} />

            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
                <Space style={{ width: 954, textAlign: "center", paddingTop: 20 }}>
                    <img src={`${images}pic_04.png`} style={{borderRadius:32}} />
                    <img src={`${images}pic_05.png`} style={{ marginLeft: 20,borderRadius:32 }}/>
                </Space>
            </Col>
        </Row>
    )
}

export default PageServices