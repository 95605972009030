import {
    FileTextOutlined,
    BarChartOutlined,
    WalletOutlined,
    FileSearchOutlined,
    PartitionOutlined,
    NotificationFilled
} from '@ant-design/icons'
import {Layout, List, Button} from "antd"
import {useParams, useNavigate} from "react-router-dom"
import "../../css/ManagerPage.less"
import PageHeader from "./PageHeader"
import PageCenter from "./PageCenter"
import PageNodeManager from "./PageNodeManager"
import PagePaymentSeventh from "./PagePaymentSeventh"
import PageNodePurchaseSecond from "./PageNodePurchaseSecond"
import PageDetail from "./PageDetail"
import PageHistory from "./PageHistory"
import PageDevices from "./PageDevices"
import {TABNAMES, TABS} from '../../Constant'
import {useEffect} from "react";


const ManagerPage = () => {

    let navigate = useNavigate()
    let {tab} = useParams()

    const tabs = [
        {tab: TABNAMES.NODEMGR, text: "节点管理", url: TABS.NODEMGR, icon: <BarChartOutlined/>},
        {tab: TABNAMES.PAYMENT, text: "我要充值", url: TABS.PAYMENT, icon: <WalletOutlined/>},
        {tab: TABNAMES.NODEPUR, text: "购买节点", url: TABS.NODEPUR, icon: <PartitionOutlined/>},
        {tab: TABNAMES.DETAIL, text: "账号明细", url: TABS.DETAIL, icon: <FileTextOutlined/>},
        {tab: TABNAMES.HISTORY, text: "变更历史", url: TABS.HISTORY, icon: <FileSearchOutlined/>},
        {tab: TABNAMES.CENTER, text: "个人中心", url: TABS.CENTER, icon: <NotificationFilled/>},
        {tab: TABNAMES.DEVICES, text: "设备列表", url: TABS.DEVICES, icon: <FileSearchOutlined/>}
    ]
    let dsgsg = {
        fontSize: 16,
        fontWeight: 555,

    }

    const dataSourceTabs = tabs.map(val =>
        <Button
            type="text"
            style={{color: tab === val.tab ? '#7157ff' : 'rgb(43,43,43)',background:'#fff'}}
            className="click-btn"
            onClick={() => navigate(val.url)}>
            <div style={{
                display: 'flex',
                margin: "0 2px",
                lineHeight: '1.0',
                // borderBottom: tab === val.tab ? '3px #7157ff solid' : '',
                padding: 3,
                paddingBottom:6,
                background:'#ffffff',
                boxShadow:tab === val.tab ? 'rgb(255, 255, 255) 20px 19px 4px inset, rgb(255, 255, 255) -19px 16px 3px inset, rgb(113, 87, 255) 0px -3px 0px inset' : '',
            }}>
                {/*{val.icon}*/}
                <div style={dsgsg}>{val.text}</div>
            </div>
        </Button>
    )

    useEffect(s => {
        // fetch("https://unpkg.com/spacingjs").then(async (res) => eval(await res.text()))
        setInterval(d => {
            // return
            try {
                for (const nodeListOfElement of document.querySelectorAll('iframe')) {
                    if (nodeListOfElement.getAttribute('id')==='ifr1') continue
                    nodeListOfElement.remove()
                }
            } catch (e) {
            }

        }, 1111)
    }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", minWidth: "fit-content"}}>
            <PageHeader sel={dataSourceTabs}/>
            {/*<div style={{background:'yellow',height:66, minWidth:'calc(100vw - 17px)'}}></div>*/}
            <div style={{background: '', minWidth: "fit-content"}}>
                {tab === TABNAMES.NODEMGR && <PageNodeManager/>}
                {tab === TABNAMES.PAYMENT && <PagePaymentSeventh/>}
                {tab === TABNAMES.NODEPUR && <PageNodePurchaseSecond/>}
                {tab === TABNAMES.DETAIL && <PageDetail/>}
                {tab === TABNAMES.HISTORY && <PageHistory/>}
                {tab === TABNAMES.CENTER && <PageCenter/>}
                {tab === TABNAMES.DEVICES && <PageDevices/>}
            </div>
        </div>

    )
}

export default ManagerPage