import { Input, Button, message, Modal } from "antd"
import { useEffect, useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"

const ModalNodeGroup = ({ show, onHide, selnodes, onNodeChangeCallback }) => {

    const [groupName, setGroupName] = useState(selnodes.length===1?selnodes[0].soldAccount:"")

    const onGroupNameChange = (event) => {
        setGroupName(event.target.value)
    }

    const handlerGroupChange = () => {



        let SoldId = []
        selnodes.map(val => {
            SoldId.push(val.ID.toString())
        })

        NetworkHelper.getInstance().SetNodeRemark(SoldId, groupName).then(response => {
            console.log('设置备注', response)
            if(response.data.Code === 1000) {
                onNodeChangeCallback && onNodeChangeCallback(groupName)
                onHide && onHide()
                message.info('备注设置成功', 2)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log(error))

    }

    useEffect(() => {
        setGroupName(selnodes.length===1?selnodes[0].soldAccount:"")
    }, [selnodes])

    return (
        <Modal
            maskClosable={true}
            transitionName=""
            maskTransitionName=""
            visible={show}
            onCancel={onHide}
            // size="sm"
            // aria-labelledby="contained-modal-title-vcenter"
            centered
            title={"设置备注"}
            footer={<div style={{ width: "100%", textAlign: "center" }}>
                <Button onClick={onHide}>取消</Button>
                <Button type="primary" onClick={handlerGroupChange}>确认</Button>
            </div>}
            >
                <Input value={groupName} onChange={onGroupNameChange} maxLength={7}/>
        </Modal>
    )
}

export default ModalNodeGroup