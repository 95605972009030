import { Radio, Table, Row, Col, Button, message, Modal } from 'antd'
import { useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { getSFM } from '../../utils/helper';
import { modalNodeRestore_leftColumns, modalNodeRestore_rightColumns } from "../../utils/ColumnTemplate"

const ModalNodeRestore = ({onNodeChangeCallback, selnodes, rightnodes, show, onHide, pagination, handlerrighttablechange}) => {

    const [selNode, setSelNode] = useState(null)
    const [dialogShow, setDialogShow] = useState(false)

    let handlerSelNodeChange = (event) => {
        console.log("handlerSelNodeChange", event.target.value)
        setSelNode(event.target.value)
    }


    const onClose = () => {
        setSelNode(null)
        onHide && onHide()
    }

    let handlerChange = () => {

        let SoldId = []
        selnodes.map(val => {
            SoldId.push(val.ID.toString())
        })

        let NewArea = selNode.Area
        let NewGame = selnodes[0].Game

        NetworkHelper.getInstance().RestoreNode(SoldId, NewArea, NewGame).then(response => {
            if(response.data.Code === 1000) {
                setDialogShow(false)
                onNodeChangeCallback && onNodeChangeCallback()
                onHide && onHide()
                message.info('节点恢复成功', 2)
            } else {
                setDialogShow(false)
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log('navigator("/")'))
    }

    return (
        <>
            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                width="1200px"
                height="500px"
                visible={show}
                onCancel={onClose}
                // size="xl"
                // aria-labelledby="contained-modal-title-vcenter"
                title={"节点恢复"}
                footer={<div style={{ width: "100%", textAlign: "center"}}>
                    <Button type="primary" onClick={onHide} onClick={() => {
                        if(selNode === null) {
                            message.error('未选择恢复节点', 2)
                            return
                        }
                        setDialogShow(true)
                    }}>一键恢复</Button>
                </div>}
            >
                    <Row>
                        <Col span={12}>
                            已选择节点列表
                        </Col>
                        <Col span={12}>
                            可恢复节点地区
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Table
                                key="node-sel-table"
                                style={{ height: 290 }}
                                columns={modalNodeRestore_leftColumns}
                                dataSource={selnodes}
                                pagination={false}
                                scroll={{ y: 200 }}
                                size="small"
                            />
                        </Col>
                        <Col span={12}>
                            <Radio.Group onChange={handlerSelNodeChange}>
                                <Table
                                    key="node-change-table"
                                    style={{ height: 290 }}
                                    columns={modalNodeRestore_rightColumns}
                                    dataSource={rightnodes}
                                    pagination={pagination}
                                    onChange={handlerrighttablechange}
                                    scroll={{ y: 200 }}
                                    size="small"
                                />
                            </Radio.Group>
                        </Col>
                    </Row>
            </Modal>
            <Modal
                maskClosable={true}
                transitionName=""
                maskTransitionName=""
                centered
                visible={dialogShow}
                onCancel={() => setDialogShow(false)}
                title={"提示"}
                footer={<div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={() => setDialogShow(false)}>取消</Button>
                    <Button type="primary" onClick={handlerChange}>确认</Button>
                </div>}
                >
                    <p>您将恢复{selnodes.length}个节点到{selNode && selNode.Area} </p>
            </Modal>
        </>
    )
}

export default ModalNodeRestore
