import { Form, Input, Button, message } from 'antd';
import css from "../css/LoginPage.module.css"
import "../css/LoginPage.css"
import { useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import useThrottle from '../hooks/useThrottle';
import { images } from '../Constant';
import '../css/login.css'
import {CloseOutlined, LockOutlined, MobileOutlined, RobotOutlined, UserOutlined} from "@ant-design/icons";

const ModalResetPassword = ({modelType, handlerType}) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [smsPhone, SetSmsPhone] = useState('')
    const [smscode, SetSmscode] = useState('')
    const [countDownSecond, setCountDownSecond] = useState(0)
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleCountDown = (seconds = 60, time = 1000) => {
        let second = seconds;
        let timer = (countDown) => setTimeout(countDown, time);
        const countDown = () => {
            if (second > 0) {
                second--;
                setCountDownSecond(second);
                console.log('time', second);
            }
            if (second === 0) {
                setCountDownSecond(second);
                return
            }
            timer(countDown)
        };
        countDown()
    };


    let handlerCode = useThrottle((event) => {
        // if (!username) {
        //     message.error("请填写用户名", 2)
        //     return false;
        // }

        if (smsPhone.trim() === "") {
            message.info("请填写手机号码", 2)
            return false;
        }

        if (!(/^1[3456789]\d{9}$/.test(smsPhone))) {
            console.log("手机号码有误，请重填", smsPhone)
            message.info("手机号码有误，请重填", 2)
            return false;
        }
        // 获取验证码倒计时
        NetworkHelper.getInstance().SendSmsV3(smsPhone, 'ResetPassword').then((res) => {
            if (res && res.data.Code === 1000) {
                handleCountDown(60)
                message.success("短信发送成功！", 2)
            } else {
                message.info(res.data.Message, 2)
            }
        })
            .catch(
                (err) => { message.error(err.Message) }
            )
        event && event.preventDefault()
    }, 500)

    let handlerSubmit = useThrottle(() => {
        NetworkHelper.getInstance().ResetPassword(smsPhone, password, smscode).then(response => {
            if (response.data.Code === 1000) {
                // console.log(`重置${response}`)
                message.success('重置成功！', 2, () => { handlerType() })
            } else {
                message.error(response.data.Message)
            }
        })
            .catch(
                (err) => { message.error(err.Message) }
            )
    }, 300)

    const onFinish = (values) => {
        // message.info('通过验证')
        handlerSubmit()
    };
    return (
        <div style={{display: modelType === 'resPassword' ? "" : "none"}}>
            <div className="uni-login"></div>
            <div className="login">
                <div className="left"></div>
                <div className="right">
                    <div className="close" onClick={() => {
                        handlerType('')
                    }}><CloseOutlined/></div>
                    <h2>重置密码</h2>
                    <h6>已有帐号？请立即<a tfype="link" onClick={() => {
                        handlerType('login')
                    }}>登录
                    </a></h6>
                    <Form name="control-ref" onFinish={onFinish}>
                        {/*<div className="input">*/}
                        {/*    <Form.Item*/}
                        {/*        name="userName"*/}
                        {/*        rules={[{ required: true, message: '请填写用户名!' }]}*/}
                        {/*    >*/}
                        {/*        <Input*/}
                        {/*            prefix={*/}
                        {/*                <UserOutlined className="site-form-item-icon" />*/}
                        {/*            }*/}
                        {/*            size="middle"*/}
                        {/*            bordered={false}*/}
                        {/*            placeholder="用户名"*/}
                        {/*            autocomplete="off"*/}
                        {/*            value={username}*/}
                        {/*            onChange={(e) => { setUsername(e.target.value) }}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}

                        <div className="input">
                            <Form.Item
                                       name="smsPhone"
                                       rules={[{ required: true, message: '请填写注册手机号!' }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!' }]}
                            >
                                <Input
                                    prefix={
                                        <MobileOutlined />
                                    }
                                    size="middle"
                                    bordered={false}placeholder="注册手机号"
                                    autocomplete="off"
                                    value={smsPhone}
                                    onChange={(e) => { SetSmsPhone(e.target.value) }}
                                />

                            </Form.Item>
                        </div>


                        <div className="input">
                            <Form.Item
                                       name="smscode"
                                       rules={[{ required: true, message: '请填写验证码!' }]}
                            >
                                <Input
                                    maxLength={6}
                                    bordered={false}
                                    suffix={
                                        <div className={css.suffix} style={{paddingRight: '0px'}}>
                                            {countDownSecond > 0 ?
                                                <Button type="link" disabled={true}>重新获取 ({countDownSecond})</Button> :
                                                <Button type="link" onClick={handlerCode}>获取验证码</Button>}
                                        </div>
                                    }
                                    prefix={
                                        <RobotOutlined />
                                    }
                                    size="middle"
                                    placeholder="验证码"
                                    autocomplete="off"
                                    onChange={(e) => { SetSmscode(e.target.value) }}
                                />
                            </Form.Item>
                        </div>


                        <div className="input">

                            <Form.Item
                                       name="password"
                                       rules={[{ required: true, message: '请填写新密码!' }]}
                            >
                                <Input.Password
                                    bordered={false}
                                    prefix={
                                        <LockOutlined />
                                    }
                                    size="middle"
                                    placeholder="新密码"
                                    autocomplete="off"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </Form.Item>

                        </div>


                        <div className="input">

                            <Form.Item
                                       name="confirmPassword"
                                       rules={[
                                           { required: true, message: '请填写确认密码!' },
                                           ({ getFieldValue }) => ({
                                               validator(_, value) {
                                                   if (!value || getFieldValue('password') === value) {
                                                       return Promise.resolve();
                                                   }
                                                   return Promise.reject(new Error('确认密码与密码设置不一致!'));
                                               },
                                           }),
                                       ]}
                            >
                                <Input.Password
                                    bordered={false}
                                    prefix={
                                        <LockOutlined />
                                    }
                                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    size="middle"
                                    placeholder="确认密码"
                                    autocomplete="off"
                                />
                            </Form.Item>

                        </div>



                        <div className="bt" style={{width: '100%'}}>
                            <Button size="large" htmlType="submit" type="primary" style={{ background: '#7157FF', width: '100%', color: '#eeeeee', fontWeight: '700' ,border:'none'}}>
                                <div style={{ width: '100%', color: '#ffffff', fontWeight: '700' }}>
                                    确认重置
                                </div>
                            </Button>
                        </div>
                    </Form>



                </div>
            </div>
        </div>
    )
}


const ModalResetPasswordxx = ({modelType, handlerType}) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [smsPhone, SetSmsPhone] = useState('')
    const [smscode, SetSmscode] = useState('')
    const [countDownSecond, setCountDownSecond] = useState(0)
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleCountDown = (seconds = 60, time = 1000) => {
        let second = seconds;
        let timer = (countDown) => setTimeout(countDown, time);
        const countDown = () => {
            if (second > 0) {
                second--;
                setCountDownSecond(second);
                console.log('time', second);
            }
            if (second === 0) {
                setCountDownSecond(second);
                return
            }
            timer(countDown)
        };
        countDown()
    };


    let handlerCode = useThrottle((event) => {
        // if (!username) {
        //   message.error("请填写用户名", 2)
        //   return false;
        // }

        if (smsPhone.trim() === "") {
            message.info("请填写手机号码", 2)
            return false;
        }

        if (!(/^1[3456789]\d{9}$/.test(smsPhone))) {
            console.log("手机号码有误，请重填", smsPhone)
            message.info("手机号码有误，请重填", 2)
            return false;
        }
        // 获取验证码倒计时
        NetworkHelper.getInstance().SendSmsV3(smsPhone, username, 'ResetPassword').then((res) => {
            if (res && res.data.Code === 1000) {
                handleCountDown(60)
                message.success("短信发送成功！", 2)
            } else {
                message.error(res.data.Message, 2)
            }
        })
            .catch(
                (err) => { message.error(err.Message) }
            )
        event && event.preventDefault()
    }, 500)

    let handlerSubmit = useThrottle(() => {
        NetworkHelper.getInstance().ResetPassword(username, password, smscode).then(response => {
            if (response.data.Code === 1000) {
                // console.log(`重置${response}`)
                message.success('重置成功！', 2, () => { handlerType() })
            } else {
                message.error(response.data.Message)
            }
        })
            .catch(
                (err) => { message.error(err.Message) }
            )
    }, 300)

    const onFinish = (values) => {
        // message.info('通过验证')
        handlerSubmit()
    };
    return (
        <div >
            <div className={css.formTitle}>重置密码</div>
            <Form name="control-ref" onFinish={onFinish}>
                <div className={css.KM}>欢迎使用魔方IP助手</div>

                <Form.Item className="mb3"
                    name="userName"
                    rules={[{ required: true, message: '请填写用户名!' }]}
                >
                    <Input
                        prefix={
                            <div className={css.userIconDiv}>
                                <img alt="img" className={css.userIcon} src={`${images}loginicon_1.png`}></img>
                            </div>
                        }
                        size="middle"
                        style={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="用户名"
                        autocomplete="off"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                    />
                </Form.Item>

                <Form.Item className="mb3"
                    name="smsPhone"
                    rules={[{ required: true, message: '请填写手机号!' }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!' }]}
                >
                    <Input
                        prefix={
                            <div className={css.userIconDiv}>
                                <img alt="img" className={css.userIcon} src={`${images}loginicon_2.png`} />
                            </div>
                        }
                        size="middle"
                        style={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="手机号"
                        autocomplete="off"
                        value={smsPhone}
                        onChange={(e) => { SetSmsPhone(e.target.value) }}
                    />

                </Form.Item>
                <Form.Item className="mb3"
                    name="smscode"
                    rules={[{ required: true, message: '请填写验证码!' }]}
                >
                    <Input
                        maxLength={6}
                        suffix={
                            <div className={css.suffix} style={{ paddingRight: '0px' }}>
                                {countDownSecond > 0 ? <Button type="primary" disabled={true}>重新获取 ({countDownSecond})</Button> : <Button type="primary" onClick={handlerCode}>获取验证码</Button>}
                            </div>
                        }
                        prefix={
                            <div className={css.userIconDiv}>
                                <img alt="img" className={css.userIcon} src={`${images}loginicon_5.png`} />
                            </div>
                        }
                        size="middle"
                        style={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="验证码"
                        autocomplete="off"
                        onChange={(e) => { SetSmscode(e.target.value) }}
                    />
                </Form.Item>

                <Form.Item className="mb3"
                    name="password"
                    rules={[{ required: true, message: '请填写新密码!' }]}
                >
                    <Input.Password
                        prefix={
                            <div className={css.userIconDiv}>
                                <img alt="img" className={css.userIcon} src={`${images}loginicon_3.png`}></img>
                            </div>
                        }
                        size="middle"
                        style={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="新密码"
                        autocomplete="off"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                </Form.Item>
                <Form.Item className="mb3"
                    name="confirmPassword"
                    rules={[
                        { required: true, message: '请填写确认密码!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('确认密码与密码设置不一致!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={
                            <div className={css.userIconDiv}>
                                <img alt="img" className={css.userIcon} src={`${images}loginicon_3.png`}></img>
                            </div>
                        }
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                        size="middle"
                        style={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="确认密码"
                        autocomplete="off"
                    />
                </Form.Item>
                <div className="cake" style={{ marginBottom: '12px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='link' onClick={() => { handlerType() }}>已有账号？立即登录</Button>
                </div>

                <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button size="large" htmlType="submit" type="primary" style={{ border: '1px solid #ffd031', background: '#ffd031', borderRadius: '30px', width: '100%', color: '#000', fontWeight: '700' }}>
                        <div style={{ fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold', width: '100%', color: '#000', fontWeight: '700' }}>重置密码</div>
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default ModalResetPassword

