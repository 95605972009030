import {Button, Row, Col, Modal, Space} from "antd"
import {Link} from "react-router-dom"
import {images, downloadUrl, TABS} from "../Constant.js"
import {useEffect, useState} from "react"
import ModalLogin from "./ModalLogin"
import ModalResgister from "./ModalResgister"
import ModalResetPassword from "./ModalResetPassword"
import {useNavigate} from "react-router"
// import path from "path"
import css from "../css/PageHeaderSecond.module.css"

var HeaderType = {
    HomePage: 0,
    AboutPage: 1,
    RegisterPage: 2,
    LoginPage: 3
}

const PageHeaderSecond = ({hideIndex}) => {
    let navigate = useNavigate()
    // 弹窗
    const [modelType, setIsModelType] = useState('');//登录，注册，重置密码

    const handlerType = (e) => {
        setIsModelType(e)
    }

    return (
        <Row style={{
            height: "74px", padding: 0, margin: 0, position: 'relative', alignItems: "center", display: "flex",
            minWidth:1111,
        }}>
            <Col span={12}>
                <img src={`${images}logo.png`} style={{paddingLeft: 120}}/>
            </Col>
            <Col span={8}>
                <Space size={22}>
                    <Link to="/" className={css.linkStyle}>首页</Link>
                    <Link to="/about" className={css.linkStyle}>关于我们</Link>
                    <Link to="/help" className={css.linkStyle}>帮助中心</Link>
                    <a onClick={e=>{
                        if (sessionStorage.getItem("mofang-username")) {
                            navigate("/mgr/node-purchase")

                        } else setIsModelType('login')


                    }} zhref="/mgr/node-purchase" className={css.linkStyle}>购买节点</a>
                </Space>
            </Col>
            <Col span={4} style={{textAlign: "right", paddingRight: 30}}>
                <Space>
                    <Button type="link" onClick={handlerType.bind(this, 'login')}
                            style={{color: '#7157FF'}}>登录</Button>
                    <Button onClick={handlerType.bind(this, 'register')}
                        type="text"
                        style={{background: '#7157FF', borderRadius: 40, width: 100}}>
                        <div
                            style={{
                                fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold',
                                width: '100%', color: '#eeeeee',
                            }}
                            >
                            立即注册
                        </div>
                    </Button>
                </Space>
            </Col>

            <ModalLogin modelType={modelType} handlerType={(val) => {
                setIsModelType(val)
            }}/>

            <ModalResgister modelType={modelType} handlerType={(val) => {
                setIsModelType(val)
            }}/>

            <ModalResetPassword modelType={modelType} handlerType={(val) => {
                setIsModelType(val)
            }}/>

        </Row>
    )
}

export {HeaderType}
export default PageHeaderSecond