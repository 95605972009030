import {Button, Empty, Modal, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {NetworkHelper} from "com.guntherlau.services.mofang"
import ModalChangePassword from './ModalChangePassword'
import ModalChangePhone from './ModalChangePhone'
import {useNavigate} from 'react-router-dom';
import "../../css/center.css"
import {images, TABS} from '../../Constant';
import {
    CloudFilled,
    CompassFilled, EnvironmentFilled,
    MessageFilled,
    MessageOutlined,
    QqCircleFilled,
    QqOutlined,
    SignalFilled, ThunderboltFilled
} from "@ant-design/icons";

const PageCenter = () => {
    const navigate = useNavigate()
    const navigator = useNavigate()
    //  余额，金币
    const [balance, setBalance] = useState(sessionStorage.getItem("mofang-Balance"))
    //  等级
    const [memberLevel, setMemberLevel] = useState(sessionStorage.getItem("mofang-MemberLevel"))
    //  已购节点总数
    const [nodeLength, setNodeLength] = useState(sessionStorage.getItem("mofang-NodeQty"))
    //  当前登录用户，用户名
    const [nickName, setNickName] = useState(sessionStorage.getItem("mofang-username"))
    //  在线设备
    const [OnlineDeviceQty, setOnlineDeviceQty] = useState(sessionStorage.getItem("mofang-OnlineDeviceQty"))
    //  暂停节点
    const [PauseNodeQty, setPauseNodeQty] = useState(sessionStorage.getItem("mofang-PauseNodeQty"))
    //  7天内到期节点数
    const [ExpireQty, setExpireQty] = useState(sessionStorage.getItem("mofang-ExpireQty"))
    //  累计充值金额
    const [TotalRechargeAmount, setTotalRechargeAmount] = useState(sessionStorage.getItem("mofang-TotalRechargeAmount"))
    //  登录IP
    const [Ipaddress, setIpaddress] = useState(sessionStorage.getItem("mofang-Ipaddress"))


    //  当前手机号码
    const [phoneNumber, setPhoneNumber] = useState(sessionStorage.getItem("mofang-PhoneNumber"))
    const [announcementList, setannouncementListList] = useState([])
    const [modalResetPassword, setModalResetPassword] = useState(false)
    const [modalChangePhone, setModalChangePhone] = useState(false)
    const [textId, setTextId] = useState(-1)
    const [noticeScrollY, setNoticeScrollY] = useState(0)


    const DemoBox = props => <div className={`height-${props.value}`}>{props.children}</div>
    const style = {background: '#ffbbff', with: "100%", height: '100%'};

    //  当前头像

    const {Title} = Typography
    const [ellipsis, setEllipsis] = React.useState(true)


    const handerlList = (e) => {
        setTextId(e.target.id)
    }
    useEffect(() => {

        NetworkHelper.getInstance().GetAnnouncement().then(({data}) => {
            if (data.Code === 1000 && data.AnnouncementList) {
                setannouncementListList((data.AnnouncementList).reverse())
            }
        }).catch(error => {
            console.log('navigator("/")')
        })

        NetworkHelper.getInstance().GetUserInfo().then(response => {
            if (response.data.Code === 1000) {
                //  金币
                sessionStorage.setItem("mofang-Balance", response.data.Balance)
                setBalance(response.data.Balance)
                //  电话
                sessionStorage.setItem("mofang-PhoneNumber", response.data.PhoneNumber)
                setPhoneNumber(response.data.PhoneNumber)
                //  节点数量
                sessionStorage.setItem("mofang-NodeQty", response.data.NodeQty)
                setNodeLength(response.data.NodeQty)
                //  在线设备数量
                sessionStorage.setItem("mofang-OnlineDeviceQty", response.data.OnlineDeviceQty)
                setOnlineDeviceQty(response.data.OnlineDeviceQty)
                //  暂停节点数量
                sessionStorage.setItem("mofang-PauseNodeQty", response.data.PauseNodeQty)
                setPauseNodeQty(response.data.PauseNodeQty)
                //  7天内到期节点数量
                sessionStorage.setItem("mofang-ExpireQty", response.data.ExpireQty)
                setExpireQty(response.data.ExpireQty)
                //  登记
                sessionStorage.setItem("mofang-MemberLevel", response.data.MemberLevel)
                setMemberLevel(response.data.MemberLevel)
                //  累计充值金额
                sessionStorage.setItem("mofang-TotalRechargeAmount", response.data.TotalRechargeAmount)
                setTotalRechargeAmount(response.data.TotalRechargeAmount)
                //  登录IP
                sessionStorage.setItem("mofang-Ipaddress", response.data.Ipaddress)
                setIpaddress(response.data.Ipaddress)
            }
        })
    }, [])

    const constAnnouncementList = []
    announcementList.map(val => {
        if (constAnnouncementList.length < 8)
            constAnnouncementList.push(val)
    })


    return (
        <div className="mycenter">
            <main>
                <div className='left'>
                    <div className="card">
                        <section className="info">
                            <div className="l">
                                <div className="avr">
                                    <img style={{width: '100%'}} src={`${images}icon_touxiang2.png`} alt=""/></div>
                                <div className="user">
                                    <span className="uname"> {`${nickName}`}</span>
                                    <footer className="userlv">
                                        <span className="ulv" style={{color: '#A7B3C7'}}>会员等级 LV{memberLevel}</span>
                                        {/*<img src={`${images}tab_lv${memberLevel}.png`} alt="e"/>*/}
                                    </footer>

                                </div>

                            </div>
                            <div className="q1">
                                {/*<div >*/}

                                {/*    <MessageFilled />*/}
                                {/*</div>*/}
                                {/*<ThunderboltFilled />*/}
                                <span className="q2"><EnvironmentFilled style={{
                                    color: 'rgba(19,222,120,0.65)',
                                    marginRight: 20,
                                    marginLeft: 6,
                                    fontSize: 16
                                }}/>当前IP 暴风城</span>
                                <span className="q3"><ThunderboltFilled
                                    style={{color: 'rgb(113,87,255)', marginRight: 10}}/> {`${Ipaddress}`}</span>

                            </div>
                        </section>

                    </div>
                    <div className="card">
                        <div className="card-tit">
                            <img src={`${images}icon_zichan.png`} alt="e"/> <span
                            className="title">账户资产</span>
                            <button className="bt" onClick={() => navigate(TABS.PAYMENT)}
                            >充值
                            </button>
                        </div>

                        <div className="art-box">
                            <article className="art">
                                <span className="art-tit">金豆余额</span>
                                <span className="art-val">{`${balance}`}</span>
                            </article>
                            <article className="art">
                                <span className="art-tit">累计充值</span>
                                <span className="art-val">{`${TotalRechargeAmount}`}</span>
                            </article>
                        </div>

                    </div>


                    <div className="card">
                        <div className="card-tit">
                            <img src={`${images}icon_jiedian.png`} alt="e"/> <span
                            className="title">节点管理</span>
                            <button className="bt" onClick={() => navigate(TABS.NODEPUR)}>
                                购买/续费
                            </button>
                        </div>
                        <div className="art-box flex-bt">
                            <article className="art">
                                <span className="art-tit">节点数量</span>
                                <span className="art-val blue">{`${nodeLength}`}</span>
                            </article>
                            <article className="art" onClick={() => navigate(TABS.DEVICES)}>
                                <span className="art-tit">在线设备</span>
                                <span className="art-val blue">{`${OnlineDeviceQty}`}</span>
                            </article>
                            <article className="art">
                                <span className="art-tit">暂停节点</span>
                                <span className="art-val blue">{`${PauseNodeQty}`}</span>
                            </article>
                            <article className="art">
                                <span className="art-tit">7天内到期</span>
                                <span className="art-val blue">{`${ExpireQty}`}</span>
                            </article>
                        </div>
                    </div>


                </div>
                <div className='r'>
                    <div className="card" style={{height: 159}}>
                        <article className="userlv mb40">

                            <span className="fw700 marat" mgr="at"><MessageFilled
                                style={{color: '#7157ff', marginRight: 10}}/>客服在线时间：9:00 - 24:00</span>

                            <button className="bt" onClick={() => 0}>
                                投诉留言
                            </button>

                        </article>
                        <article className="userlv mb40">
                            <span className="fw700" style={{marginRight: "auto"}}><QqOutlined
                                style={{color: '#7157ff', marginRight: 10}}/>QQ客服：2852520923</span>
                        </article>

                    </div>


                    <div className="card">
                        <div className="card-tit nbb">
                            <img src={`${images}icon_shezhi.png`} alt="e"/> <span
                            className="title">账户设置</span>
                        </div>

                        <article className="userlv mb40">
                            <span className="fw700">手机号码：</span>
                            <span className="flex1 mrat cogray">{`${phoneNumber}`}</span>
                            <Button className='bt2'
                                    type="text"
                                    onClick={() => setModalChangePhone(true)}
                            >
                                修改手机号
                            </Button>

                        </article>
                        <article className="userlv mb40">
                            <span className="fw700">账户名称：</span>
                            <span className="flex1 mrat cogray">{`${nickName}`}</span>
                            <Button className='bt2'
                                    type="text"
                                    onClick={() => setModalResetPassword(true)}
                            >
                                修改密码
                            </Button>

                        </article>
                        <article className="userlv mb40">
                            <span className="fw700">实名认证：</span>
                            <span className="flex1 mrat cogray">尚未认证</span>
                            <Button className='bt2'
                                    type="text"
                                    disabled={true}

                            >
                                实名认证
                            </Button>

                        </article>


                    </div>

                    <div className="card" style={{height: 384}}>
                        <div className="card-tit nbb">
                            <img src={`${images}icon_gonggao.png`} alt="e"/> <span
                            className="title">系统公告</span>
                        </div>

                        <div style={{height: 333, overflow: "auto"}}>
                            {
                                constAnnouncementList.length <= 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    constAnnouncementList.map(n => (

                                        <a className="userlv notice">
                                            <span className="flex1 mrat">{n.Title}</span>
                                            <span className="flex1 mrat">{n.ReleaseTime}</span>
                                        </a>
                                    ))
                            }
                            {/*{*/}
                            {/*    (x=>{*/}
                            {/*        let s = []*/}
                            {/*        for (let i = 0; i < 33; i++) {*/}
                            {/*            s.push(i)*/}
                            {/*        }*/}
                            {/*        return s*/}
                            {/*    })().map(e=>*/}
                            {/*        <a className="userlv notice">*/}
                            {/*            <span className="flex1 mrat">科盟用户后台 1.0.02维护更新完成，请前往查看~</span>*/}
                            {/*            <span className="flex1 mrat">2022-12-01</span>*/}
                            {/*        </a>*/}
                            {/*    )*/}
                            {/*}*/}
                        </div>


                    </div>

                </div>


            </main>
            {/*<Modal maskClosable={false} visible={modalResetPassword} onCancel={() => setModalResetPassword(false)} transitionName="" maskTransitionName="" footer={''} width="400px">*/}
            {/*    <ModalChangePassword handlerType={() => { setModalResetPassword(false) }} />*/}
            {/*</Modal>*/}
            <Modal
                visible={modalResetPassword}
                transitionName=""
                maskTransitionName=""
                width={366}
                title={"修改密码"}
                centered
                onCancel={() => setModalResetPassword(false)}
                footer={false}
            >
                <ModalChangePassword handlerType={() => {
                    setModalResetPassword(false)
                }}/>

            </Modal>

            <Modal
                transitionName=""
                maskTransitionName=""
                width={366}
                title={"修改手机号"}
                centered
                footer={null}
                visible={modalChangePhone} onCancel={() => setModalChangePhone(false)}
            >
                <ModalChangePhone handlerType={() => {
                    setPhoneNumber(sessionStorage.getItem("mofang-PhoneNumber"))
                    setModalChangePhone(false)
                }}/>
            </Modal>

            {/*<Modal visible={modalChangePhone} onCancel={() => setModalChangePhone(false)} transitionName="" maskTransitionName="" footer={''} width="400px">*/}
            {/*<ModalChangePhone handlerType={() => {*/}
            {/*    setPhoneNumber(sessionStorage.getItem("mofang-PhoneNumber"))*/}
            {/*    setModalChangePhone(false)*/}
            {/*}} />*/}
            {/*</Modal>*/}

        </div>
    )
}

export default PageCenter
