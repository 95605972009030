// import './App.less';
import { Route, Routes } from "react-router-dom"
import HomePage from './components/Home/HomePage';
import AboutPage from './components/About/AboutPage';
import ManagerPage from './components/Manager/ManagerPage';
import ArticlePage from './components/Article/ArticlePage';
import { NetworkHelper } from 'com.guntherlau.services.mofang';
import { host } from './Constant';
import HelpPage from "./components/Help/Help";



function App() {

    NetworkHelper.getInstance().init(host)
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="help"  element={<HelpPage/>} />
            <Route path="mgr"  >
                <Route path=":tab" element={<ManagerPage/>} />
            </Route>
            <Route path="article" element={<ArticlePage/>} />
        </Routes>
    )
}

export default App;
