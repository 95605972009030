import { Col, Row } from "antd"
import { useState } from "react"
import { images } from "../../Constant"
import '../../css/HomeInfo.css'

const Item = ({ index, id, onChanged, listData }) => {
    const isClick = index === id
    return (
        <div className='item-container fl-c mouse-item1' 
            style={{ width: 288, height: 400 }}
            onMouseOut={() => { onChanged && onChanged(-1) }} 
            onMouseOver={() => { onChanged && onChanged(id) }}>
            <div>
                <img alt="infoItemImage" src={isClick ? listData.image : listData.imageNot} className="info-item-image" />
            </div>
            <div className="info-item-title" style={{ fontSize:24,fontWeight:600, }}>
                {listData.title}
            </div>
            {/*<div className="info-item-value" style={{ color: isClick ? '' : '#b2b2b2' }}>*/}
            <div className="info-item-value">
                {listData.value}
            </div>
        </div>
    )
}

const PageWhy = () => {
    let [index, setIndex] = useState(-1)
    let handlerIndexChange = (newValue) => {
        setIndex(newValue)
    }

    const data = [
        {
            title: '地区广泛',
            value: '覆盖全国多个地区，云服务器运维，海量节点资源、线路灵活选择。',
            image: `${images}icon_04.png`,
            imageNot: `${images}icon_04.png`,
        },
        {
            title: '操作便携',
            value: '系统操作简易，教程指引轻松上手，可自由切换节点、项目和地区。',
            image: `${images}icon_05.png`,
            imageNot: `${images}icon_05.png`,
        },
        {
            title: '品质保障',
            value: '以技术起家，多年动态拨号服务器运营经验，自有资源搭建技术好，价格低，性价比高。',
            image: `${images}icon_06.png`,
            imageNot: `${images}icon_06.png`,
        }
    ];

    return (
        <Row style={{ paddingTop: 222, paddingBottom: 54 }}>
            <Col span={24} style={{ textAlign: "center" }}>
                <img src={`${images}icon_03.png`} style={{ paddingBottom: 20 }} /><br/>
                <div style={{ width:'100%', border:'1px soil black',display:"flex",justifyContent:"center"}}>
                    {data.map((item, key) =>
                        <Item key={key} listData={item} index={index} id={key} onChanged={handlerIndexChange} />
                    )}
                    {/*<div style={{width:222,height:222,background:"red"}}></div>*/}
                    {/*<div style={{width:222,height:222,background:"red"}}></div>*/}
                </div>
            </Col>
        </Row>
    )
}

export default PageWhy