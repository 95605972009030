import {Layout, Col, Image, Button, message, Modal, List, Carousel, Row, Space, Slider, Input} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {NetworkHelper} from "com.guntherlau.services.mofang";
import QRCode from 'qrcode.react';
import {useNavigate} from "react-router"
import {formatDate} from "../../utils/helper";
import useThrottle from "../../hooks/useThrottle";
import {images} from "../../Constant";
import '../../css/pay.css'
import ModalChangePassword from "./ModalChangePassword";
import {AlipayCircleOutlined, AlipayOutlined, ClockCircleOutlined} from "@ant-design/icons";

const {Content, Sider} = Layout;


const PaymentLevel = ({val, idx}) => {

    return (
        <div className="im">
            <div className="tj"><img src={`${images}icon_tab.png`} alt="e" hidden={idx < 2}/></div>
            <div className="val"><span> {`¥${val.StartPrice} - ¥${val.EndPrice}元`}</span></div>
            <div className="dou"><span>{`${(val.StartPrice * (1 - val.Discount)).toFixed(2)} ~ ${(val.EndPrice * (1 - val.Discount)).toFixed(2)}`}金豆</span></div>
            <div className="zhe"><span>{val.Discount === 1 ? '不打折' : ` ≈  ${(val.Discount * 10).toString().substring(0, 3)}折`}</span></div>
        </div>
    )
}

const PagePaymentSeventh = () => {

    const navigator = useNavigate()
    const [userBalance, setUserBalance] = useState(sessionStorage.getItem("mofang-Balance"))
    const [orderNumber, setOrderNumber] = useState(null)
    const [paymentNum, setPaymentNum] = useState(0)
    const [paymentListData, setPaymentListData] = useState([])
    //  支付类型，alipay/wxpay
    const [paymentType, setPaymentType] = useState('alipay')
    //  支付宝支付窗口
    const [modalAlipayPaymentShow, setModalAlipayPaymentShow] = useState(false)
    //  微信支付窗口
    const [modalWeixinPaymentShow, setModalWeixinPaymentShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState()
    const [wxqrcode, setWxqrcode] = useState("")
    // 倒计时
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(60)
    const [orderDate, setOrderDate] = useState(0)
    const timer = useRef(null)

    const font = {
        color: " rgba(0,0,0,0.85)",
        fontWeight: "700",
        fontFamily: "Microsoft YaHei, Microsoft YaHei-Bold",
        fontSize: '16px'
    }
    const btnFont = {
        height: '46px',
        borderRadius: '30px',
        width: '200px',
        marginTop: '1.6rem',
        fontFamily: "Microsoft YaHei, Microsoft YaHei-Bold",
        color: '#000'
    }

    const handlerInputBalanceChange = (event) => {
        if (event.target.value === "") {
            setPaymentNum(0)
            return
        }
        if (!isNaN(event.target.value)) {
            setPaymentNum(parseInt(event.target.value))
        }
    }
    
    const hasAlipayPaid = () => {
        NetworkHelper.getInstance().GetPayState(orderNumber).then(res => {
            if (res.data.Code === 1000) {
                sessionStorage.setItem("mofang-Balance", res.data.Balance)
                setUserBalance(res.data.Balance)
                console.log('支付成功', res)
                message.success('支付成功')
                clearInterval(timer.current)
                setOrderNumber(null)
                setModalAlipayPaymentShow(false)
                setModalWeixinPaymentShow(false)
            } else {
                message.error(res.data.Message, 2)
            }
        })
    }

    const startTimer = () => {
        console.log('PagePaymentSeventh startTime')
        setMinutes(4)
        setSeconds(60)
        timer.current = setInterval(() => {
            setSeconds(c => c - 1)
        }, 1000)
    }

    const loadGetPayState = useThrottle(function () {
        NetworkHelper.getInstance().GetPayState(orderNumber).then(res => {
            if (res.data.Code === 1000) {
                sessionStorage.setItem("mofang-Balance", res.data.Balance)
                setUserBalance(res.data.Balance)
                console.log('支付成功', res)
                message.success('支付成功')
                clearInterval(timer.current)
                setOrderNumber(null)
                setModalAlipayPaymentShow(false)
                setModalWeixinPaymentShow(false)
            } else {
                // message.error(res.data.Message, 2)
            }
        })
    }, 1000)
    useEffect(() => {
        // console.log('倒计时===>',`${minutes}分${seconds}秒`);
        if (orderNumber) {
            loadGetPayState()
        }
        if (seconds <= 0 && minutes <= 0) {
            clearInterval(timer.current)
            return
        } else if (seconds <= 0) {
            setMinutes(e => e - 1)
            setSeconds(59)
        }
    }, [seconds, minutes])

    const handlerPayment = () => {

        // console.log(paymentNum);
        if (!paymentNum && (paymentNum <= 0 || typeof (paymentNum) === 'object')) {
            message.error('请填写充值金额！！', 2)
            return
        }
        setOrderDate(formatDate(new Date()))

        if (paymentType === 'alipay') {
            NetworkHelper.getInstance().alipay(paymentNum).then(response => {
                if(response.data.Code === 1000) {
                    console.log('支付宝充值返回', response.data)
                    setOrderNumber(response.data.OrderNumber)
                    let decode = atob(response.data.Parameter)
                    // console.log('解码后', decode)
                    startTimer()
                    setPaymentUrl(decode)
                    setModalAlipayPaymentShow(true)
                } else {
                    message.error(response.data.Message, 2)
                }
            }).catch(() => console.log('navigator("/")'))
        } else {
            NetworkHelper.getInstance().wxpay(paymentNum).then(response => {
                if(response.data.Code === 1000) {
                    console.log('微信充值接口返回', response.data)
                    setOrderNumber(response.data.OrderNumber)
                    let decode = atob(response.data.Parameter)
                    let decodeObj = JSON.parse(decode)
                    // console.log('解码后', decodeObj)
                    startTimer()
                    setWxqrcode(decodeObj.code_url)
                    setModalWeixinPaymentShow(true)
                } else {
                    message.error(response.data.Message, 2)
                }
            }).catch(() => console.log('navigator("/")'))
        }
    }

    const right = [
        <Row>
            <Col style={{...font, ...{width: '100px'}}}>充值金额：</Col>
        </Row>,
        <Row style={{marginTop: '10px'}}>
            <input
                type="text"
                maxLength={6}
                // placeholder="输入充值金额"
                style={{

                    background: 'none',
                    textAlign: 'center',
                    borderBottom: '2px solid rgba(0,0,0,0.04)',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderTop: 'none',
                    color: '#000000',
                    fontSize: '1.6rem',
                    width: 500
                }}
                value={paymentNum | 0}
                onChange={handlerInputBalanceChange}/>
        </Row>,
        <Row>
            <Col style={{paddingLeft: 30, alignItems: "center", display: "flex"}}><img src={`${images}huode.png`}/>
                <span style={{width: 80, textAlign: "center"}}>{paymentNum}金币</span>
                <img src={`${images}zengsong.png`}/><span style={{width: 80, textAlign: "center"}}>
                    {
                        paymentListData.find(val => paymentNum >= val.StartPrice && paymentNum <= val.EndPrice) ?
                            paymentListData.map((val, idx) => {
                                if (paymentNum >= val.StartPrice && paymentNum <= val.EndPrice) {
                                    return `${(paymentNum * (1 - val.Discount)).toFixed(2)}`
                                }
                            }) : 0}金币</span></Col>
        </Row>,

        <Row style={{marginTop: '10px'}}>
            <Col style={font}>充值方式：</Col>
        </Row>,
        <Row style={{marginTop: 20}}>
            <Col>
                <Button
                    style={{
                        lineHeight: '37.6px',
                        height: '50px',
                        width: '200px',
                        border: paymentType === "alipay" ? "1px solid #0F7AFF" : "1px solid #C0C0C0",
                        color: paymentType === "alipay" ? "#0F7AFF" : "#C0C0C0",
                        borderRadius: "30px"
                    }}
                    onClick={() => setPaymentType('alipay')}
                >
                    <div style={{display: 'flex', paddingLeft: '10px',}}>
                        <div style={{width: 30, height: 30, verticalAlign: 'middle'}}>
                            <Image src={`${images}zficon_1.png`} preview={false}/>
                        </div>
                        <div style={{
                            marginLeft: '8px',
                            fontSize: '16px',
                            height: 30,
                            verticalAlign: 'middle',
                            lineHeight: '30px',
                        }}>支付宝支付
                        </div>
                    </div>
                </Button>
            </Col>
            {/* <Col style={{ paddingLeft: 40 }}>
                <Button
                    style={{
                        lineHeight: '37.6px',
                        height: '50px',
                        width: '200px',
                        color: paymentType === "wxpay" ? "#0F7AFF" : "#C0C0C0",
                        border: paymentType === "wxpay" ? "1px solid #0F7AFF" : "1px solid #C0C0C0",
                        borderRadius: "30px"
                    }}
                    onClick={() => setPaymentType('wxpay')}
                >
                    <div style={{ display: 'flex', paddingLeft: '8px' }}>
                        <div style={{ width: 30, height: 30, verticalAlign: 'middle' }}>
                            <Image src={`${images}zficon_2.png`} preview={false} />
                        </div>
                        <div style={{ marginLeft: '8px', fontSize: '16px', height: 30, verticalAlign: 'middle', lineHeight: '30px', }}>微信支付</div>
                    </div>
                </Button>
            </Col> */}
        </Row>
        ,
        <Button
            type="primary"
            size="large"
            style={{
                fontFamily: "Microsoft YaHei, Microsoft YaHei-Regular",
                fontWeight: 700, color: '#000',
                borderRadius: '30px', width: '450px',
                border: '1px solid #ffd031', background: '#ffd031',
                marginTop: 20
            }}
            onClick={handlerPayment}>
            确认充值
        </Button>
    ]

    const wxModelCancel = () => {
        clearInterval(timer.current)
        setOrderNumber(null)
        setModalAlipayPaymentShow(false)

        //wyzf
        hasAlipayPaid()



    }
    const aliwxModelCancel = () => {
        clearInterval(timer.current)
        setOrderNumber(null)
        setModalWeixinPaymentShow(false)
    }

    const handleHeight = () => {
        if (document.documentElement.clientWidth >= 1440)
            setPLeft((document.documentElement.clientWidth - 1178 - 200) / 2)
    }

    const [pLeft, setPLeft] = useState((document.documentElement.clientWidth - 1178 - 200) / 2)

    useEffect(() => {
        NetworkHelper.getInstance().GetDiscount().then(response => {
            if (response.data.Code === 1000) {
                setPaymentListData(response.data.DiscountList)
            } else {
                message.error(response.data.Message, 2)
            }
        }).catch(() => console.log('navigator("/")'))

        window.addEventListener('resize', handleHeight)
        return () => window.removeEventListener('resize', handleHeight)
    }, [])

    return (
        <div className="payment">
            <main>
                <div className='left'>
                    <header>
                        <img style={{width: '100%'}} src={`${images}banner_1.png`}/>

                    </header>
                    <div className="tbody">
                        <div><img className='mgr11' src={`${images}icon_充值.png`} alt="e"/></div>
                        <span className='title'>充值活动</span></div>
                    <div className="act">
                        {
                            paymentListData.map((val, idx) => (
                                <PaymentLevel val={val} idx={idx}/>
                            ))
                        }
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                        <div style={{width:274}}></div>
                    </div>

                </div>
                <div className='r'>

                    <div className="account">
                        <div className='row-center'>
                            <img className='mgr11' src={`${images}icon_zichan.png`} alt="e"/> <span
                            className="title">账户余额</span>
                        </div>
                        <span className="dt">账户金豆：</span>
                        <span className="dv">{userBalance}</span>

                    </div>

                    <div className="pay">
                        <div className='row-center'>
                            <img className='mgr11' src={`${images}icon_chongz.png`} alt="e"/>
                            <span className="title">充值支付</span>
                        </div>
                        <span className="dt">充值金额：</span>
                        <div className='row-center' style={{width:222,borderBottom: '2px solid rgba(0,0,0,0.04)'}}>
                            <span style={{fontSize:25}}>￥</span>
                            <Input
                                type="text"
                                maxLength={6}
                                bordered={false}
                                // placeholder="输入充值金额"
                                style={{
                                    fontSize: '1.6rem',
                                }}
                                value={paymentNum | 0}
                                onChange={handlerInputBalanceChange} />
                        </div>

                        <Row style={{ paddingTop: 13}}>
                            <Col  style={{ display: "flex" }}>
                                <span style={{color:'#F54D3C'}}>获得金币</span>
                                <span>{paymentNum} </span> <p className='mgr11'></p>
                                <span style={{color:'#F54D3C'}}> 赠送金豆</span>
                    {
                        paymentListData.find(val => paymentNum >= val.StartPrice && paymentNum <= val.EndPrice) ?
                            paymentListData.map((val, idx) => {
                                if (paymentNum >= val.StartPrice && paymentNum <= val.EndPrice) {
                                    return `${(paymentNum * (1 - val.Discount)).toFixed(2)}`
                                }
                            }) : 0}</Col>
                        </Row>

                        <span className="dt">充值方式：</span>

                        <Button className="dt"
                            style={{
                                lineHeight: '37.6px',
                                height: '50px',
                                width: '200px',
                                border: paymentType === "alipay" ? "1px solid #0F7AFF" : "1px solid #C0C0C0",
                                color: paymentType === "alipay" ? "#0F7AFF" : "#C0C0C0",
                                borderRadius: "30px"
                            }}
                            onClick={() => setPaymentType('alipay')}
                        >
                            <div style={{ display: 'flex', paddingLeft: '10px', }}>
                                <div style={{display: 'flex',  'align-items': 'center' }}>
                                    <img src={`${images}icon_zhifubao.png`} />
                                    <div style={{ marginLeft: '8px', fontSize: '16px', height: 30, verticalAlign: 'middle', lineHeight: '30px', }}>支付宝支付</div>
                                </div>
                            </div>
                        </Button>

                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: 222,
                                height: 50,
                                background:' linear-gradient(140deg,#ff7d3b 0%, #f92906 95%)',
                                'border-radius': 25,
                                border:'none',
                                marginTop: 44
                            }}
                            onClick={handlerPayment}>
                            确认充值
                        </Button>








                    </div>

                </div>


            </main>




            <Modal
                key="modalAlipay"
                centered
                visible={modalAlipayPaymentShow}
                onCancel={wxModelCancel}
                title={<span><AlipayCircleOutlined  style={{ marginRight: '8px' ,fontSize: '1rem'}}/>支付宝支付</span>}
                width={'fit-content'}
                footer={null}
            >

                <div style={{width:'100%',overflow:"auto",padding:'10px 0'}}>
                    {/* ,borderBottom:'1px solid rgba(0,0,0,0.12)' */}
                    <div style={{ marginTop: '18px', paddingBottom: '20px', fontFamily: 'Microsoft YaHei, Microsoft YaHei-Regular', fontSize: '1rem', color: 'rgba(0,0,0,0.65)' }}>
                        <ClockCircleOutlined style={{ color: 'var(--ant-primary-color)'  }}/> 请使用支付宝扫描下方二维码完成支付，请在<span style={{ color: '#FC6F01' }}> {minutes} 分 {seconds} 秒 </span> 内支付。
                    </div>
                    <iframe id="ifr1" frameBorder="0" title="支付窗口" name="ifr1" key="alipayFrame" src={paymentUrl} width="650" height="280" scrolling="no">
                    </iframe>

                    <div style={{ display: 'flex', width: '424px', margin: '0 auto' }}>
                        <Button type="primary" size="large" style={{ ...btnFont, ...{ fontWeight: 400, border: '1px solid rgba(0,0,0,0.30)', background: 'none' } }} onClick={wxModelCancel}>取消支付</Button>
                        <Button type="primary" size="large" style={{ ...btnFont, ...{ marginLeft: '24px', fontWeight: 700, border: 'none', background: 'linear-gradient(54deg,#ff7d3b 0%, #f92906 100%)' ,color:'#fff'} }} onClick={wxModelCancel}>我已支付</Button>
                    </div>
                </div>
            </Modal>

        </div>


    )
}

export default PagePaymentSeventh
