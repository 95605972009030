import { Radio } from 'antd'

//  节点管理，点击查看节点详情窗口
const normalDetailColumns = [
    {
        title: '类型',
        dataIndex: 'DetailsType',
        key: 'DetailsType',
        align: 'center',
        width: 160
    },
    {
        title: '节点名称',
        dataIndex: 'Node',
        key: 'Node',
        align: 'center',
        width: 130
    },
    {
        title: '游戏项目',
        dataIndex: 'Game',
        key: 'Game',
        align: 'center',
        width: 170
    },
    {
        title: '地区',
        dataIndex: 'Area',
        key: 'Area',
        align: 'center',
        width: 100
    },
    {
        title: '购买时间',
        dataIndex: 'StartTime',
        key: 'StartTime',
        align: 'center',
        width: 170
    },
    {
        title: '到期时间',
        dataIndex: 'Endtime',
        key: 'Endtime',
        align: 'center',
        width: 170
    },
    {
        title: '价格',
        dataIndex: 'Price',
        key: 'Price',
        align: 'center',
        width: 70,
        render: (_, record) => parseInt(record.Price)
    }
]

//  账号明细，点击查看详情窗口
const pageDetail_NormalColumns = [
    {
        title: '类型',
        dataIndex: 'DetailsType',
        key: 'DetailsType',
        align: 'center',
        width: 160
    },
    {
        title: '节点名称',
        dataIndex: 'Node',
        key: 'Node',
        align: 'center',
        width: 130
    },
    {
        title: '游戏项目',
        dataIndex: 'Game',
        key: 'Game',
        align: 'center',
        width: 170
    },
    {
        title: '购买时间',
        dataIndex: 'StartTime',
        key: 'StartTime',
        align: 'center',
        width: 170
    },
    {
        title: '到期时间',
        dataIndex: 'Endtime',
        key: 'Endtime',
        align: 'center',
        width: 170
    },
    {
        title: '价格',
        dataIndex: 'Price',
        key: 'Price',
        align: 'center',
        width: 70,
        render: (_, record) => parseInt(record.Price)
    }
]

//  节点管理，节点切换，左边表格
const modalNodeChange_leftColumns = [
    {
        title: '节点名称',
        dataIndex: 'Node',
        key: 'Node',
        align: 'center',
        width: 5
    },
    {
        title: '节点地点',
        dataIndex: 'Area',
        key: 'Area',
        align: 'center',
        width: 5
    },
    // {
    //     title: '到期时间',
    //     dataIndex: 'EndTime',
    //     key: 'EndTime',
    //     align: 'center',
    //     width: 5
    // },
    {
        title: '剩余天数',
        dataIndex: 'EndDays',
        key: 'EndDays',
        align: 'center',
        width: 5,
        render: (_, record) => parseInt(record.EndDays)
    }
]

//  节点管理，节点切换，右边表格
const modalNodeChange_rightColumns = [
    {
        title: '选择',
        dataIndex: '',
        key: 's',
        align: 'center',
        width: 3,
        render: (_, record) => {
            return <Radio value={record}></Radio>
        }
    },
    {
        title: '节点地区',
        dataIndex: 'Area',
        key: 'Area',
        align: 'center',
        width: 5
    },
    {
        title: '运营商',
        dataIndex: 'Route',
        key: 'Route',
        align: 'center',
        width: 5
    },
    {
        title: '库存',
        dataIndex: 'Count',
        key: 'Count',
        width: 5
    },
    {
        title: '售价',
        dataIndex: 'Price',
        key: 'Price',
        align: 'center',
        width: 5
    }
]

//  节点管理，续费窗口
const modalNodeRenewColumns = [
    {
        title: '节点名称',
        dataIndex: 'Node',
        key: 'Node',
        align: 'center',
        width: "15%"
    },
    {
        title: '游戏项目',
        dataIndex: 'Game',
        key: 'Game',
        align: 'center',
        width: "20%"
    },
    {
        title: '地区/运营商',
        dataIndex: 'areaAndRoute',
        key: 'areaAndRoute',
        align: 'center',
        render: (_, record) => `${record.Area}/${record.Route}`
    },
    {
        title: '到期时间',
        dataIndex: 'EndTime',
        key: 'EndTime',
        align: 'center',
        width: "15%"
    },
    {
        title: '售价',
        dataIndex: 'Price',
        key: 'Price',
        align: 'center',
        width: "10%",
        render: (_, record) => parseInt(record.Price)
    }
]

const modalNodeRestore_leftColumns = [
    {
        title: '游戏项目',
        dataIndex: 'Game',
        key: 'Game',
        align: 'center',
        width: 150
    },
    {
        title: '节点地点',
        dataIndex: 'Area',
        key: 'Area',
        align: 'center',
        width: 150
    },
    {
        title: '剩余价值',
        dataIndex: 'PauseValues',
        key: 'PauseValues',
        align: 'center',
        render: (_, record) => {
            let { PauseValues } = record
            if(PauseValues) {
                if(PauseValues >= 1) {
                    let PauseValuesStr = PauseValues.toString()
                    return `${PauseValuesStr.substring(0, PauseValuesStr.indexOf(".")+3)}元`
                } else {
                    return `${PauseValues}元`
                }
            }
        }
    }
]

const modalNodeRestore_rightColumns = [
    {
        title: '选择',
        dataIndex: '',
        key: 's',
        align: 'center',
        width: "15%",
        render: (_, record) => {
            return <Radio value={record}></Radio>
        }
    },
    {
        title: '节点地区',
        dataIndex: 'Area',
        key: 'Area',
        align: 'center',
        width: "25%"
    },
    {
        title: '运营商',
        dataIndex: 'Route',
        key: 'Route',
        align: 'center',
        width: "25%"
    },
    {
        title: '库存',
        dataIndex: 'Count',
        key: 'Count',
        align: 'center',
        width: "20%"
    },
    {
        title: '售价',
        dataIndex: 'Price',
        key: 'Price',
        align: 'center',
        width: "15%"
    }
]

export { 
    normalDetailColumns, 
    pageDetail_NormalColumns, 
    modalNodeChange_leftColumns, 
    modalNodeChange_rightColumns, 
    modalNodeRenewColumns,
    modalNodeRestore_leftColumns,
    modalNodeRestore_rightColumns
}