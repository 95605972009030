import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
// Importing the Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
// Importing the Ant Design CSS
// import 'antd/dist/antd.css'
import 'antd/dist/antd.variable.less'

// antdesign 中文
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')



ConfigProvider.config({
    theme: {
        primaryColor: 'rgb(113,87,255)',
    },
});


ReactDOM.render(
    <BrowserRouter>
        <ConfigProvider locale={zh_CN} componentSize={'middle'} csp={{ nonce: 'YourNonceCode' }}>
            <App />
        </ConfigProvider >
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
