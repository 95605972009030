import {Input, Button, Row, Col} from 'antd'
import {useState} from 'react'
// import '../../variables.less';
import {images} from '../../Constant';


const NodeCity = ({cat, setCat, commodity, geame}) => {
    const [count, setCount] = useState(0)
    let changeCat = (newCount) => {
        // setPrice(price = price + newCount*commodity.Price)
        let data = {area: commodity.Area, quantity: newCount, price: newCount * commodity.Price}

        const catNumber = [...cat]
        const i = catNumber.findIndex(n => n.area === commodity.Area)
        i > -1 ? newCount === 0 ? catNumber.splice(i, 1) : catNumber[i] = data : catNumber.push(data)
        // console.log(catNumber);
        setCat(catNumber)
    }
    let sub = () => {
        if (count - 1 < 0) return
        let newCount = count - 1
        changeCat(newCount)
        setCount(newCount)
    }
    let add = () => {
        if (count + 1 > commodity.Count) return
        let newCount = count + 1
        changeCat(newCount)
        setCount(newCount)
    }
    let onCountChange = (event) => {
        if (event.target.value === '') {
            changeCat(0)
            setCount(0)
            return
        }

        let valStr = parseFloat(event.target.value.trim())


        if (valStr > commodity.Count) return

        if (!isNaN(valStr)) {
            changeCat(valStr)
            setCount(valStr)
        }
    }


    const getLoadPicName = (Load) => {
        if (Load === "良好") {
            return "tab_liangh.png"
        } else if (Load === "满载") {
            return "tab_manz.png"
        } else {
            return "tab_weihu.png"
        }
    }

    const getTypePicName = (Type) => {
        if (Type === "尊享专区") {
            return "tab_zhuanx.png"
        } else if (Type === "独享专区") {
            return "tab_dux.png"
        } else {
            return "tab_youzhi.png"
        }
    }

    return (
        <Col style={{paddingTop: 10,paddingBottom:10}}>
            <Row>
                <Col span={12}>
                    <div style={{marginRight: '6px', whiteSpace: "nowrap"}}>
                        {commodity.Area}
                        {/*<img src={`${images}${getTypePicName(commodity.Type)}`}*/}
                        {/*                     style={{paddingLeft: 10}}/>*/}
                    </div>
                </Col>

                <Col span={12} style={{textAlign: "right"}}>
                    <Button style={{lineHeight: '20px', fontWeight: '600',xbackground:'#FF8227',xborder:'none'}} className='ant-button-disabled'
                            type="primary" size="small" disabled={count <= 0} onClick={sub}>-</Button>

                    <Input disabled={commodity.Count === 0} size="small"
                           style={{minWidth: 28, maxWidth: 50, textAlign: 'center', margin: '0 4px'}} value={count}
                           onChange={onCountChange}/>
                    <Button
                        style={{lineHeight: '20px', fontWeight: '600',xbackground:'#FF8227',xborder:'none'}}
                        className='ant-button-disabled' type="primary" size="small" onClick={add}
                        disabled={count >= commodity.Count}>+</Button>
                </Col>

            </Row>
            <div style={{background: '#f1f4fd',borderRadius:4,padding:5,marginTop:8}}>
                <Row style={{paddingTop: 5}}>

                    <Col span={12} style={{textAlign: 'left'}}>
                        {commodity.Route}
                    </Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        {commodity.Price}金币
                    </Col>

                </Row>
                <Row style={{paddingTop: 5,}}>
                    <Col span={12}>
                        {commodity.Load}<img src={`${images}${getLoadPicName(commodity.Load)}`}
                                             style={{paddingLeft: 10}}/>
                    </Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        数量：{commodity.Count}
                    </Col>

                </Row>
            </div>
        </Col>
    )
}

export default NodeCity