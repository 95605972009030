import "../../css/PageHistory.less"
import { useEffect, useState } from 'react'
import { NetworkHelper } from 'com.guntherlau.services.mofang'
import { Table, Layout, message } from 'antd';
import SearchBar from './SearchBar'
import useThrottle from "../../hooks/useThrottle";
// const { Header, Content } = Layout;

const PageHistory = () => {
    const [nodeOrderItemList, setNodeOrderItemList] = useState([])
    const [loading, setLoading] = useState(false)
    //  SearchBar
    const [groupsData, setGroupsData] = useState([])
    const [gameData, setGameData] = useState([])
    const [zoneData, setZoneData] = useState([])
    const [searchData, setSearchData] = useState({ timeQuantum: "", zone: "", game: "", groups: "", searchText: '', keyword: "", searchTime: "" })
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    //  【SearchBar】点击搜索的回调函数
    const handlerSearch = useThrottle((timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime) => {
        setCurrentPage(1)
        setSearchData({ timeQuantum, zone, game, groups, searchText, nodeStatus, searchTime })
    }, 500)

    //  当前页面的节点管理列表分页
    let handlerTableChange = (pagination) => {
        pagination && setPageSize(pagination.pageSize)
        pagination && setCurrentPage(pagination.current)
    }

    // #region 
    const columnsSel = [
        {
            title: '序号',
            render: (Type, Describe, index) => `${index + 1}`,
            width: "2%",
            align: 'center',
            fixed: 'left',
        },
        {
            title: '变更时间',
            dataIndex: 'Time',
            key: 'Time',
            width: "5%",
            align: 'center'
        },
        {
            title: '变更原因',
            dataIndex: 'DetailsType',
            key: 'DetailsType',
            width: "5%",
            align: 'center',
        },
        {
            title: '节点名称',
            dataIndex: 'Node',
            key: 'Node',
            width: "5%",
            align: 'center'
        },
        {
            title: '游戏项目',
            dataIndex: 'Game',
            key: 'Game',
            width: "10%",
            align: 'center'
        },
        {
            title: '地区',
            dataIndex: 'Area',
            key: 'Area',
            width: '5%',
            align: 'center'
        },
        {
            title: '购买时间',
            dataIndex: 'StartTime',
            key: 'StartTime',
            width: '5%',
            align: 'center'
        },
        {
            title: '到期时间',
            dataIndex: 'Endtime',
            key: 'Endtime',
            width: "5%",
            align: 'center'
        },
        {
            title: '售价',
            dataIndex: 'Price',
            key: 'Price',
            width: '2%',
            align: 'center'
        }
    ]
    // #endregion

    useEffect(() => {
        setLoading(true)
        NetworkHelper.getInstance().GetNodeOrderItemData(
            currentPage, 
            pageSize, 
            searchData.groups, 
            searchData.searchTime[0], 
            searchData.searchTime[1], 
            searchData.game,
            searchData.zone
            ).then((res) => {
            setLoading(false)
            console.log('==>', res.data);
            if (res.data.Code === 1000) {
                setNodeOrderItemList(res.data.NodeOrderItemList)
                setTotal(res.data.NodeOrderItemQty)
                // setGroupsData(['切换节点', '暂停节点', '恢复节点', '购买节点', '续费节点'])
                setGroupsData(res.data.ScreenTypeList)
                setGameData(res.data.ScreenGameList)
                setZoneData(res.data.ScreenAreaList)
            } else {
                message.error(res.data.Message, 2)
            }
        })
    }, [pageSize, currentPage, searchData])

    const [tableAutoScrollY, setTableAutoScrollY] = useState(0)
    useEffect(() => {
        window.addEventListener('resize',d=>{
            setTableAutoScrollY(document.getElementById('dti').clientHeight)
        })
        setTableAutoScrollY(document.getElementById('dti').clientHeight)

    }, [])

    return (
        <>
            <Layout style={{ width: "100%"}}>
                <div style={{margin:'22px 0',width:'100%'}}>
                    <SearchBar
                        onSearchClicked={handlerSearch}
                        // showModel={{ groups: true }}
                        showModel={{ groups: true, range: true, game: true, zone: true }}
                        groupsData={groupsData}
                        gameData={gameData}
                        zoneData={zoneData} />
                </div>
                <div style={{background:'#F1F4FD',padding:16,paddingTop:0}}>
                    <div id='dti' style={{background:' #ffffff',padding:0, borderRadius: 11,xwidth:'calc(100vw - 55px)',height:'calc(100vh - 177px)',overflow:"hidden"}}>

                    <Table
                            // style={{background: "#fff", padding: 20 ,height:'90%',paddingTop:0}}
                            rowKey="ID"
                            columns={columnsSel}
                            dataSource={nodeOrderItemList}
                            onChange={handlerTableChange}
                            defaultCurrent={1}
                            loading={loading}
                            pagination={{
                                size: "default",
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                showTotal: total2 => `共${total2}条数据`,
                                style: {width:'100%',height:'55px',alignContent:'center',justifyContent: 'center',position:"fixed",bottom:'19px'},

                                current: currentPage,
                                pageSize: pageSize,
                                total: total
                            }}
                            scroll={{ x: 'true', y:tableAutoScrollY-133 }}
                            // scroll={{ x: 'max-content', y:'true' }}

                            size="middle"
                            sticky
                        />

                    </div>


                </div>



            </Layout>
        </>
    )
}

export default PageHistory