import { Form, Input, Button, message } from 'antd';
import css from "../../css/LoginPage.module.css"
import "../../css/LoginPage.css"
import { useEffect, useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import useThrottle from "../../hooks/useThrottle"
import { images } from '../../Constant';
import {EllipsisOutlined, MobileOutlined, MobileTwoTone} from "@ant-design/icons";

const ModalChangePhone = ({ handlerType }) => {
    const [countDownSecond, setCountDownSecond] = useState(0)

    const [oldPhoneNum, setOldPhoneNum] = useState('')
    const [newPhoneNum, setNewPhoneNum] = useState('')
    const [oldPhoneCode, setOldPhoneCode] = useState('')
    const [newPhoneCode, setNewPhoneCode] = useState('')


    const [stepData, setStepData] = useState({
        subTitle: "第一步：请先验证原手机验证码",
        comfirnText: "下一步"
    })
    //  标识操作步骤
    const [stepOne, setStepOne] = useState(true)

    const handleCountDown = (seconds = 60, time = 1000) => {
        let second = seconds;
        let timer = (countDown) => setTimeout(countDown, time);
        const countDown = () => {
            if (second > 0) {
                second--;
                setCountDownSecond(second);
                console.log('time', second);
            }
            if (second === 0) {
                setCountDownSecond(second);
                return
            }
            timer(countDown)
        };
        countDown()
    };

    let handlerCode = useThrottle((event) => {
        // console.log('stepData', stepData)
        // console.log('oldPhoneData', oldPhoneData)
        // return
        let phoneCheck = stepOne? oldPhoneNum : newPhoneNum
        if (!(/^1[3456789]\d{9}$/.test(phoneCheck))) {
            message.info("手机号码有误，请重填", 2)
            return false;
        }
        // 获取验证码倒计时
        NetworkHelper.getInstance().SendSmsV3(phoneCheck,  stepOne ? 'ChangePhoneNumber' : 'BindNewPhoneNumber').then((res) => {
            if (res && res.data.Code === 1000) {
                handleCountDown(60)
                message.success("短信发送成功！", 2)
            } else {
                message.error(res.data.Message, 2)
            }
        }).catch(err => { message.error(err.Message) })
        event && event.preventDefault()
    }, 500)

    let handlerSubmit = useThrottle(() => {

        if (stepOne) {
            setStepOne(false)
        } else {
            NetworkHelper.getInstance().ChangePhoneNumber(oldPhoneNum, oldPhoneCode, newPhoneNum, newPhoneCode).then(response => {
                if (response.data.Code === 1000) {
                    sessionStorage.setItem("mofang-PhoneNumber", newPhoneNum)
                    message.info('手机号码修改成功', 2)
                    setStepOne(true)
                    handlerType()
                } else {
                    message.error(response.data.Message, 2)
                    if (response.data.Message.toString().indexOf('原手机') != -1){
                        setStepOne(true)
                        setStepData({
                            subTitle: "第一步：请先验证原手机验证码",
                            comfirnText: "下一步"
                        })
                    }
                    console.log(response.data.Message,'fff')
                }
            }).catch(e => console.log('navigator("/")'))
        }
    }, 300)

    const onFinish = (values) => {
        handlerSubmit()
    };

    useEffect(() => {
        if (!stepOne) {
            setStepData({
                subTitle: "第二步：绑定新的手机号",
                comfirnText: "确认修改"
            })
        }
    }, [stepOne])

    return (
        <div style={{ width: '320px' }} className={css.loginForm}>
            {/*<div className={css.formTitle}>修改手机号</div>*/}
            <Form name="control-ref" onFinish={onFinish}>
                <div className={css.KM}>{stepData.subTitle}</div>



                {
                    stepOne && <Form.Item className=""
                        name="oldPhoneNum"
                        rules={[{ required: true, message: '请填写手机号!' }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!' }]}
                    >
                        <Input
                            prefix={
                                <div className={css.userIconDiv}>
                                    <MobileOutlined />
                                    {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_2.png`} />*/}
                                </div>
                            }
                            size="middle"
                            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                            placeholder="原手机号"
                            value={oldPhoneNum}
                            onChange={(e) => { setOldPhoneNum(e.target.value) }}
                        />

                    </Form.Item>
                }
                {
                    !stepOne && <Form.Item className=""
                        name="newPhoneNum"
                        rules={[{ required: true, message: '请填写手机号!' }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!' }]}
                    >
                        <Input
                            prefix={
                                <div className={css.userIconDiv}>
                                    <MobileOutlined />
                                    {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_2.png`} />*/}
                                </div>
                            }
                            size="middle"
                            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                            placeholder="新手机号"
                            value={newPhoneNum}
                            onChange={(e) => { setNewPhoneNum(e.target.value) }}
                        />
                    </Form.Item>
                }
                {
                    stepOne &&
                <Form.Item className=""
                    name="oldPhoneCode"
                    rules={[{ required: true, message: '请填写验证码!' }]}
                >
                    <Input
                        maxLength={6}
                        suffix={
                            <div className={css.suffix} style={{ paddingRight: '0px' }}>
                                {countDownSecond > 0 ? <Button type="primary" disabled={true}>重新获取 ({countDownSecond})</Button> : <Button type="primary" onClick={handlerCode}>获取验证码</Button>}
                            </div>
                        }
                        prefix={
                            <div className={css.userIconDiv}>
                                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_5.png`} />*/}
                                <EllipsisOutlined />
                            </div>
                        }
                        size="middle"
                        xstyle={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="验证码"
                        value={oldPhoneCode}
                        onChange={(e) => { setOldPhoneCode(e.target.value) }}
                    />
                </Form.Item>
                }
                {
                    !stepOne &&
                <Form.Item className=""
                    name="newPhoneCode"
                    rules={[{ required: true, message: '请填写验证码!' }]}
                >
                    <Input
                        maxLength={6}
                        suffix={
                            <div className={css.suffix} style={{ paddingRight: '0px' }}>
                                {countDownSecond > 0 ? <Button type="primary" disabled={true}>重新获取 ({countDownSecond})</Button> : <Button type="primary" onClick={handlerCode}>获取验证码</Button>}
                            </div>
                        }
                        prefix={
                            <div className={css.userIconDiv}>
                                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_5.png`} />*/}
                                <EllipsisOutlined />
                            </div>
                        }
                        size="middle"
                        xstyle={{ background: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
                        placeholder="验证码"
                        value={newPhoneCode}
                        onChange={(e) => { setNewPhoneCode(e.target.value) }}
                    />
                </Form.Item>
                }

                <Form.Item style={{ width: '100%', display: 'flex', paddingTop: 20 }}>
                    <Button size="large" htmlType="submit" type="primary" style={{width:'100%'}} stylex={{ border: '1px solid #ffd031', background: '#ffd031', borderRadius: '30px', width: '100%', color: '#000', fontWeight: '700' }}>
                        <div stylex={{ fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold', width: '100%', color: '#000', fontWeight: '700' }}>{stepData.comfirnText}</div>
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default ModalChangePhone

