import { Radio, Table, Row, Col, Button, message, Modal, Cascader } from 'antd'
import { useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { modalNodeChange_leftColumns, modalNodeChange_rightColumns } from "../../utils/ColumnTemplate"

const ModalNodeChange = ({ onNodeChangeCallback, selnodes, rightnodes, show, onHide, pagination, handlerrighttablechange }) => {
    let gameIndexArray = []
    for(let i=0; i<26; i++) {
        let str = String.fromCharCode(i + 65)
        gameIndexArray.push({
            value: str,
            label: str,
            isLeaf: false
        })
    }

    const [selNode, setSelNode] = useState(null)
    const [dialogShow, setDialogShow] = useState(false)
    const [options, setOptions] = useState(gameIndexArray);
    const [selGame, setSelGame] = useState('')
    

    const onClose = () => {
        setSelNode(null)
        onHide && onHide()
    }

    const handlerChange = () => {
        let SoldId = []
        selnodes.map(val => {
            SoldId.push(val.ID.toString())
        })
        let newArea = selNode.Area
        let newGame = selGame!==''?selGame:selnodes[0].Game

        NetworkHelper.getInstance().SwitchNode(SoldId, newArea, newGame).then(response => {

            if (response.data.Code === 1000) {
                setSelNode(null)
                setDialogShow(false)
                onNodeChangeCallback && onNodeChangeCallback()
                onHide && onHide()
                message.info('节点切换成功', 2)
            } else {
                setDialogShow(false)
                message.error(response.data.Message, 2)
            }
        }).catch(error => console.log(error))
    }

    const onBtnOneChangeClicked = () => {
        if (selNode === null) {
            message.info('请选择节点', 2)
            return
        }
        setDialogShow(true)
    }


    const onChange = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        if(selectedOptions.length > 1) {
            setSelGame(selectedOptions[selectedOptions.length-1].value)
        } else {
            setSelGame('')
        }
    };

    const loadData = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        NetworkHelper.getInstance().GetGameData(targetOption.value)
        .then((response) => {
            if (response && response.data.Code === 1000) {
                response.data.GameList.map(val => {
                    val.label = val.Game
                    val.value = val.Game
                })

                targetOption.loading = false;
                targetOption.children = response.data.GameList;
                setOptions([...options]);
            } else {
                message.error(response.data.Message, 2)
            }
        })
        .catch((error) => {
            console.log("请求发生异常", error);
        });
    };

    return (
        <>
            <Modal
                maskClosable={true}
                visible={show}
                onCancel={onClose}
                transitionName=""
                maskTransitionName=""
                // size="xl"
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                width="1200px"
                height="500px"
                title={"节点切换"}
                footer={<div style={{ width: "100%", textAlign: "center" }}><Button type="primary" onClick={onBtnOneChangeClicked}>一键切换</Button></div>}
            >
                <Row>
                    <Col>
                        可切换游戏项目<Cascader options={options} loadData={loadData} onChange={onChange} changeOnSelect style={{ width: 300, marginLeft: 30 }}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        已选择节点列表
                    </Col>
                    <Col span={12}>
                        可切换节点地区
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Table
                            style={{ height: 290 }}
                            key="node-sel-table"
                            columns={modalNodeChange_leftColumns}
                            dataSource={selnodes}
                            pagination={false}
                            scroll={{ y: 200 }}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Radio.Group onChange={(event) => setSelNode(event.target.value)}>
                            <Table
                                key="node-change-table"
                                style={{ height: 290 }}
                                columns={modalNodeChange_rightColumns}
                                dataSource={rightnodes}
                                pagination={pagination}
                                onChange={handlerrighttablechange}
                                scroll={{ y: 200 }}
                                size="small"
                            />
                        </Radio.Group>
                    </Col>
                </Row>
            </Modal>
            <Modal visible={dialogShow} onCancel={() => setDialogShow(false)}
                maskClosable={true}
                title={"提示"} footer={<>
                    <Button onClick={() => setDialogShow(false)}>取消</Button>
                    <Button type="primary" onClick={handlerChange}>确认</Button>
                </>}
            >

                <p>您将切换{selnodes.length}个节点到{selNode && selNode.Area}</p>
            </Modal>
        </>
    )
}

export default ModalNodeChange