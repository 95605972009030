import { Image, Row, Col } from "antd"
import css from "../../css/PageMap.module.css"
import { useEffect, useState } from "react"
import { randomNum } from "../../utils/helper"
import { images } from "../../Constant"
import '../../css/home.css'

const AniMap = () => {
    const sty = [
        {
            name: '西宁',
            l: 30,
            t: 46,
        },
        {
            name: '成都',
            l: 41,
            t: 58,
        },
        {
            name: '重庆',
            l: 49,
            t: 61,
        },
        {
            name: '兰州',
            l: 43,
            t: 47,
        },
        {
            name: '郑州',
            l: 58,
            t: 52,
        },
        {
            name: '乌鲁木齐',
            l: 19,
            t: 26,
        }, {
            name: '哈尔滨',
            l: 82,
            t: 20,
        },
        {
            name: '南宁',
            l: 50,
            t: 73,
        },
        {
            name: '广州',
            l: 58,
            t: 73,
        },
        {
            name: '福州',
            l: 65,
            t: 68,
        },
        {
            name: '杭州',
            l: 67,
            t: 62,
        },
        {
            name: '上海',
            l: 70,
            t: 57,
        },
        {
            name: '青岛',
            l: 66,
            t: 46,
        },
        {
            name: '天津',
            l: 61,
            t: 40,
        },
        {
            name: '北京',
            l: 63,
            t: 36,
        },
    ]
    return (

        <div className="map">
            <img src={`${images}pic_02.png`} alt=""/>
            {

                sty.map(e =>
                    <p className="xp" style={{left: e.l +0+ '%', top: e.t + 0+'%'}}>{e.name}</p>
                )
            }
        </div>
    )
}

const PageMap = () => {
    let pstyle= {
        fontSize:22,
        fontWeight:333,
    }
    const count = () => (Date.parse(new Date())/555).toFixed()-2064743479-900000062

    const [num, setNum] = useState(count())

    useEffect(() => {
        setInterval(wefwe=>{
            setNum(count())
        },2189)
    },[])
    return (
        <>
            <Row justify="center" style={{ width: '100%', background: '#EAEEFA', display: 'flex', justifyContent: 'center', paddingTop: 54, paddingBottom: 54 }} className=' fl-c' >
            {/*<Col span={3}>*/}
            {/*</Col>*/}
            <Col span={6}>
                <img src={`${images}icon_02.png`} />
                <p style={{ width: 401,fontWeight:500,color:'#566176',fontSize:16,paddingTop:22 }}>覆盖全国海量自营服务器节点，线路灵活选择。支持多窗口在线/单程多程、单IP多IP窗口运行。</p>
            </Col>


        </Row>
            <Row justify="center" align="middle">
                <Col span={8} >
                    <p style={pstyle}>自营节点数：15778</p>

                    <p style={pstyle}>地区分布：16</p>
                    <p style={pstyle}>节点链接次数：{ num}</p>


                </Col>
                <Col span={8} xstyle={{display:window.location.href.toString().indexOf('local')==-1?'':"none"}}>
                   <AniMap/>
                </Col>
            </Row>
        </>

    )
}

export default PageMap