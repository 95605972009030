import { useEffect, useState } from 'react';
import { NetworkHelper } from "com.guntherlau.services.mofang"
import { Breadcrumb, Row, Button, message } from 'antd'
import { LeftCircleOutlined } from '@ant-design/icons';
import MarkdownIt from "markdown-it"
// import mdfile from "../../test.md"


const PageDocument = ({id, onBack }) => {

    const test = false
    const [document, setDocument] = useState({})

    const loadMarkdownFile = (url, Title, ReleaseTime, Publisher) => {
        fetch(url, { mode: 'cors' }).then(response => {   
            if(response.status === 200) {
                response.blob().then(bolb => bolb.text().then(text => {
                    const md = new MarkdownIt({
                        html: true,
                        linkify: true,
                        typographer: true
                    })
                    md.enable('image')
                    md.enable('link')
                    const result = md.render(text)
                    setDocument({
                        Title: Title,
                        Text: result,
                        ReleaseTime: ReleaseTime,
                        Publisher: Publisher
                    })
                }))
            } else if(response.status === 404) {

            } else {

            }
        }).catch(error => console.log('异常', error))
    }  

    useEffect(() => {
        !test &&
        NetworkHelper.getInstance().GetAnnouncementText(id).then(response => {
            console.log('公告',response)
            if(response.data.Code === 1000) {

                loadMarkdownFile(response.data.AnnouncementList[0].Url, response.data.AnnouncementList[0].Title, response.data.AnnouncementList[0].ReleaseTime, response.data.AnnouncementList[0].Publisher)
            } else {
                message.error(response.data.Message, 2)
            }
        })
    }, [])

    const markdown = `
#### 图片

![](https://pandao.github.io/editor.md/images/logos/editormd-logo-180x180.png)

![](https://img.shields.io/github/stars/pandao/editor.md.svg) 
![](https://img.shields.io/github/forks/pandao/editor.md.svg) 
![](https://img.shields.io/github/tag/pandao/editor.md.svg) 
![](https://img.shields.io/github/release/pandao/editor.md.svg) 
![](https://img.shields.io/github/issues/pandao/editor.md.svg) 
![](https://img.shields.io/bower/v/editor.md.svg)

![](https://pandao.github.io/editor.md/examples/images/7.jpg)

#### 标题

# Heading 1
## Heading 2               
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6

#### 标题（用底线的形式）Heading (underline)

# Heading 1 link [Heading link](https://github.com/pandao/editor.md "Heading link")
## Heading 2 link [Heading link](https://github.com/pandao/editor.md "Heading link")
### Heading 3 link [Heading link](https://github.com/pandao/editor.md "Heading link")
#### Heading 4 link [Heading link](https://github.com/pandao/editor.md "Heading link") Heading link [Heading link](https://github.com/pandao/editor.md "Heading link")
##### Heading 5 link [Heading link](https://github.com/pandao/editor.md "Heading link")
###### Heading 6 link [Heading link](https://github.com/pandao/editor.md "Heading link")

### 字符效果和横线等

上标：X<sub>2</sub>，下标：O<sup>2</sup>

----

~~删除线~~ <s>删除线（开启识别HTML标签时）</s>
*斜体字*      _斜体字_
**粗体**  __粗体__
***粗斜体*** ___粗斜体___

The <abbr title="Hyper Text Markup Language">HTML</abbr> specification is maintained by the <abbr title="World Wide Web Consortium">W3C</abbr>.


[普通链接](http://localhost/)

[普通链接带标题](http://localhost/ "普通链接带标题123")


- 列表一
- 列表二
- 列表三

* 列表一
* 列表二
* 列表三

+ 列表一
+ 列表二
    + 列表二-1
    + 列表二-2
    + 列表二-3
+ 列表三
    * 列表一
    * 列表二
    * 列表三

1. 第一行
2. 第二行
3. 第三行

- [x] GFM task list 1
- [x] GFM task list 2
- [ ] GFM task list 3
    - [ ] GFM task list 3-1
    - [ ] GFM task list 3-2
    - [ ] GFM task list 3-3
- [ ] GFM task list 4
    - [ ] GFM task list 4-1
    - [ ] GFM task list 4-2

### 特殊符号

&copy; &  &uml; &trade; &iexcl; &pound;
&amp; &lt; &gt; &yen; &euro; &reg; &plusmn; &para; &sect; &brvbar; &macr; &laquo; &middot; 
X&sup2; Y&sup3; &frac34; &frac14;  &times;  &divide;   &raquo;
18&ordm;C  &quot;  &apos;

* Lists
* [ ] todo
* [x] done
`

    const md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true
    })
    md.enable('image')
    md.enable('link')
    const result = md.render(markdown)
    // console.log(result)

    return (
        <div style={{ padding: 30 }}>
            <Row><h1>{document.Title}</h1></Row>
            <Row>
            <Button type="text" onClick={onBack}><LeftCircleOutlined style={{ fontSize: 20 }} /></Button>
                <Breadcrumb style={{ paddingLeft: 20, paddingTop: 8 }}>
                    <Breadcrumb.Item onClick={onBack}>
                        <span>系统公告</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{document.Title}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row style={{ paddingTop: 30 }}>
                发布时间：{document.ReleaseTime}    作者：{document.Publisher}
            </Row>
            <Row style={{ paddingTop: 30 }}>
                {!test && <div dangerouslySetInnerHTML={{ __html: document.Text}}></div>}
                {test && <div dangerouslySetInnerHTML={{ __html: result}}></div>}
            </Row>
        </div>
    )
}

export default PageDocument