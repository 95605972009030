
import PageCompany from "../PageCompany"
import PageFooter from "../PageFooter"
import PageDocument from "../Manager/PageDocument"
import { Image } from "antd"
import { images } from "../../Constant"


const ArticlePage = () => {

    return (
        <div id="ArticlePage">
            <Image src={`${images}banner_2.png`} width={"100%"} preview={false}/>
            <PageDocument/>
            <PageCompany />
            <PageFooter />
        </div>
    )
}

export default ArticlePage