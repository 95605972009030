import "../../css/about.css"
// import PageHeader, { HeaderType } from "../PageHeader"
import { images } from "../../Constant"
import { Tabs } from "antd"
import PageFooter from "../PageFooter";
// import PageCompany from "../PageCompany";
// import data from "../../rawdata/AboutPage.json"
import PageHeaderSecond from "../PageHeaderSecond";
// const { TabPane } = Tabs;

const HelpPage = () => {
    const secsty = {display:"flex",justifyContent:"center",width:'initial' }


    return (
        <div className="about" style={{ backgroundColor: "#EAEEFA" ,display:"flex",flexDirection:"column",minWidth:1300}}>
            <PageHeaderSecond />
            <main style={{display:"flex",justifyContent:'center',marginBottom:22}}>
                <section>
                    <h4>帮助中心</h4>
                    <p style={{maxWidth:444 }}>
                        我们是一款基于WEB/PC端，为切换节点而生的网络助手。我们致力为用户提供安全、便捷、稳定的专业级IP代理服务。                    </p>
                    <div   style={{width:800,marginTop:33}}>
                        <img src={`${images}pic_09.png`} style={{width:'100%'}} />
                    </div>
                </section>
                <section style={secsty}>
                    <h4 style={{alignSelf:"self-start"}}>教程/公告</h4>

                    <div style={{display:"flex",marginTop:11,justifyContent:"flex-start"}}>
                        <img src={`${images}pic_11.png`}   style={{width:'333px',marginRight:22,justifyContent:"center"}}   />
                        <img src={`${images}pic_12.png`}   style={{width:'333px'}}   />
                    </div>
                    <div className="item" style={{width:'100%'}}></div>

                </section>
                <section style={secsty}>
                    <h4 style={{alignSelf:"self-start"}}>新闻动态</h4>

                    <div style={{display:"flex",marginTop:11,justifyContent:"flex-start"}}>
                        <img src={`${images}pic_11.png`}  style={{width:'333px',marginRight:22,justifyContent:"center"}}  />
                        <img src={`${images}pic_12.png`} style={{width:'333px'}} />
                    </div>
                    <div className="item" style={{width:'100%'}}></div>

                </section>

            </main>
            <PageFooter></PageFooter>

        </div>

    )
}

export default HelpPage