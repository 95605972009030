import { Layout } from "antd"
// import "../css/PageFooter.module.css"
import '../css/footer.css'
const { Content } = Layout

const PageFooter = () => {

    return (<footer>
        <div className="footer">
            <div>
                <hr/>
                    <p>魔方IP助手</p>
                    <a href="">魔方IP助手桌面版</a>
            </div>
            <div>
                <hr/>
                    <p>商务合作</p>
                    <a href="">代理加盟：杨经理</a>
                    <a href="">电话/微信：188-8888-8888</a>
            </div>
            <div>
                <hr/>
                    <p>联系方式</p>
                    <a href="">在线客服：88888888</a>
                    <a href="">投诉热线：0771-88888888</a>
            </div>
        </div>
        <div className="copyright">
            Copyright 2016-2017 ip368.com. All Rights Reserved. 广东科盟网络科技有限公司 版权所有 粤ICP备16045157号
        </div>
    </footer> )
    }


export default PageFooter