import { Form, Input, Button, message } from 'antd';
import css from "../../css/LoginPage.module.css"
import "../../css/LoginPage.css"
import { useState } from "react"
import { NetworkHelper } from "com.guntherlau.services.mofang"
import useThrottle from "../../hooks/useThrottle"
import { images } from '../../Constant';
import {EllipsisOutlined, LockOutlined, MobileOutlined} from "@ant-design/icons";

const ModalChangePassword = ({handlerType}) => {
  const [username, setUsername] = useState(sessionStorage.getItem("mofang-username"))
  const [password, setPassword] = useState('')
  const [smsPhone, SetSmsPhone] = useState('')
  const [smscode, SetSmscode] = useState('')
  const [countDownSecond, setCountDownSecond] = useState(0)
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleCountDown = (seconds = 60, time = 1000) => {
    let second = seconds;
    let timer = (countDown) => setTimeout(countDown, time);
    const countDown = () => {
      if (second > 0) {
        second--;
        setCountDownSecond(second);
        console.log('time', second);
      }
      if (second === 0) {
        setCountDownSecond(second);
        return
      }
      timer(countDown)
    };
    countDown()
  };

  let handlerCode = useThrottle((event) => {
    // if (!username) {
    //   message.error("请填写用户昵称", 2)
    //   return false;
    // }

    console.log('手机号码：', smsPhone)
    if (!(/^1[3456789]\d{9}$/.test(smsPhone))) {
      message.info("手机号码有误，请重填", 2)
      return false;
    }
    // 获取验证码倒计时
    NetworkHelper.getInstance().SendSmsV3(smsPhone, 'ResetPassword').then((res) => {
      if (res && res.data.Code === 1000) {
        handleCountDown(60)
        message.success("短信发送成功！", 2)
      } else {
        message.error(res.data.Message, 2)
      }
    })
    .catch(
      (err)=>{message.error(err.Message)}
    )
    event && event.preventDefault()
  },500)

  let handlerSubmit = useThrottle(() => {
    NetworkHelper.getInstance().ResetPassword(username, password,smscode).then(response => {
      if (response.data.Code === 1000) {
        message.success('重置成功！', 2)
        handlerType()
      }else{
        message.error(response.data.Message)
      }
    })
    .catch(
      (err)=>{message.error(err.Message)}
    )
  },300)

  const onFinish = (values) => {
    // message.info('通过验证')
    handlerSubmit()
  };
  return (
    <div style={{ width: '100%' }} className={css.loginForm}>
      {/*<div className={css.formTitle}>修改密码</div>*/}
      <Form name="control-ref" onFinish={onFinish}>

        <Form.Item className=""
          name="smsPhone"
          rules={[{ required: true, message: '请填写手机号!' }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!' }]}
        >
          <Input
            prefix={
              <div className={css.userIconDiv}>
                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_2.png`} />*/}
                <MobileOutlined />
              </div>
            }
            size="middle"
            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
            placeholder="手机号"
            onChange={(e) => { SetSmsPhone(e.target.value) }}
          />

        </Form.Item>
        <Form.Item className=""
          name="smscode"
          rules={[{ required: true, message: '请填写验证码!' }]}
        >
          <Input
            maxLength={6}
            suffix={
              <div className={css.suffix} style={{ paddingRight: '0px' }}>
                {countDownSecond > 0 ? <Button type="primary" disabled={true}>重新获取 ({countDownSecond})</Button> : <Button type="primary" onClick={handlerCode}>获取验证码</Button>}
              </div>
            }
            prefix={
              <div className={css.userIconDiv}>
                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_5.png`} />*/}
                <EllipsisOutlined />
              </div>
            }
            size="middle"
            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
            placeholder="验证码"
            onChange={(e) => { SetSmscode(e.target.value) }}
          />
        </Form.Item>

        <Form.Item className=""
          name="password"
          rules={[{ required: true, message: '请填写新密码!' }]}
        >
          <Input.Password
            prefix={
              <div className={css.userIconDiv}>
                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_3.png`}></img>*/}
                <LockOutlined />
              </div>
            }
            size="middle"
            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
            placeholder="新密码"
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
          />
        </Form.Item>
        <Form.Item className=""
          name="confirmPassword"
          rules={[
            { required: true, message: '请填写确认密码!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('确认密码与密码设置不一致!'));
              },
            }),
          ]}
        >
          <Input.Password
            prefix={
              <div className={css.userIconDiv}>
                {/*<img alt="img" className={css.userIcon} src={`${images}loginicon_3.png`}></img>*/}
                <LockOutlined />
              </div>
            }
            onChange={(e) => { setConfirmPassword(e.target.value) }}
            size="middle"
            xstyle={{ xbackground: '#eff2f4', borderRadius: '4px', border: '1px solid rgba(0,0,0,0.30)' }}
            placeholder="确认密码"
          />
        </Form.Item>
        

        <Form.Item style={{ width: '100%', display: 'flex', paddingTop: 20 }}>
          <Button size="large" htmlType="submit" type="primary" style={{width:'100%'}} >
            <div xstyle={{ fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold', width: '100%', color: '#000', fontWeight: '700' }}>确认修改</div>
          </Button>
        </Form.Item>

      </Form>
    </div>
  )
}

export default ModalChangePassword

