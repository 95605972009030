import {useEffect, useState} from "react"
import {Form, Button, Input, message, Checkbox} from 'antd';
import css from "../css/LoginPage.module.css"
import "../css/RegisterPage.css"
import {NetworkHelper} from "com.guntherlau.services.mofang"
import useThrottle from "../hooks/useThrottle";
import {images} from "../Constant";
import '../css/login.css'
import {CloseOutlined, LockOutlined, MobileOutlined, RobotOutlined, UserOutlined} from "@ant-design/icons";

const ModalResgister = ({modelType, handlerType}) => {

    const [validated, setValidated] = useState(false);
    const [countDownSecond, setCountDownSecond] = useState(0)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [smsPhone, SetSmsPhone] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [smscode, SetSmscode] = useState('')

    const [qq, SetQQ] = useState('')
    const [agent, SetAgent] = useState('')
    const [agentGiveGold, SetAgentGiveGold] = useState('')

    const handleCountDown = (seconds = 60, time = 1000) => {
        let second = seconds;
        let timer = (countDown) => setTimeout(countDown, time);
        const countDown = () => {
            if (second > 0) {
                second--;
                setCountDownSecond(second);
                console.log('time', second)
            }
            if (second === 0) {
                setCountDownSecond(second);
                return
            }
            timer(countDown)
        };
        countDown()
    };
    useEffect(() => {
        NetworkHelper.getInstance().QueryRegGiveGold().then(response => {
            SetAgentGiveGold(`填写邀请码赠送${response.data.GiveGold}金币`)
        })
    }, [])

    let handlerCode = useThrottle((event) => {
        // if (!username) {
        //     message.error("请填写用户昵称", 2)
        //     return false;
        // }
        if (!(/^1[3456789]\d{9}$/.test(smsPhone))) {
            message.info("手机号码有误，请重填", 2)
            return false;
        }
        // 获取验证码倒计时
        NetworkHelper.getInstance().SendSmsV3(smsPhone, username, 'Registered').then((res) => {
            if (res && res.data.Code === 1000) {
                handleCountDown(60)
                message.success("短信发送成功！", 2)
            } else {
                message.error(res.data.Message, 2)
            }
        })
        event && event.preventDefault()
    }, 500)
    const onFinish = () => {
        setValidated(true)
        if (validated === false) {
            handlerSubmit()
        }
    };
    const handlerSubmit = useThrottle(() => {
        NetworkHelper.getInstance().RegisterUser(password, smsPhone, smscode, qq, agent).then((res) => {
            console.log(smscode,'yzm',smsPhone)
            if (res && res.data.Code === 1000) {
                message.success("账号注册成功！", 2)
                handlerType("login")
            } else {
                message.error(res.data.Message, 2)
            }
        })
            .finally(() => {
                setValidated(false)
            })
    }, 300)

    const [isNext, setisNext] = useState(false)

    const caniNext = () => {
        // if (!username) {
        //     message.error("请填写用户昵称", 2)
        //     return false;
        // }
        if (!(/^1[3456789]\d{9}$/.test(smsPhone))) {
            message.info(`手机号码${smsPhone}有误，请重填`, 2)
            return false;
        }
        if (window.isNaN(qq)) {
            message.info("QQ号码有误，请重填", 2)
            return false;
        }
        setisNext(true)

    }

    return (
        <div style={{display: modelType === 'register' ? "" : "none"}}>
            <div className="uni-login"></div>
            <div className="login">
                <div className="left"></div>
                <div className="right">
                    <div className="close" onClick={() => {
                        handlerType('')
                    }}><CloseOutlined/></div>
                    <h2>欢迎注册</h2>

                    <h6>已有帐号？请立即<a tfype="link" onClick={() => {
                        handlerType('login')
                    }}>登录
                    </a></h6>


                    <Form onFinish={onFinish}>
                        {/*<div className="input" style={{width: '100%', display: !isNext ? '' : 'none'}}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="username"*/}
                        {/*        rules={[{required: true, message: '请填写用户名!'}]}*/}
                        {/*    >*/}

                        {/*        <Input*/}
                        {/*            prefix={*/}
                        {/*                <UserOutlined className="site-form-item-icon"/>*/}
                        {/*            }*/}
                        {/*            size="middle"*/}
                        {/*            bordered={false}*/}
                        {/*            placeholder="注册用户名"*/}
                        {/*            autocomplete="off"*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setUsername(e.target.value)*/}
                        {/*            }}*/}
                        {/*        />*/}

                        {/*    </Form.Item>*/}
                        {/*</div>*/}

                        <div className="input" style={{width: '100%', display: !isNext ? '' : 'none'}}>
                            <Form.Item style={{width: '100%', display: !isNext ? '' : 'none'}}
                                       name="smsPhone"
                                       rules={[{
                                           required: true,
                                           message: '请填写手机号!'
                                       }, {pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请填写正确的手机号码!'}]}
                            >
                                <Input
                                    prefix={
                                        <MobileOutlined/>
                                    }
                                    size="middle"
                                    bordered={false}
                                    placeholder="手机号"
                                    autocomplete="off"
                                    onChange={(e) => {
                                        SetSmsPhone(e.target.value)
                                    }}
                                />

                            </Form.Item>
                        </div>

                        <div className="input" style={{width: '100%', display: !isNext ? '' : 'none'}}>

                            <Form.Item style={{width: '100%', display: !isNext ? '' : 'none'}}
                                       name="qq"
                                       rules={[{
                                           required: true,
                                           message: '请填写QQ号!'
                                       }, {pattern: /^[0-9]*$/, message: '请填写正确的QQ号码!'}]}
                            >
                                <Input
                                    prefix={
                                        <MobileOutlined/>
                                    }
                                    size="middle"
                                    bordered={false}
                                    placeholder="QQ号"
                                    autocomplete="off"
                                    onChange={(e) => {
                                        SetQQ(e.target.value)
                                    }}
                                />


                            </Form.Item>
                        </div>

                        <div className="input" style={{width: '100%', display: !isNext ? '' : 'none'}}>

                            <Form.Item style={{width: '100%', display: !isNext ? '' : 'none'}}
                                       name="agent"
                            >
                                <Input
                                    prefix={
                                        <MobileOutlined/>
                                    }
                                    size="middle"
                                    bordered={false}
                                    placeholder={"邀请码，"+agentGiveGold}
                                    autocomplete="off"
                                    onChange={(e) => {
                                        SetAgent(e.target.value)
                                    }}
                                />


                            </Form.Item>
                        </div>




                        <div  className="input" style={{width: '100%', display: isNext ? '' : 'none'}}>

                            <Form.Item
                                name="password"
                                rules={[{required: true, message: '请填写密码!'}]}
                            >
                                <Input.Password
                                    bordered={false}
                                    prefix={
                                        <LockOutlined/>
                                    }
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                    size="middle"
                                    placeholder="密码"
                                    autocomplete="off"
                                />
                            </Form.Item>
                        </div>
                        <div  className="input" style={{width: '100%', display: isNext ? '' : 'none'}}>

                        <Form.Item
                                name="confirmPassword"
                                rules={[
                                    {required: true, message: '请填写确认密码!'},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('确认密码与密码设置不一致!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    bordered={false}
                                    prefix={
                                        <LockOutlined/>
                                    }
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                    }}
                                    size="middle"
                                    placeholder="确认密码"
                                    autocomplete="off"
                                />
                            </Form.Item>
                        </div>
                        <div  className="input" style={{width: '100%', display: isNext ? '' : 'none',paddingBottom:44}}>

                            <Form.Item style={{width: '100%', display: isNext ? '' : 'none'}}
                                       name="smscode"
                                       rules={[{required: true, message: '请填写验证码!'}]}
                            >

                                <Input
                                    maxLength={6}

                                    bordered={false}
                                    suffix={
                                        <div className={css.suffix} style={{paddingRight: '0px'}}>
                                            {countDownSecond > 0 ?
                                                <Button type="link" disabled={true}>重新获取 ({countDownSecond})</Button> :
                                                <Button type="link" onClick={handlerCode}>获取验证码</Button>}
                                        </div>
                                    }
                                    prefix={
                                        <RobotOutlined/>
                                    }
                                    size="middle"
                                    placeholder="验证码"
                                    autocomplete="off"
                                    onChange={(e) => {
                                        SetSmscode(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div className="bt" style={{width: '100%', display: !isNext ? '' : 'none'}}>
                            <Button size="large" x-htmlType="submit" type="primary" style={{
                                background: '#7157FF',
                                width: '100%',
                                color: '#eeeeee',
                                fontWeight: '700',
                                border: 'none'
                            }}>
                                <div xyb style={{width: '100%', color: '#ffffff', fontWeight: '700'}}
                                     onClick={() => caniNext()}  >
                                    下一步
                                </div>
                            </Button>
                        </div>

                        <Form.Item style={{width: '100%', display: isNext ? '' : 'none'}}>
                            <Button disabled={validated} htmlType="submit" type="primary" size="large" style={{
                                marginTop: '1.6rem',
                                width: '100%',
                                border: 'none',
                                background: '#7157ff'
                            }}>
                                <div onDoubleClick={() => {
                                    setisNext(false)
                                }}
                                   onClick={d=>{
                                       console.log(smscode,'ddw')
                                   }}
                                     style={{
                                    fontWeight: '700'
                                }}>确认注册
                                </div>
                            </Button>
                        </Form.Item>

                        <Form.Item style={{width: '100%', textAlign: 'center', marginTop: '10px'}}
                                   name="agreement"
                                   valuePropName="checked"
                                   rules={[
                                       {
                                           validator: (_, value) =>
                                               value ? Promise.resolve() : Promise.reject(new Error('请同意用户协议')),
                                       },
                                   ]}
                        >
                            <Checkbox><span style={{color: "red"}}>我已仔细阅读并同意接受</span><a variant="link"
                                                                                        href="protocol.html"
                                                                                        target="view_window">《用户协议》</a></Checkbox>
                        </Form.Item>

                    </Form>
                </div>
            </div>
        </div>
    )
}


export default ModalResgister