
const isDev = true

const dev = {
    images: '../../images/',
    host: 'https://kmapi.ip368.com/',
    downloadUrl: 'file/appdmg.png'
}

const prod = {
    images: 'https://webresource-1309034441.cos.ap-guangzhou.myqcloud.com/images/',
    host: 'https://kmapi.ip368.com/',
    downloadUrl: 'https://download-1309034441.cos.ap-guangzhou.myqcloud.com/km_inst3.0.exe'
}


const images = isDev ? dev.images : prod.images
const host = isDev ? dev.host : prod.host
const downloadUrl = isDev ? dev.downloadUrl : prod.downloadUrl

const TABNAMES = {
    CENTER: "center",
    NODEMGR: "node-manager",
    PAYMENT: "payment",
    NODEPUR: "node-purchase",
    DETAIL: "detail",
    HISTORY: "history",
    DEVICES: "devices"
}

const TABS = {
    ROOT: "/",
    CENTER:  `/mgr/${TABNAMES.CENTER}`,
    NODEMGR: `/mgr/${TABNAMES.NODEMGR}`,
    PAYMENT: `/mgr/${TABNAMES.PAYMENT}`,
    NODEPUR: `/mgr/${TABNAMES.NODEPUR}`,
    DETAIL:  `/mgr/${TABNAMES.DETAIL}`,
    HISTORY: `/mgr/${TABNAMES.HISTORY}`,
    DEVICES: `/mgr/${TABNAMES.DEVICES}`
}

export { images, host, downloadUrl, TABS, TABNAMES }