import {Button} from "antd";
import {images} from "../../Constant";

 const XxButton = ()=>{
    return   (<Button
        type="text"
        style={{  background: '#366EFF', borderRadius: 40,height:60,width:200,boxShadow:' 0px 10px 30px 0px rgba(54,110,255,0.16)' }}>
        <div
            style={{
                fontFamily: 'Microsoft YaHei, Microsoft YaHei-Bold',
                width: '100%', color: '#ffffff',
            
            }}
            onClick={() => {}}>
            <img src={`${images}icon_01.png`}  style={{paddingRight:16}}/>
            <span>下载客户端</span>
        </div>
    </Button>)
}

export default XxButton